import React, { ChangeEvent, useState } from 'react'
import validator from 'validator'
import { useRecoilValue } from 'recoil'
import styled from 'styled-components'

import { checkInputValid } from '../../../../helpers/validation'
import { activeWidgetSelector, activeWidgetState } from '../../../../stores/builder/activeWidget'
import { useUpdateWidgetSettings } from '../../../../lib/widgets/useUpdateWidgetSettings'
import { DesignBlock, DesignTab } from '../../DesignSettings/DesignTab'
import { InputBox } from '../../../../stories/Forms/InputBox'
import { ToggleButton } from '../../../../stories/Button/ToggleButton'

const Settings = () => {
    const widget = useRecoilValue(activeWidgetSelector)
    const updateSettings = useUpdateWidgetSettings()
    const { index } = useRecoilValue(activeWidgetState)
    const [error, setError] = useState<string>('')

    const handleTextChange = (e: ChangeEvent<HTMLInputElement>) => {
        updateSettings.update({ settings: { ...widget.settings, [e.target.name]: e.target.value } })
    }

    const handleCheckbox = (e: ChangeEvent<HTMLInputElement>) => {
        updateSettings.update({ settings: { ...widget.settings, required: e.target.checked } })
    }

    const handleTextBlur = (value: string, attr: string) => () => {
        setError('')
        if (attr === 'name') {
            if (validator.isEmpty(value) || value === '') {
                setError('Input name can not be empty')
                return
            }
            if (!checkInputValid(value, 'alphanumericWithDashSpace')) {
                setError('Please set valid input name without special characters')
            }
        }
    }

    return (
        <DesignTab index={index}>
            <DesignBlock>
                <InputBox
                    label="Text area label"
                    name="label"
                    placeholder="Input label name"
                    onChange={handleTextChange}
                    type="text"
                    value={widget?.settings?.label}
                    onBlur={handleTextBlur(widget?.settings?.label, 'label')}
                />
                <InputBox
                    label="Field name"
                    name="name"
                    placeholder="Input Name"
                    onChange={handleTextChange}
                    type="text"
                    value={widget?.settings?.name}
                    onBlur={handleTextBlur(widget?.settings?.name, 'name')}
                    error={error}
                />
                <InputBox
                    label="Placeholder text"
                    name="placeholder"
                    placeholder="Tell us why (optional)"
                    onChange={handleTextChange}
                    type="text"
                    value={widget?.settings?.placeholder}
                />
                <FlexBox>
                    <InputBox
                        label="Height text rows"
                        width="134px"
                        name="rows"
                        onChange={handleTextChange}
                        type="number"
                        value={widget?.settings?.rows}
                    />
                    <InputBox
                        label="Max characters"
                        width="134px"
                        name="maxLength"
                        onChange={handleTextChange}
                        type="number"
                        value={widget?.settings?.maxLength}
                    />
                </FlexBox>
                <ToggleContainer>
                    <ToggleButton
                        isToggled={widget?.settings?.required}
                        onChange={handleCheckbox}
                    />
                    <span>Field is required</span>
                </ToggleContainer>
            </DesignBlock>
        </DesignTab>
    )
}

export default Settings

const FlexBox = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    & > div:first-child {
        margin-right: 16px;
    }
`
export const ToggleContainer = styled.div`
    display: flex;
    align-items: center;
    & > span {
        display: flex;
        align-items: center;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: #3c4b61;
        margin-left: 7px;
    }
`
