import { nanoid } from 'nanoid'


export interface IVideo {
    _id?: string
    id: string
    type: string
    icon: string
    name: string
    settings: {
        src: string
        file: File | undefined
        fileName: string
        thumbnail: string
        name: string
        platform: string
    }
    appearanceSettings: {
        width: number
        height: number
        align: string
        paddingTop: number
        paddingBottom: number
        paddingLeft: number
        paddingRight: number
        marginTop: number
        marginBottom: number
        marginLeft: number
        marginRight: number
    }
}

class Video implements IVideo {
    constructor(public name: string, public src: string) {
        this.name= name 
        this.settings.src = src
    }
    id = nanoid()
    type = 'video'
    icon = '/icons/widgets/video.svg'
    category = 'general'
    settings = {
        src: 'https://media.w3.org/2010/05/sintel/trailer_hd.mp4',
        file: undefined,
        fileName: "",
        thumbnail: '',
        platform: '',
        name: ''
    }
    appearanceSettings = {
        width: 90,
        height: 100,
        align: 'center',
        paddingTop: 10,
        paddingBottom: 10,
        paddingLeft: 0,
        paddingRight: 0,
        marginTop: 0,
        marginBottom: 0,
        marginLeft: 0,
        marginRight: 0,
    }
    colourSettings = {}
}

export { Video }
