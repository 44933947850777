import React, { useState, useEffect, ChangeEvent } from 'react'
import styled from 'styled-components'
import { SimpleSelect } from '../../Forms/SelectBox'
import { Icon } from '../../index'
import { BorderIcon, MiddleMultiSelectContainer, MultiSelectContainer } from '../Border/BorderWidth'
import { ContainerBody, WidthBlock } from '../Width/Width'
import { ContainerLabel, Props, TopBottomMultiSelectContainer } from './Margin'
import BorderCustom from './MarginCustom'

export const Padding = ({ label, widget, handleChange, handleChangePadding }: Props) => {
    const [selectedType, setSelectedType] = useState('px')
    const [isCustomBorder, setCustomState] = useState(false)

    const paddingTop = widget?.appearanceSettings ? widget?.appearanceSettings?.paddingTop : 0
    const paddingBottom = widget?.appearanceSettings ? widget?.appearanceSettings?.paddingBottom : 0
    const paddingLeft = widget?.appearanceSettings ? widget?.appearanceSettings?.paddingLeft : 0
    const paddingRight = widget?.appearanceSettings ? widget?.appearanceSettings?.paddingRight : 0

    useEffect(() => {
        if (
            paddingTop === paddingBottom &&
            paddingBottom === paddingLeft &&
            paddingLeft === paddingRight &&
            paddingRight === paddingTop
        ) {
            setCustomState(false)
        } else {
            setCustomState(true)
        }
    }, [paddingBottom, paddingLeft, paddingRight, paddingTop])

    const handleChangeType = (opt: React.SetStateAction<string>): void => {
        setSelectedType(opt)
    }

    const checkCustomState = () => {
        setCustomState((prev) => !prev)
    }

    return (
        <PaddingContainer>
            <ContainerLabel>{label}</ContainerLabel>
            <ContainerBody>
                <WidthBlock>
                    <input
                        type="number"
                        value={paddingTop || paddingBottom || paddingLeft || paddingRight}
                        onChange={handleChangePadding}
                    />
                    <SimpleSelect
                        options={['px', '%', 'vw']}
                        onHandleChange={handleChangeType}
                        size="small"
                        defaultValue="px"
                    />
                </WidthBlock>
                <BorderIcon isCustomBorder={isCustomBorder} onClick={checkCustomState}>
                    <div style={{ padding: 5 }}>
                        {isCustomBorder ? (
                            <Icon
                                name="margin-link"
                                size={'sm'}
                                color={isCustomBorder ? '#6478F9' : ''}
                            />
                        ) : (
                            <Icon
                                name="margin-unlink"
                                size={'sm'}
                                color={isCustomBorder ? '#6478F9' : ''}
                            />
                        )}
                    </div>
                </BorderIcon>
            </ContainerBody>
            {isCustomBorder && (
                <MultiSelectContainer
                    initial={{ opacity: 0, y: 50, scale: 0.3 }}
                    animate={{ opacity: 1, y: 0, scale: 1 }}
                    exit={{ opacity: 0, scale: 0.5, transition: { duration: 0.2 } }}
                >
                    <TopBottomMultiSelectContainer>
                        <BorderCustom
                            direction="Top"
                            value={paddingTop}
                            onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                handleChange(e, 'paddingTop')
                            }
                        />
                    </TopBottomMultiSelectContainer>
                    <MiddleMultiSelectContainer>
                        <BorderCustom
                            direction="Left"
                            value={paddingLeft}
                            onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                handleChange(e, 'paddingLeft')
                            }
                        />
                        <BorderCustom
                            direction="Right"
                            value={paddingRight}
                            onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                handleChange(e, 'paddingRight')
                            }
                        />
                    </MiddleMultiSelectContainer>
                    <TopBottomMultiSelectContainer>
                        <BorderCustom
                            direction="Bottom"
                            value={paddingBottom}
                            onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                handleChange(e, 'paddingBottom')
                            }
                        />
                    </TopBottomMultiSelectContainer>
                </MultiSelectContainer>
            )}
        </PaddingContainer>
    )
}

const PaddingContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    color: #717f95;
    margin-bottom: 15px;
`
