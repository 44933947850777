import { useRecoilValue, useSetRecoilState } from 'recoil'
import { activeWidgetState } from '../../stores/builder/activeWidget'
import { campaignViewState } from '../../stores/campaign/edit/campaignState'

interface Update {
    update: (value: { [key: string]: any }) => void
}

export const useUpdateWidgetSettings = (): Update => {
    const { widget, index, parentIndex, currentViewIndex, currentLayer } =
        useRecoilValue(activeWidgetState)
    const updateView = useSetRecoilState(campaignViewState)

    return {
        update: (value): void => {
            updateView((prevState) => {
                if (currentLayer === null) {
                    if (typeof parentIndex == 'number' && parentIndex >= 0) {
                        const widget = prevState[currentViewIndex].widgets[parentIndex]
                        const child_widgets = widget.widgets
                        const child_widget = child_widgets[index]
                        const updated_child_widget = { ...child_widget, ...value }

                        const updated_child_widgets = [
                            ...child_widgets.slice(0, index),
                            updated_child_widget,
                            ...child_widgets.slice(index + 1),
                        ]

                        const updated_widget = { ...widget, widgets: updated_child_widgets }

                        const new_widgets = [
                            ...prevState[currentViewIndex].widgets.slice(0, parentIndex),
                            updated_widget,
                            ...prevState[currentViewIndex].widgets.slice(parentIndex + 1),
                        ]

                        const newView = { ...prevState[currentViewIndex], widgets: new_widgets }
                        const updatedViews = [
                            ...prevState.slice(0, currentViewIndex),
                            newView,
                            ...prevState.slice(currentViewIndex + 1),
                        ]

                        return updatedViews
                    } else {
                        const widget = prevState[currentViewIndex].widgets[index]
                        const updated_widget = { ...widget, ...value }

                        const new_widgets = [
                            ...prevState[currentViewIndex].widgets.slice(0, index),
                            updated_widget,
                            ...prevState[currentViewIndex].widgets.slice(index + 1),
                        ]

                        const newView = { ...prevState[currentViewIndex], widgets: new_widgets }

                        const updatedViews = [
                            ...prevState.slice(0, currentViewIndex),
                            newView,
                            ...prevState.slice(currentViewIndex + 1),
                        ]

                        return updatedViews
                    }
                } else {
                    // for popup view widget update
                    if (typeof parentIndex == 'number' && parentIndex >= 0) {
                        const widget =
                            prevState[currentViewIndex].layers[currentLayer].widgets[parentIndex]
                        const child_widgets = widget.widgets
                        const child_widget = child_widgets[index]
                        const updated_child_widget = { ...child_widget, ...value }
                        const updated_child_widgets = [
                            ...child_widgets.slice(0, index),
                            updated_child_widget,
                            ...child_widgets.slice(index + 1),
                        ]

                        const updated_widget = { ...widget, widgets: updated_child_widgets }

                        const new_layer_widgets = [
                            ...prevState[currentViewIndex].layers[currentLayer].widgets.slice(
                                0,
                                parentIndex
                            ),
                            updated_widget,
                            ...prevState[currentViewIndex].layers[currentLayer].widgets.slice(
                                parentIndex + 1
                            ),
                        ]

                        const new_layer = {
                            ...prevState[currentViewIndex].layers[currentLayer],
                            widgets: new_layer_widgets,
                        }

                        const new_layers = [
                            ...prevState[currentViewIndex].layers.slice(0, currentLayer),
                            new_layer,
                            ...prevState[currentViewIndex].layers.slice(currentLayer + 1),
                        ]

                        const newView = { ...prevState[currentViewIndex], layers: new_layers }
                        const updatedViews = [
                            ...prevState.slice(0, currentViewIndex),
                            newView,
                            ...prevState.slice(currentViewIndex + 1),
                        ]

                        return updatedViews
                    } else {
                        const widget =
                            prevState[currentViewIndex].layers[currentLayer].widgets[index]
                        const updated_widget = { ...widget, ...value }

                        const new_layer_widgets = [
                            ...prevState[currentViewIndex].layers[currentLayer].widgets.slice(
                                0,
                                index
                            ),
                            updated_widget,
                            ...prevState[currentViewIndex].layers[currentLayer].widgets.slice(
                                index + 1
                            ),
                        ]

                        const new_layer = {
                            ...prevState[currentViewIndex].layers[currentLayer],
                            widgets: new_layer_widgets,
                        }

                        const new_layers = [
                            ...prevState[currentViewIndex].layers.slice(0, currentLayer),
                            new_layer,
                            ...prevState[currentViewIndex].layers.slice(currentLayer + 1),
                        ]

                        const newView = { ...prevState[currentViewIndex], layers: new_layers }

                        const updatedViews = [
                            ...prevState.slice(0, currentViewIndex),
                            newView,
                            ...prevState.slice(currentViewIndex + 1),
                        ]

                        return updatedViews
                    }
                }
            })
        },
    }
}
