import { useRecoilValue, useRecoilState } from 'recoil'
import { currentLayerIndexState } from '../../../stores/builder/currentLayerIndexState'
import { currentViewState } from '../../../stores/builder/currentViewState'
import { campaignViewState } from '../../../stores/campaign/edit/campaignState'
import Layer, { ILayer } from '../../../models/Layer'
import Button from '../../../models/Button'
import { viewLayerVisibleState } from '../../../stores/builder/viewLayerVisibleState'
import { viewSectionState } from '../../../stores/builder/viewSectionState'
import { nanoid } from 'nanoid'
import { selectedPreviewState } from '../../../stores/builder/view/selectedPreview'

interface LayerHook {
    add: () => void
    delete: (viewIndex: number, id: string) => void
    toggle: () => void
    update: (settings: { [key: string]: any }) => void
    copy: (viewIndex: number, id: string) => void
}

export const useLayers = (): LayerHook => {
    const [views, setViews] = useRecoilState(campaignViewState)
    const [currentLayer, setCurrentLayer] = useRecoilState(currentLayerIndexState)
    const currentViewIndex = useRecoilValue(currentViewState)
    const [showViewLayer, setViewLayer] = useRecoilState(viewLayerVisibleState)
    const [sectionState, setSectionState] = useRecoilState(viewSectionState)
    const [selectedPreview, setSelectedPreview] = useRecoilState(selectedPreviewState)
    return {
        add: (): void => {
            let layerIndex = currentLayer
            let layerId = null
            setViews((prevState) => {
                const currentLayers = prevState[currentViewIndex].layers
                let layerName = 1
                if (currentLayers && currentLayers.length > 0) {
                    layerName = currentLayers.length + 1
                }

                const newLayer: ILayer = new Layer()
                layerId = newLayer.id

                const newView = {
                    ...prevState[currentViewIndex],
                    widgets: [...prevState[currentViewIndex].widgets],
                    layers: [...prevState[currentViewIndex].layers, newLayer],
                }

                layerIndex = newView.layers.length - 1

                const updatedView = [
                    ...prevState.slice(0, currentViewIndex),
                    newView,
                    ...prevState.slice(currentViewIndex + 1),
                ]

                return updatedView
            })

            setSelectedPreview(layerId)
            setCurrentLayer(layerIndex)
        },
        delete: (viewIndex: number, id: string): void => {
            if (sectionState === 'widgetEditSettings') {
                setSectionState('widgetPicker')
            }

            const filteredLayers = views[viewIndex].layers.filter((layer) => layer.id !== id)
            setViews((prevState) => {
                const newView = { ...prevState[viewIndex], layers: filteredLayers }

                const updatedView = [
                    ...prevState.slice(0, viewIndex),
                    newView,
                    ...prevState.slice(viewIndex + 1),
                ]
                return updatedView
            })

            if (filteredLayers.length > 0) {
                setCurrentLayer(0)
            } else {
                setCurrentLayer(null)
                setViewLayer(false)
            }
        },
        toggle: (): void => {
            if (showViewLayer) {
                setSectionState('widgetPicker')
            }
            setViewLayer(!showViewLayer)
        },
        update: (settings): void => {
            setViews((prevState) => {
                const current_layer = {
                    ...prevState[currentViewIndex].layers[currentLayer],
                    ...settings,
                }
                const currentView = {
                    ...prevState[currentViewIndex],
                    layers: [
                        ...prevState[currentViewIndex].layers.slice(0, currentLayer),
                        current_layer,
                        ...prevState[currentViewIndex].layers.slice(currentLayer + 1),
                    ],
                }

                const updatedViews = [
                    ...prevState.slice(0, currentViewIndex),
                    currentView,
                    ...prevState.slice(currentViewIndex + 1),
                ]

                return updatedViews
            })
        },
        copy: (viewIndex: number, id: string): void => {
            setViews((prevState) => {
                const currentView = prevState[viewIndex]
                const currentLayer = currentView.layers.find((layer) => layer.id === id)

                const updateLayers = [
                    ...currentView.layers,
                    { ...currentLayer, name: `${currentLayer.name} copy`, id: nanoid() },
                ]
                const copyView = { ...currentView, layers: updateLayers }
                return [
                    ...prevState.slice(0, viewIndex),
                    copyView,
                    ...prevState.slice(viewIndex + 1),
                ]
            })
        },
    }
}
