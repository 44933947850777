export type VoucherSettings = {
    voucherGenerator: VoucherGenerator
    length: number
    prefix: string
    startingNumber?: number
    defaultVoucherCode?: string | null
}


export enum VoucherGenerator  {
    RANDOM = 'random',
    FIXED = 'fixed',
    SEQUENTIAL = 'sequential'
}
