import React from 'react'
import { useRecoilState, useSetRecoilState } from 'recoil'
import Image from 'next/image'
import { IReward } from '../../../../../../models/Assets/Reward'
import UploadAssetModal from '../UploadAssetModal'
import { categoryState, assetModalState, rewardDataState } from '../../../../../../stores/assets'
import File from '../../../../../Inputs/File'

interface Props {
    index: number
    asset: IReward
}

const RewardItem = ({ index, asset }: Props) => {
    const [isModalVisible, setModalVisible] = useRecoilState(assetModalState(asset._id || asset.id))
    const setRewardData = useSetRecoilState(rewardDataState)
    const setCategory = useSetRecoilState(categoryState(asset._id || asset.id))

    let imgsrc: string = '/icons/rewards/voucher.png'

    switch (asset.rewardType) {
        case 'voucher':
            imgsrc = '/icons/rewards/voucher.png'
            break
        case 'digital':
            imgsrc = '/icons/rewards/digital.png'
            break
        case 'physical':
            imgsrc = '/icons/rewards/physical.png'
            break
        default:
            break
    }

    const handleEdit = (): void => {
        setCategory(asset.category)
        setRewardData(asset)
        setModalVisible(!isModalVisible)
    }

    return (
        <div className="font-primary  ">
            <File name={asset.name} imageSrc={imgsrc} onClick={handleEdit} editable={true} />

            <UploadAssetModal
                id={asset._id || asset.id}
                visible={isModalVisible}
                toggleVisible={() => setModalVisible(!isModalVisible)}
            />
        </div>
    )
}

export default RewardItem
