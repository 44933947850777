import React, { Fragment, memo, useMemo } from 'react'
import { IWidget } from '../../../models/Widget'
import { Button } from '../../../stories/Button/Button'
import { ButtonGroup, StepTitle, StepWrapper } from '../style'

interface Props {
    widget: IWidget
    onClose: () => void
    prevStep: () => void
    nextStep: () => void
}

const Step7 = ({ prevStep, nextStep, onClose, widget }: Props) => {
    const renderStep = useMemo(() => {
        if (widget && widget.type === 'spinwheel') {
            return (
                <StepWrapper>
                    <StepTitle>
                        You can add as many segments as you like. If you are using a custom wheel
                        image, then the same number of segments should be added as there are in the
                        image. It is required.
                    </StepTitle>
                    <ButtonGroup>
                        <Button onClick={prevStep}>Back</Button>
                        <Button width="20px" variant="invert" onClick={onClose}>
                            Close
                        </Button>
                        <Button variant="secondary" onClick={nextStep}>
                            Done
                        </Button>
                    </ButtonGroup>
                </StepWrapper>
            )
        } else {
            return (
                <StepWrapper>
                    <StepTitle>You can add as many prizes as you like.</StepTitle>
                    <ButtonGroup>
                        <Button onClick={prevStep}>Back</Button>
                        <Button width="20px" variant="invert" onClick={onClose}>
                            Close
                        </Button>
                        <Button variant="secondary" onClick={nextStep}>
                            Done
                        </Button>
                    </ButtonGroup>
                </StepWrapper>
            )
        }
    }, [nextStep, onClose, prevStep, widget])

    return <Fragment>{renderStep}</Fragment>
}

export default memo(Step7)
