import { nanoid } from 'nanoid'

export interface IImage {
    _id?: string
    id: string
    type: string
    icon: string
    name: string
    settings: {
        src: string
        file: File | undefined
        fileName: string
        action: {
            linkUrl: string
            type: string
            viewIndex: null | number | string
            layerIndex: null | number | string
        }
    }
    appearanceSettings: {
        width: number
        height: number
        align: string
        paddingTop: number
        paddingBottom: number
        paddingLeft: number
        paddingRight: number
        marginTop: number
        marginBottom: number
        marginLeft: number
        marginRight: number
        borderBottomLeftRadius: number
        borderBottomRightRadius: number
        borderTopLeftRadius: number
        borderTopRightRadius: number
    }
}

class Image implements IImage {
    constructor(public name: string, public src: string) {
        this.name = name
        this.settings.src = src
    }
    id = nanoid()
    type = 'image'
    category = 'general'
    icon = '/icons/widgets/image_icon.svg'
    settings = {
        src: '/images/default_image.png',
        file: undefined,
        fileName: 'image',
        action: {
            linkUrl: 'https://deploy.link',
            type: 'none',
            viewIndex: 'none',
            layerIndex: 'none',
        },
    }
    appearanceSettings = {
        width: 90,
        height: 100,
        align: 'center',
        paddingTop: 0,
        paddingBottom: 0,
        paddingLeft: 0,
        paddingRight: 0,
        marginTop: 10,
        marginBottom: 10,
        marginLeft: 0,
        marginRight: 0,

        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
    }
    colourSettings = {}
}

export { Image }
