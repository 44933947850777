import { nanoid } from 'nanoid'
import { IWidget } from './Widget'

export interface ILayer {
    id: string
    type: string
    widgets: IWidget[]
    name: string
    category: string
    colourSettings: {
        'background colour': string[]
        'border colour': string[]
        'backdrop colour': string[]
    }
    appearanceSettings: {
        height: number
        width: number
        backgroundImageUrl: string
        backgroundFile: null | File
        backgroundFileName: string
        borderRadius?: number
        borderWidth?: number
        paddingTop: number
        paddingBottom: number
        paddingLeft: number
        paddingRight: number
        borderTopWidth?: number
        borderBottomWidth?: number
        borderLeftWidth?: number
        borderRightWidth?: number
        borderTopLeftRadius: number
        borderTopRightRadius: number
        borderBottomLeftRadius: number
        borderBottomRightRadius: number
        zIndex?: number
    }
    settings: {
        popupType: string
        popupClosing?: string
        autoTimer?: string
    }
}

class Layer implements ILayer {
    id = nanoid()
    type = 'popup'
    name = 'popup'
    icon = '/icons/builder/modal.svg'
    category = 'layout'
    appearanceSettings = {
        height: 50,
        width: 80,
        backgroundImageUrl: '',
        backgroundFile: null,
        backgroundFileName: '',
        borderRadius: 17,
        borderWidth: 0,
        paddingTop: 1,
        paddingBottom: 1,
        paddingLeft: 1,
        paddingRight: 1,
        borderTopWidth: 0,
        borderBottomWidth: 0,
        borderLeftWidth: 0,
        borderRightWidth: 0,
        borderTopLeftRadius: 17,
        borderTopRightRadius: 17,
        borderBottomLeftRadius: 17,
        borderBottomRightRadius: 17,
        zIndex: 10,
    }
    colourSettings = {
        'background colour': ['#333333', '#FFA7E1', '#0426FF'],
        'border colour': ['#000', '#FFA7E1', '#0426FF'],
        'backdrop colour': ['rgba(0, 0, 0, 0.5)', '#FFA7E1', '#0426FF'],
    }
    settings = {
        popupType: 'modal darkened background',
    }
    widgets = []
}

export default Layer
