import React, { HTMLAttributes, ReactNode } from 'react'
import styled from 'styled-components'
import { brandColors, Gray, Orange } from '../../styles/v2/styles'
import { Icon } from '../index'

enum colorTypes {
    primary = 'primary',
    success = 'success',
    weaning = 'weaning',
}
export interface Props extends HTMLAttributes<any> {
    icon?: string
    count?: number
    description?: string
    iconColor?: colorTypes | string
}

const AnalyticChip = styled.div`
    display: flex;
    flex-direction: row;
`

const AnalyticChipIconContainer = styled.div<{ color: string }>`
    display: flex;
    flex-shrink: none;
    justify-content: center;
    align-items: center;
    height: max-content;
    padding: 10px;
    background: ${(props) => props.color}20;
    border-radius: 8px;
`
const AnalyticChipDetailContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-left: 18px;
`

const AnalyticChipDetailCountContainer = styled.div<{ color: string }>`
    font-style: normal;
    font-weight: 600;
    font-size: 26px;
    line-height: 31px;
    color: ${(props) => props.color};
`

const AnalyticChipDetailInfoContainer = styled.div`
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: #717f95;
    @media (max-width: 750px) {
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        color: #717f95;
    }
`

const selectIconColor = (key: string): string => {
    switch (key) {
        case colorTypes.primary:
            return brandColors.blue
        case colorTypes.success:
            return brandColors.green
        case colorTypes.weaning:
            return brandColors.orange
        default:
            return key
    }
}

const Analytics = ({ icon, iconColor, count, description, ...props }: Props) => {
    return (
        <AnalyticChip>
            <AnalyticChipIconContainer color={selectIconColor(iconColor)}>
                <Icon name={icon} size="md" color={selectIconColor(iconColor)} />
            </AnalyticChipIconContainer>
            <AnalyticChipDetailContainer>
                <AnalyticChipDetailCountContainer color={selectIconColor(iconColor)}>
                    {count}
                </AnalyticChipDetailCountContainer>
                <AnalyticChipDetailInfoContainer>{description}</AnalyticChipDetailInfoContainer>
            </AnalyticChipDetailContainer>
        </AnalyticChip>
    )
}

export default Analytics
