import React, { HTMLAttributes } from 'react'
import styled, { css } from 'styled-components'

interface IColumnProps extends HTMLAttributes<HTMLDivElement> {
    sm?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12
    md?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12
    lg?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12
    xs?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12
    margin?: string
    minWidth?: string
}

const ColumnComponent = styled.div<{ sm; md; lg; xs; margin; minWidth }>`
    width: 100%;
    min-width: ${(props) => props.minWidth};
    display: flex;
    align-items: center;
    margin: ${(props) => props.margin};
    justify-content: flex-start;

    @media only screen and (max-width: 600px) {
        width: ${(props) => (props['xs'] / 12) * 100}%;
    }

    @media only screen and (min-width: 600px) {
        width: ${(props) => (props['sm'] / 12) * 100}%;
    }

    @media only screen and (min-width: 768px) {
        width: ${(props) => (props['md'] / 12) * 100}%;
    }

    @media only screen and (min-width: 992px) {
        width: ${(props) => (props['lg'] / 12) * 100}%;
    }
`

const Column = ({
    sm = 12,
    md = 12,
    lg = 12,
    xs = 12,
    margin,
    style,
    minWidth,
    children,
}: IColumnProps) => {
    return (
        <ColumnComponent
            sm={sm}
            md={md}
            lg={lg}
            xs={xs}
            margin={margin}
            minWidth={minWidth}
            style={style}
        >
            {children}
        </ColumnComponent>
    )
}

export default Column
