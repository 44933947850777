import React from 'react'

interface Props {
    campaigns: {[key:string]: {campaignName: string, redeemedQuantity: number}}
}

const CampaignList = ({campaigns}: Props) => {

    if(!campaigns){
        return <></>
    }

    return (
        <div>
            {Object.values(campaigns).length > 0 && (
                <div className="grid grid-cols-2 text-sm font-medium">
                    <p>Campaign Name</p>
                    <p>Redeemed Quantity</p>
                </div>
            )}
           
            {Object.values(campaigns).map((campaign, index) =>{
                return (
                    <div key={`${campaign.campaignName}-${index}`} className="grid grid-cols-2 text-sm my-1">
                        <p>{campaign.campaignName}</p>
                        <p>{campaign.redeemedQuantity}</p>
                    </div>
                )
            })}
            
        </div>
    )
}

export default CampaignList
