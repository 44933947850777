import { motion } from 'framer-motion'
import React, { ChangeEvent, useEffect, useState } from 'react'
import styled from 'styled-components'
import { BorderPropsType } from '../../../components/Widgets/DesignSettings/Border'
import { Icon } from '../../index'
import { InputValue, SizerContainer, Unit, Wrapper } from '../Sizer/Sizer'
import { BorderRadiusCustom } from './BorderCustom'

export const BorderWidth = ({
    label,
    widget,
    isCustomBorder,
    setIsCustomBorder,
    handleChange,
    handleChangeBorderWidth,
}: BorderPropsType) => {
    const checkCustomBorder = () => {
        setIsCustomBorder((prev) => !prev)
    }

    const borderTopWidth = widget?.appearanceSettings
        ? widget?.appearanceSettings?.borderTopWidth
        : widget?.settings?.borderTopWidth
    const borderBottomWidth = widget?.appearanceSettings
        ? widget?.appearanceSettings?.borderBottomWidth
        : widget?.settings?.borderBottomWidth
    const borderLeftWidth = widget?.appearanceSettings
        ? widget?.appearanceSettings?.borderLeftWidth
        : widget?.settings?.borderLeftWidth
    const borderRightWidth = widget?.appearanceSettings
        ? widget?.appearanceSettings?.borderRightWidth
        : widget?.settings?.borderRightWidth
    const borderWidth = widget?.appearanceSettings
        ? widget?.appearanceSettings?.borderWidth
        : widget?.settings?.borderWidth

    useEffect(() => {
        if (
            borderTopWidth === borderBottomWidth &&
            borderBottomWidth === borderLeftWidth &&
            borderLeftWidth === borderRightWidth &&
            borderRightWidth === borderTopWidth
        ) {
            setIsCustomBorder(false)
        } else {
            setIsCustomBorder(true)
        }
    }, [borderBottomWidth, borderLeftWidth, borderRightWidth, borderTopWidth])

    return (
        <BorderSettingWrapper>
            <BorderContainer>
                <ContainerLabel>{label}</ContainerLabel>
                <ContainerBody>
                    <SizerContainer>
                        <ContainerBody>
                            <Wrapper>
                                <InputValue
                                    disabled={isCustomBorder}
                                    type="number"
                                    value={
                                        borderTopWidth ||
                                        borderBottomWidth ||
                                        borderLeftWidth ||
                                        borderRightWidth ||
                                        borderWidth
                                    }
                                    onChange={handleChangeBorderWidth}
                                />
                                <Unit>px</Unit>
                            </Wrapper>
                        </ContainerBody>
                    </SizerContainer>
                    <BorderIcon isCustomBorder={isCustomBorder} onClick={checkCustomBorder}>
                        <div style={{ padding: 5 }}>
                            <Icon
                                name="border-radius"
                                size={'sm'}
                                color={isCustomBorder ? '#6478F9' : ''}
                            />
                        </div>
                    </BorderIcon>
                </ContainerBody>
            </BorderContainer>
            {isCustomBorder && (
                <MultiSelectContainer
                    initial={{ opacity: 0, y: 50, scale: 0.3 }}
                    animate={{ opacity: 1, y: 0, scale: 1 }}
                    exit={{ opacity: 0, scale: 0.5, transition: { duration: 0.2 } }}
                >
                    <TopBottomMultiSelectContainer>
                        <BorderRadiusCustom
                            direction="Top"
                            value={borderTopWidth}
                            onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                handleChange(e, 'borderTopWidth')
                            }
                        />
                    </TopBottomMultiSelectContainer>
                    <MiddleMultiSelectContainer>
                        <BorderRadiusCustom
                            direction="Left"
                            value={borderLeftWidth}
                            onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                handleChange(e, 'borderLeftWidth')
                            }
                        />
                        <BorderRadiusCustom
                            direction="Right"
                            value={borderRightWidth}
                            onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                handleChange(e, 'borderRightWidth')
                            }
                        />
                    </MiddleMultiSelectContainer>
                    <TopBottomMultiSelectContainer>
                        <BorderRadiusCustom
                            direction="Bottom"
                            value={borderBottomWidth}
                            onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                handleChange(e, 'borderBottomWidth')
                            }
                        />
                    </TopBottomMultiSelectContainer>
                </MultiSelectContainer>
            )}
        </BorderSettingWrapper>
    )
}

export const BorderSettingWrapper = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
`

export const BorderContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    color: #717f95;
    margin-right: 20px !important;
`

const ContainerLabel = styled.label`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin-right: 10px;
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    color: #3c4b61;
    margin-bottom: 7px;
`

export const ContainerBody = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-end;
`

export const RadiusBody = styled.div`
    display: flex;
    align-items: center;
    border: 1px solid rgb(223, 226, 242);
    width: 50px;
    height: 32px;
    padding: 5px;
    border-radius: 8px;
    margin-right: 10px;
    & > input {
        width: 35px;
        height: 28px;
        border-radius: 8px;
        border: none;
        padding-left: 7px;
        color: #192536;
        font-weight: 600;
    }
`

export const BorderIcon = styled.div.attrs((props) => ({}))<{ isCustomBorder: boolean }>`
    transition: border 0.2s;
    border: ${(props) => (props.isCustomBorder ? `1px solid #6478F9` : `1px solid #DFE2F2`)};
    width: 32px;
    display: flex;
    height: 32px;
    border-radius: 8px;
    margin-right: 10px;
    cursor: pointer;
    align-items: center;
    justify-content: center;
`
export const MultiSelectContainer = styled(motion.div)`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 15px;
`
export const TopBottomMultiSelectContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
`
export const MiddleMultiSelectContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 285px;
`
