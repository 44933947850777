import { atom, atomFamily } from 'recoil'
import Content, { IContent, IContentType } from '../../models/Assets/Content'
import Reward, { IReward } from '../../models/Assets/Reward'
import { AssetCategory } from '../../types/Assets/Asset'

export const rewardsState = atom<IReward[]>({
    key: 'rewardsState',
    default: [],
})

export const rewardDataState = atom<IReward>({
    key: 'rewardData',
    default: new Reward(),
})

export const contentDataState = atom<IContent>({
    key: 'contentData',
    default: new Content(),
})

export const assetModalState = atomFamily<boolean, string>({
    key: 'assetModal',
    default: false,
})

export const categoryState = atomFamily<string, string>({
    key: 'assetCategory',
    default: AssetCategory.CONTENT,
})

export const contentSettingToggleState = atomFamily<boolean, string>({
    key: 'contentSettingToggle',
    default: false,
})

export interface IContentModalState {
    src: string
    srcName: string
    srcType: string
    visible: boolean
    category: IContentType
    page: number
    limit: number
}

export const contentModalState = atomFamily<IContentModalState, string>({
    key: 'contentModal',
    default: {
        src: '',
        srcName: '',
        srcType: '',
        visible: false,
        category: IContentType.IMAGE,
        page: 1,
        limit: 24,
    },
})
