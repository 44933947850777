import { css } from 'styled-components'

// Global style variables

export const White = '#FFFFFF'
export const Red = '#F5393D'
export const Blue = '#6478F9'
export const Green = '#3AE29B'
export const Orange = '#FF7D34'
export const Gray = '#DFE2F2'
export const DarkGray = '#313945'
export const SuperGray = '#6d6d6e'

export const DarkBlue = '#192536'
export const SoftBlue = '#3C4B61'
export const LightBlue = '#717F95'

export const brandColors = {
    blue: Blue,
    green: Green,
    orange: Orange,
}

export const textColors = {
    dark_blue: DarkBlue,
    soft_blue: SoftBlue,
    light_blue: LightBlue,
}

export const commonColors = {
    ux_error: Red,
    line_color: Gray,
    icon_default: SoftBlue,
}

export const buttonColors = {
    primary_background: White,
    primary_text: LightBlue,
    primary_border: Gray,
    secondary_background: Blue,
    secondary_text: White,
    secondary_border: LightBlue,
}

export const spacing = {
    padding: {
        small: 10,
        medium: 20,
        large: 30,
    },
    borderRadius: {
        small: 5,
        default: 10,
    },
}
