import React, { useEffect, useRef } from 'react'
import { useState } from 'react'
import styled from 'styled-components'

interface Props {
    title?: string
    width?: string
    value?: string | number
    items: Array<string | number>
    onSelect?: (any) => void
}

const Container = styled.div.attrs((props) => ({}))<{
    width: string
}>`
    width: ${(props) => (props.width ? props.width : '284px')};
    height: 38px;
    background: #ffffff;
    border: 1px solid #dfe2f2;
    box-sizing: border-box;
    border-radius: 10px;
    cursor: pointer;

    font-family: Matter;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: #717f95;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    z-index: 2;

    p {
        padding-top: 5px;
        margin-right: auto;
        padding-left: 17px;
    }

    .arrow {
        padding-top: 1px;
        height: 5px;
        margin-left: auto;
        padding-right: 17px;
    }
`

const Wrapper = styled.div.attrs((props) => ({}))<{
    width: string
}>`
    display: flex;
    flex-direction: column;
    width: ${(props) => (props.width ? props.width : '284px')};

    .list {
        list-style: none;
        width: ${(props) => (props.width ? props.width : '284px')};
        border: 1px solid #dfe2f2;
        box-sizing: border-box;
        border-radius: 10px;
        padding: 10px;
        margin-top: 50px;
        background-color: white;

        position: absolute;
        object-fit: cover;
        z-index: 50;
    }

    .list-item {
        margin-top: 3px;
        list-style-type: none;
        padding-top: 2px;
    }

    button {
        width: 100%;
        text-align: left;
        font-family: Matter;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        background-color: white;
        border: none;
        z-index: -1;
        padding-left: 10px;
        padding-top: 5px;
        margin-bottom: 2.5px;
        border-radius: 5px;
    }

    button:hover {
        background-color: #6478f9;
        color: white;
    }
`

export const DropMenu = ({ title, width, items, value, onSelect }: Props) => {
    const [open, setOpen] = useState(false)
    const [selection, setSelection] = useState(title ? title : items[0])
    const toggle = () => setOpen(!open)

    function handleOnClick(item) {
        if (selection !== item) {
            // the selected item is not already selected
            setSelection(item)
            console.log('set', item)
        }

        if (onSelect) {
            onSelect(item)
        }

        toggle()
    }

    let menuRef = useRef<HTMLDivElement>()

    useEffect(() => {
        let handler = (event) => {
            if (!menuRef.current.contains(event.target)) {
                setOpen(false)
            }
        }

        document.addEventListener('mousedown', handler)

        return () => {
            document.removeEventListener('mousedown', handler)
        }
    })

    return (
        <Wrapper width={width} ref={menuRef}>
            <Container
                tabIndex={0}
                width={width}
                onKeyPress={() => toggle()}
                onClick={() => toggle()}
            >
                <p>{value ? value : selection ? selection : title}</p>
                <div className="arrow">
                    <svg
                        width="6"
                        height="5"
                        viewBox="0 0 6 5"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M0.258295 1.7585L2.41663 3.91683C2.74163 4.24183 3.26663 4.24183 3.59163 3.91683L5.74996 1.7585C6.27496 1.2335 5.89996 0.333496 5.1583 0.333496H0.841628C0.0999614 0.333496 -0.266705 1.2335 0.258295 1.7585Z"
                            fill="#3C4B61"
                        />
                    </svg>
                </div>
            </Container>

            {open && (
                <ul className="list">
                    {items.map((item, key) => (
                        <li className="list-item" key={item.toString() + key}>
                            <button
                                type="button"
                                onClick={() => {
                                    handleOnClick(item)
                                }}
                            >
                                <span>{item}</span>
                            </button>
                        </li>
                    ))}
                </ul>
            )}
        </Wrapper>
    )
}

// Implementation through on other pages
// export const CheckBox = () => {
//     const [isEnabled, setEnabled] = useState<boolean>(false)

//     return <CheckBox onChange={(event) => setEnabled(event.target.checked)} />
// }
