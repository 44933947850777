import React, { memo } from 'react'
import { Button } from '../../../stories/Button/Button'
import { ButtonGroup, StepTitle, StepWrapper } from '../style'

interface Props {
    onClose: () => void
    prevStep: () => void
    nextStep: () => void
}

const Step11 = ({ prevStep, nextStep, onClose }: Props) => {
    return (
        <StepWrapper>
            <video muted loop autoPlay style={{ borderRadius: '15px' }}>
                <source src="/video/rewardasset.mp4" type="video/mp4" />
            </video>
            <StepTitle>
                This field is required if your segment is a winning segment. All prizes that are in
                your game must first be added in the assets tab of the sidebar.
            </StepTitle>
            <ButtonGroup>
                <Button onClick={prevStep}>Back</Button>
                <Button width="20px" variant="invert" onClick={onClose}>
                    Close
                </Button>
                <Button variant="secondary" onClick={nextStep}>
                    All added
                </Button>
            </ButtonGroup>
        </StepWrapper>
    )
}

export default memo(Step11)
