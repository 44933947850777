import React, { HTMLAttributes, useState, useEffect, useMemo, ChangeEvent } from 'react'
import styled from 'styled-components'
import { Sizer } from '../Sizer/Sizer'

export interface Props extends HTMLAttributes<HTMLDivElement> {
    label: string
    widgetName?: string
    handleColumnChange?: (id: number) => () => void
    columnsSizeChange?: (e: ChangeEvent<HTMLInputElement>, attr: string) => void
    columns: number
    columnsSize?: number
}

const Columns = ({
    label,
    widgetName,
    columns,
    columnsSize,
    columnsSizeChange,
    handleColumnChange,
}: Props) => {
    const columnsData = [
        { id: 1, link: '/images/v2/group-1.svg', height: '22px', width: '24px' },
        { id: 2, link: '/images/v2/group-2.svg', height: '24px', width: '24px' },
        { id: 3, link: '/images/v2/group-3.svg', height: '24px', width: '24px' },
    ]

    const handleColumnsSizeChange = (e: ChangeEvent<HTMLInputElement>) => {
        columnsSizeChange(e, 'buttonSpacing')
    }

    const checkLabel = () => {
        if (widgetName) {
            return `${widgetName[0].toUpperCase() + widgetName.slice(1)} ${label}`
        } else {
            return `${label[0].toUpperCase() + label.slice(1)}`
        }
    }

    const renderColumns = useMemo(
        () =>
            columnsData.map((item) => (
                <BorderIcon
                    key={item.id}
                    isCustomBorder={columns === item.id}
                    onClick={handleColumnChange(item.id)}
                >
                    <div>
                        <FillIcon
                            link={item.link}
                            color={columns === item.id ? '#6478F9' : '#717F95'}
                            width={item.width}
                            height={item.height}
                        />
                    </div>
                </BorderIcon>
            )),
        [columns]
    )

    return (
        <ColumnsContainer>
            <ContainerLabel>{checkLabel()}</ContainerLabel>
            <ContainerBody>{renderColumns}</ContainerBody>
            <Sizer
                disabled={columns === 1}
                label="gap between columns"
                size={columnsSize}
                handleSizeChange={handleColumnsSizeChange}
            />
        </ColumnsContainer>
    )
}

export default Columns

const ColumnsContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    color: #717f95;
    margin-top: 18px;
`

const ContainerLabel = styled.label`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin-bottom: 7px;
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    color: #3c4b61;
`

const ContainerBody = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 18px;
`

const BorderIcon = styled.div.attrs((props) => ({}))<{ isCustomBorder: boolean }>`
    transition: border 0.2s;
    border: ${(props) => (props.isCustomBorder ? `1px solid #6478F9` : `1px solid #DFE2F2`)};
    padding: 5px;
    border-radius: 8px;
    margin-right: 5px;
    cursor: pointer;
`

const FillIcon = styled.div.attrs((props) => ({}))<{
    link: string
    color: string
    width: string
    height: string
}>`
    transition: background-color 0.2s;
    width: ${(props) => props.width};
    height: ${(props) => props.height};
    background-color: ${(props) => props.color};
    mask: url(${(props) => props.link}) no-repeat center / contain;
    -webkit-mask: url(${(props) => props.link}) no-repeat center / contain;
`
