import React, { Fragment, memo, useMemo } from 'react'
import { IWidget } from '../../../models/Widget'
import { Button } from '../../../stories/Button/Button'
import { ButtonGroup, StepTitle, StepWrapper } from '../style'

interface Props {
    widget: IWidget
    onClose: () => void
    prevStep: () => void
    nextStep: () => void
}

const Step4 = ({ prevStep, nextStep, onClose, widget }: Props) => {
    const renderStep = useMemo(() => {
        if (widget && widget.type === 'spinwheel') {
            return (
                <StepWrapper>
                    <StepTitle>
                        <strong>Customise: The Appearance</strong>
                        <br />
                        We are working on expanding the wheel design customization options.
                    </StepTitle>
                    <ButtonGroup>
                        <Button onClick={prevStep}>Back</Button>
                        <Button width="20px" variant="invert" onClick={onClose}>
                            Close
                        </Button>
                        <Button variant="secondary" onClick={nextStep}>
                            Next
                        </Button>
                    </ButtonGroup>
                </StepWrapper>
            )
        }
        if (widget && widget.type === 'scratchcard') {
            return (
                <StepWrapper>
                    <StepTitle>
                        <strong>Customise: The Appearance</strong>
                        <br />
                        If you want to add a custom foreground, then this is for you.
                    </StepTitle>
                    <ButtonGroup>
                        <Button onClick={prevStep}>Back</Button>
                        <Button width="20px" variant="invert" onClick={onClose}>
                            Close
                        </Button>
                        <Button variant="secondary" onClick={nextStep}>
                            Next
                        </Button>
                    </ButtonGroup>
                </StepWrapper>
            )
        }
        if (widget && widget.type === 'flipcard') {
            return (
                <StepWrapper>
                    <StepTitle>
                        <strong>Customise: The Appearance</strong>
                        <br />
                        If you want to add a custom foreground, then this is for you.
                    </StepTitle>
                    <ButtonGroup>
                        <Button onClick={prevStep}>Back</Button>
                        <Button width="20px" variant="invert" onClick={onClose}>
                            Close
                        </Button>
                        <Button variant="secondary" onClick={nextStep}>
                            Next
                        </Button>
                    </ButtonGroup>
                </StepWrapper>
            )
        }
        if (widget && widget.type === 'puzzle') {
            return (
                <StepWrapper>
                    <StepTitle>
                        <strong>Customise: The Appearance</strong>
                        <br />
                        If the block is in the right order, then your brand will be displayed on it.
                    </StepTitle>
                    <ButtonGroup>
                        <Button onClick={prevStep}>Back</Button>
                        <Button width="20px" variant="invert" onClick={onClose}>
                            Close
                        </Button>
                        <Button variant="secondary" onClick={nextStep}>
                            Next
                        </Button>
                    </ButtonGroup>
                </StepWrapper>
            )
        }
        if (widget && widget.type === 'quiz') {
            return (
                <StepWrapper>
                    <StepTitle>
                        <strong>Create your questions</strong>
                        <br />
                        Add questions and answers here. Mark the correct answer with a checkbox.
                    </StepTitle>
                    <ButtonGroup>
                        <Button onClick={prevStep}>Back</Button>
                        <Button width="20px" variant="invert" onClick={onClose}>
                            Close
                        </Button>
                        <Button variant="secondary" onClick={nextStep}>
                            Next
                        </Button>
                    </ButtonGroup>
                </StepWrapper>
            )
        }
    }, [nextStep, onClose, prevStep, widget])

    return <Fragment>{renderStep}</Fragment>
}

export default memo(Step4)
