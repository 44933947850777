import React, { memo } from 'react'
import { Button } from '../../../stories/Button/Button'
import { ButtonGroup, StepTitle, StepWrapper } from '../style'

interface Props {
    onClose: () => void
    prevStep: () => void
    nextStep: () => void
}

const Step12 = ({ prevStep, nextStep, onClose }: Props) => {
    return (
        <StepWrapper>
            <StepTitle>
                To add more information at the end of the game, you can use this section. By
                default, after the end of the game, the player will be redirected to the first page.
                But for an example, you can redirect it to a page with a description of the prize
                and how the player can claim it.
            </StepTitle>
            <ButtonGroup>
                <Button onClick={prevStep}>Back</Button>
                <Button width="20px" variant="invert" onClick={onClose}>
                    Close
                </Button>
                <Button variant="secondary" onClick={nextStep}>
                    It&#39;s all?
                </Button>
            </ButtonGroup>
        </StepWrapper>
    )
}

export default memo(Step12)
