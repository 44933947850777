import React from 'react'

interface Props {
    name: string
    deleteContent: any
    download: () => void
    setToggle: (value: boolean) => void
}

const ContentToggle = ({ name, deleteContent, download, setToggle }: Props) => {
    return (
        <>
            <div
                className={`block fixed inset-0 z-40`}
                onClick={() => {
                    setToggle(false)
                }}
            ></div>
            <div
                className={`flex absolute bg-white flex-col items-center justify-center right-0 z-50 rounded-md text-sm p-1 object-cover border`}
                style={{ width: '136px', minHeight: '136px', top: '0%', left: '0%' }}
            >
                <ul>
                    <li className="mt-2">
                        <button
                            type="button"
                            className="flex items-center my-1  focus:outline-none text-xs"
                            onClick={() => download()}
                        >
                            <img
                                className="mr-1"
                                src="/icons/download.png"
                                alt="download"
                                height="20px"
                                width="20px"
                            />
                            Download
                        </button>
                    </li>
                    <li>
                        <button
                            type="button"
                            className="flex items-center focus:outline-none text-xs"
                            onClick={() => deleteContent()}
                        >
                            {' '}
                            <img
                                className="mr-1"
                                src="/icons/trash-grey.png"
                                alt="download"
                                height="20px"
                                width="20px"
                            />
                            Delete
                        </button>
                    </li>
                </ul>
            </div>
        </>
    )
}

export default ContentToggle
