export const safeName = (filename: string) => {
    let newName = filename
    // parenthesis replacement
    newName = newName.replace('(', '%28')
    newName = newName.replace(')', '%29')
    // square bracket replacement
    newName = newName.replace('[', '%5B')
    newName = newName.replace(']', '%5B')

    return newName
}
