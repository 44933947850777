import { useState, useEffect } from 'react'

export const useMedia = () => {
    const [isMobile, setMobile] = useState(false)

    const onResize = () => {
        const isMobile = window.innerWidth < 1150
        setMobile(isMobile)
    }

    useEffect(() => {
        window.addEventListener('resize', onResize)
        return () => {
            window.removeEventListener('resize', onResize)
        }
    }, [])

    return { isMobile }
}
