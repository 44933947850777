import React from 'react'
import { useRecoilState, useSetRecoilState } from 'recoil'
import { CustomModal } from '../../../../Common/Modal'
import RewardAsset from './Rewards/RewardAsset'
import { AssetCategory } from '../../../../../types/Assets/Asset'
import ContentSettings from './Content/ContentSettings'
import { categoryState, contentDataState, rewardDataState } from '../../../../../stores/assets'
import Content from '../../../../../models/Assets/Content'
import Reward from '../../../../../models/Assets/Reward'
import styled from 'styled-components'
import { DropMenu } from '../../../../../stories/Forms/DropMenu'

interface Props {
    id: string
    visible: boolean
    toggleVisible: () => void
    isCreator?: boolean
}

const Body = styled.section`
    width: auto;
    height: auto;
    background-color: white;
    border: 1px solid #e3e7ff;
    box-sizing: border-box;
    border-radius: 16px;

    padding: 28px;

    font-family: Matter;
    font-style: normal;
    font-weight: normal;

    h1 {
        font-size: 18px;
        color: #3c4b61;
    }

    p {
        font-size: 14px;
        color: #3c4b61;
    }

    h2 {
        font-size: 13px;
        color: #3c4b61;

        margin-bottom: 10px;
    }

    h3 {
        font-size: 12px;
        font-style: italic;
        color: #3c4b61;
    }

    h4 {
        font-size: 12px;
    }
`

const UploadAssetModal = ({ id, visible, toggleVisible, isCreator = false }: Props) => {
    const [category, setCategory] = useRecoilState(categoryState(id))
    const setContentData = useSetRecoilState(contentDataState)
    const setRewardData = useSetRecoilState(rewardDataState)

    const handleAssetCategoryChange = (value): void => {
        if (value === AssetCategory.CONTENT) {
            setContentData(new Content())
        } else if (value === AssetCategory.REWARD) {
            setRewardData(new Reward())
        }

        setCategory(value)
    }

    return (
        <CustomModal visible={visible} toggleVisible={toggleVisible} title="Upload Asset">
            <Body>
                <div className="flex items-center justify-between mb-3">
                    <h1>{id === 'add' ? 'Upload asset' : 'Edit asset'}</h1>
                    <svg
                        onClick={toggleVisible}
                        className="cursor-pointer"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M18 5.91016L6 18.0902"
                            stroke="#3C4B61"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                        <path
                            d="M6 5.91016L18 18.0902"
                            stroke="#3C4B61"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                    </svg>
                </div>

                {id === 'add' && !isCreator && (
                    <div className="">
                        <p>Asset Category</p>
                        <DropMenu
                            width="284px"
                            items={['content', 'reward']}
                            value={category}
                            onSelect={handleAssetCategoryChange}
                        />
                    </div>
                )}

                {category === AssetCategory.CONTENT && <ContentSettings id={id} />}

                {category === AssetCategory.REWARD && <RewardAsset id={id} />}
            </Body>
        </CustomModal>
    )
}

export default UploadAssetModal
