import {atom} from 'recoil'

interface MobilePreviewSize {
    width: number
    height: number
}

export const mobilePreviewState = atom<MobilePreviewSize>({
    key: "mobilePreviewState",
    default: {width: 200, height: 200}
})