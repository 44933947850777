import {RewardType} from "./Rewards/RewardType"
import { VoucherSettings } from "./Rewards/Voucher"

export type Asset = {
    _id?: string
    id: string
    createdAt: string
    category: string
    name: string
    rewardType?: RewardType,
    quantity?: number
    voucherSettings?: VoucherSettings
    digitalSettings?: any
}

export enum AssetCategory  {
    REWARD = 'reward',
    CONTENT = 'content',
    PRODUCT = 'product'
}