import React, { SetStateAction, useEffect, useRef, useState } from 'react'
import styled, { keyframes } from 'styled-components'
import ExpandMoreOutlinedIcon from '@material-ui/icons/ExpandMoreOutlined'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'

interface SelectPropsTypes {
    options: any
    placeholder?: string
    label?: string
    size?: 'small' | 'medium'
    width?: string
    onHandleChange?: any
    defaultValue?: string
    name?: string
}

export const SimpleSelect = ({
    options,
    placeholder,
    label,
    size,
    name,
    width,
    defaultValue,
    onHandleChange,
}: SelectPropsTypes) => {
    const [visibility, setVisibility] = useState(false)
    const [selectedOption, setSelectedOption] = useState(defaultValue || '')
    let menuRef = useRef<HTMLDivElement>()

    useEffect(() => {
        const handler = (event) => {
            if (!menuRef.current.contains(event.target)) {
                setVisibility(false)
            }
        }
        document.addEventListener('mousedown', handler)
        return () => {
            document.removeEventListener('mousedown', handler)
        }
    })

    const onHandleOpenOptions = () => {
        setVisibility((prev) => !prev)
    }

    const onHandleSelected = (option: SetStateAction<string>) => () => {
        if (name === 'time') {
            setSelectedOption(`${option}:00 UTC`)
        } else {
            setSelectedOption(option)
        }
        onHandleChange(option)
    }

    return (
        <div className="custom_select" ref={menuRef}>
            <FormLabel>{label}</FormLabel>
            <Select
                selectSize={size}
                width={width}
                onClick={onHandleOpenOptions}
                onKeyPress={onHandleOpenOptions}
            >
                <SelectedOption>
                    <span title={selectedOption === '' ? placeholder : selectedOption}>
                        {selectedOption === ''
                            ? placeholder
                            : selectedOption.length <= 20 || typeof selectedOption === 'number'
                            ? selectedOption
                            : `${selectedOption?.slice(0, 20)}...`}
                    </span>
                    {size === 'small' ? (
                        <ArrowDropDownIcon className={visibility ? 'rotate' : ''} />
                    ) : (
                        <ExpandMoreOutlinedIcon className={visibility ? 'rotate' : ''} />
                    )}
                </SelectedOption>
                {visibility && (
                    <Options optionSize={size} width={width}>
                        <ul>
                            {name === 'dayOfMonth' ? (
                                <>
                                    <li
                                        className={
                                            selectedOption === 'start' ? 'active-option' : ''
                                        }
                                        onClick={onHandleSelected('start')}
                                        style={{
                                            display: 'flex',
                                            alignItems: 'flex-end',
                                        }}
                                    >
                                        Start of month
                                    </li>
                                    <li
                                        className={selectedOption === 'end' ? 'active-option' : ''}
                                        onClick={onHandleSelected('end')}
                                        style={{
                                            display: 'flex',
                                            alignItems: 'flex-end',
                                        }}
                                    >
                                        End of month
                                    </li>
                                    {options?.map((item, index) => (
                                        <li
                                            key={index}
                                            className={
                                                selectedOption === index + 1 ? 'active-option' : ''
                                            }
                                            onClick={onHandleSelected(index + 1)}
                                            style={{ display: 'flex', alignItems: 'flex-end' }}
                                        >
                                            {index + 1}
                                        </li>
                                    ))}
                                </>
                            ) : (
                                options?.map((option, index) => {
                                    if (name === 'time') {
                                        let hour = index
                                        if (index < 10) {
                                            hour = 0 + index
                                        }
                                        return (
                                            <li
                                                key={index}
                                                className={
                                                    selectedOption === hour ? 'active-option' : ''
                                                }
                                                onClick={onHandleSelected(hour)}
                                                style={{
                                                    display: 'flex',
                                                    alignItems: 'flex-end',
                                                }}
                                            >
                                                {hour}:00 UTC
                                            </li>
                                        )
                                    } else {
                                        return (
                                            <li
                                                key={index}
                                                className={
                                                    selectedOption === option ? 'active-option' : ''
                                                }
                                                onClick={onHandleSelected(option)}
                                                style={{
                                                    display: 'flex',
                                                    alignItems: 'flex-end',
                                                }}
                                            >
                                                {option}
                                            </li>
                                        )
                                    }
                                })
                            )}
                        </ul>
                    </Options>
                )}
            </Select>
        </div>
    )
}

const Select = styled.div.attrs((props) => ({}))<{
    selectSize: string
    width: string
}>`
    width: ${(props) => (props.width ? props.width : 'inherit')};
    height: ${(props) =>
        props.selectSize === 'small' ? '32px' : props.selectSize === 'medium' ? '38px' : '38px'};
    display: inline-block;
    padding: 0 16px;
    border-radius: 10px;
    background: #fff;
    border: 1px solid #dfe2f2;
    position: relative;
    cursor: pointer;
`
const SelectedOption = styled.div`
    height: inherit;
    display: flex;
    align-items: center;
    justify-content: space-between;
    span {
        font-size: 14px;
        color: #717f95;
    }
    svg {
        transition: transform 0.2s ease-in-out;
        &.rotate {
            transform: rotate(180deg);
        }
    }
`
const fadeIn = keyframes`
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	 }
`
const Options = styled.div.attrs((props) => ({}))<{
    optionSize: string
    width: string
}>`
    animation: 0.3s ${fadeIn} ease-out;
    position: absolute;
    z-index: 999;
    background: #fff;
    top: ${(props) =>
        props.optionSize === 'small' ? '38px' : props.optionSize === 'medium' ? '45px' : '45px'};
    left: -1px;
    width: ${(props) => (props.width ? props.width : 'inherit')};
    max-height: 249px;
    overflow-y: auto;
    padding: 8px;
    border-radius: 10px;
    border: 1px solid #dfe2f2;
    ul {
        margin: 0;
        padding: 0;
        li {
            height: 32px;
            font-size: 14px;
            list-style: none;
            line-height: 29px;
            padding: 0 10px;
            cursor: pointer;
            border-radius: 6px;
            color: #3c4b61;
            transition: 0.3s linear;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            &:hover {
                color: #000;
                background: rgba(100, 120, 249, 0.15);
            }
            &.active-option {
                color: #000;
                background: rgba(10, 39, 206, 0.082);
            }
        }
    }
`
const FormLabel = styled.p`
    margin: 6px 0;
    font-size: 14px;
    line-height: 14px;
    color: #3c4b61;
    font-weight: bold;
`
