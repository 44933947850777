import React from 'react'
import { withStyles, Theme, createStyles } from '@material-ui/core/styles'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import SimpleBar from 'simplebar-react'
import 'simplebar/dist/simplebar.min.css'
import TooltipContent from '../Tooltips/TooltipContent'

interface Props {
    children: React.ReactNode
    value?: number
    handleChange?: (event: React.ChangeEvent<{}>, value: number) => void
    widget?: any
}

interface StyledTabProps {
    label: string
}

interface StyledTabsProps {
    value: number
    onChange: (event: React.ChangeEvent<{}>, newValue: number) => void
}

const StyledTab = withStyles((theme: Theme) =>
    createStyles({
        root: {
            textTransform: 'none',
            color: '#717F95',
            minWidth: 'auto !important',
            fontSize: theme.typography.pxToRem(15),
            '&:focus': {
                opacity: 1,
                outline: 'none',
            },
            '&$selected': {
                color: '#6478F9 !important',
                outline: 'none',
            },
        },
        selected: {},
    })
)((props: StyledTabProps) => <Tab disableRipple {...props} />)

const StyledTabs = withStyles({
    indicator: {
        display: 'flex',
        justifyContent: 'space-between !important',
        backgroundColor: 'transparent',
        '& > span': {
            width: '100%',
            backgroundColor: '#6478F9',
        },
    },
})((props: StyledTabsProps) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />)

const ContainerWrapper = ({ children, value, handleChange, widget }: Props) => {
    return (
        <div className="flex flex-col items-center pt-2">
            <div style={{ width: '320px', borderBottom: '1px solid #DFE2F2', marginRight: 'auto' }}>
                <StyledTabs
                    value={value}
                    onChange={handleChange}
                    aria-label="widget setting and appearance tabs"
                >
                    <StyledTab label="Settings" />
                    {widget?.category === 'game' && <StyledTab label="Prize segments" />}
                    <StyledTab label="Design" />

                    <TooltipContent step={13} placement="bottom">
                        <div></div>
                    </TooltipContent>
                </StyledTabs>
            </div>
            <SimpleBar
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%',
                    overflowX: 'hidden',
                }}
            >
                {children}
            </SimpleBar>
        </div>
    )
}

export default ContainerWrapper
