import { atom } from 'recoil'

export const tooltipModal = atom<boolean>({
    key: 'tooltipModal',
    default: false,
})

export const tooltipButton = atom<boolean>({
    key: 'tooltipButtom',
    default: false,
})

export const tooltipState = atom<number | null>({
    key: 'tooltipState',
    default: null,
})
