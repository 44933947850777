import React, { forwardRef } from 'react'
import { ReactSortable } from 'react-sortablejs'
import { Image } from '../../../../../../models/Image'
import { Video } from '../../../../../../models/General/Video'
import RenderContentWidget from './RenderContentWidget'
import { IContent } from '../../../../../../models/Assets/Content'
import styled from 'styled-components'

interface Props {
    assets: IContent[]
}

const Grid = styled.div`
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    gap: 12px 12px;

    @media (min-width: 768px) {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }
`

const CustomComponent = forwardRef<HTMLDivElement, any>((props, ref) => {
    return <Grid ref={ref}>{props.children}</Grid>
})

const ContentSortable = ({ assets }: Props) => {
    if (assets.length === 0) {
        return <></>
    }

    const widgets = assets.map((asset) => {
        let widget
        switch (asset.type) {
            case 'image':
                widget = new Image(asset.fileName, asset.fileUrl)
                return { ...widget, _id: asset._id }
            case 'video':
                widget = new Video(asset.fileName, asset.fileUrl)
                return { ...widget, _id: asset._id }
            default:
                return asset
        }
    })

    return (
        <ReactSortable
            tag={CustomComponent}
            key={`widgets-sidebar`}
            group={{ name: 'widgets', pull: 'clone', put: false }}
            sort={false}
            list={widgets}
            setList={(currentList) => {
                return currentList
            }}
        >
            {widgets.map((widget, index) => {
                return <RenderContentWidget key={`content-preview-${index}`} widget={widget} />
            })}
        </ReactSortable>
    )
}

export default ContentSortable
