import { Toast } from '../../helpers/Toast'

export const clientUpload = async (file: File, category: string): Promise<string | null> => {
    const fileName = file.name.replace(/\s+/g, '-')

    const res = await fetch(`/api/content/upload-url?category=${category}&file=${fileName}`, {
        method: 'POST',
    })

    const { url, fields } = await res.json()
    const fileUrl = url + fields['key']

    const formData = new FormData()

    Object.entries({ ...fields, file }).forEach(([key, value]) => {
        formData.append(key, value as string)
    })

    const upload = await fetch(url, {
        method: 'POST',
        body: formData,
    })

    if (!upload.ok) {
        Toast('Error uploading file', 'error')
        return null
    }

    return fileUrl
}
