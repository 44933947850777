import React from 'react'
import Image from 'next/image'
import {useRecoilState} from 'recoil';
import {categoryState} from "./AssetPicker"


const Pagination = () => {

    const [category, setCategory] = useRecoilState(categoryState)

    const changePage = (direction:string):void => {
        const page = category.page
        if(direction === 'back'){
            if(page - 1 === 0) return
            setCategory({...category, page: page - 1})
        } else if (direction === 'next'){
            if(category.assets.length < category.limit) return
            setCategory({...category, page: page+1})
        }   
    }

    return (
        <div className="mr-4 px-2  rounded-full flex items-center">
            {category.page !== 1 && (
                <button className="mr-2 hover:bg-primaryOrange rounded-full p-1" onClick={()=>changePage('back')}>
                  <svg className="transform rotate-180 "  xmlns="http://www.w3.org/2000/svg" height="1em" width="1em" viewBox="0 0 512 512"><path fill={"#FFFFFF"} d="M174.54 508.94 420.1 263.39a10.74 10.74 0 0 0 0-14.75L174.53 3.06a10.43 10.43 0 0 0-14.75 0L92 70.8a10.43 10.43 0 0 0 0 14.75L262.47 256 92 426.46a10.73 10.73 0 0 0 0 14.75l67.75 67.73a10.43 10.43 0 0 0 14.79 0Z"/></svg>
                </button>
            )}

            {category.assets.length === category.limit && (
                <button className="hover:bg-primaryOrange rounded-full p-1" onClick={()=>changePage('next')}>
                    <svg  xmlns="http://www.w3.org/2000/svg" height="1em" width="1em" viewBox="0 0 512 512"><path fill={"#FFFFFF"} d="M174.54 508.94 420.1 263.39a10.74 10.74 0 0 0 0-14.75L174.53 3.06a10.43 10.43 0 0 0-14.75 0L92 70.8a10.43 10.43 0 0 0 0 14.75L262.47 256 92 426.46a10.73 10.73 0 0 0 0 14.75l67.75 67.73a10.43 10.43 0 0 0 14.79 0Z"/></svg>
                </button>
            )}
          
           
        </div>
    )
}

export default Pagination
