import { Slider, Tooltip } from '@material-ui/core'
import React, { HTMLAttributes, useState, ReactElement, ChangeEvent } from 'react'
import styled from 'styled-components'
import { SimpleSelect } from '../../Forms/SelectBox'

export interface WidthProps extends HTMLAttributes<HTMLDivElement> {
    type?: 'px' | '%' | 'vw'
    label: string
    widgetName?: string
    handleSizeChange: (e: ChangeEvent<HTMLInputElement>) => void
    handleSliderChange?: (event: any, value: any) => void
    value: number
}

export const Width = ({
    type,
    label,
    widgetName,
    handleSizeChange,
    handleSliderChange,
    value,
}: WidthProps) => {
    const [selectedType, setSelectedType] = useState('%')

    const checkLabel = () => {
        if (widgetName) {
            return `${widgetName[0].toUpperCase() + widgetName.slice(1)} ${label}`
        } else {
            return `${label[0].toUpperCase() + label.slice(1)}`
        }
    }

    const handleChangeType = (opt: React.SetStateAction<string>): void => {
        setSelectedType(opt)
    }

    return (
        <WidthContainer>
            <ContainerLabel>{checkLabel()}</ContainerLabel>
            <ContainerBody>
                <WidthBlock>
                    <input
                        type="number"
                        name={label}
                        value={value ?? 0}
                        onChange={handleSizeChange}
                    />
                    <SimpleSelect
                        options={['px', '%', 'vw']}
                        onHandleChange={handleChangeType}
                        size="small"
                        defaultValue="%"
                    />
                </WidthBlock>
                {selectedType !== 'px' && (
                    <Slider
                        ValueLabelComponent={ValueLabelComponent}
                        aria-label="custom thumb label"
                        value={value ?? 0}
                        onChange={handleSliderChange}
                    />
                )}
            </ContainerBody>
        </WidthContainer>
    )
}

interface Props {
    children: ReactElement
    open: boolean
    value: number
}

export default function ValueLabelComponent(props: Props) {
    const { children, open, value } = props

    return (
        <Tooltip open={open} enterTouchDelay={0} placement="top" title={value}>
            {children}
        </Tooltip>
    )
}

export const WidthContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    color: #717f95;
`

export const ContainerLabel = styled.label`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin-right: 10px;
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    color: #3c4b61;
    margin-bottom: 7px;
`

export const ContainerBody = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: -webkit-fill-available;
    & > .MuiSlider-root {
        width: 75%;
        margin-left: 15px;
        & > .MuiSlider-rail {
            height: 3px;
            background: rgba(100, 120, 249, 0.2);
            border-radius: 4px;
        }
        & > .MuiSlider-track {
            height: 3px;
            background: #6478f9;
            border-radius: 4px;
        }
    }
`

export const WidthBlock = styled.div`
    display: flex;
    align-items: center;
    background: #ffffff;
    border: 1px solid #dfe2f2;
    border-radius: 8px;
    height: 32px;
    margin-right: 15px;
    width: 85px;
    input {
        width: 100%;
        border: none;
        font-weight: 600;
        padding-left: 8px;
        color: #717f95;
        font-size: 12px;
    }
    p {
        display: none;
    }
    & > div {
        display: flex;
        height: 22px;
        padding-left: 6px;
        border-left: 1px solid #dfe2f2;
        align-items: center;
    }
    & > div > div {
        height: 0;
        border: none;
        padding: 0;
        & > div {
            top: 20px;
            left: -15px;
        }
        span {
            color: #3c4b61;
            font-weight: 600;
            font-size: 14px;
        }
    }
`
