import React, { memo } from 'react'
import { Button } from '../../../stories/Button/Button'
import { ButtonGroup, StepTitle, StepWrapper } from '../style'

interface Props {
    onClose: () => void
    prevStep: () => void
    nextStep: () => void
}

const Step10 = ({ prevStep, nextStep, onClose }: Props) => {
    return (
        <StepWrapper>
            <StepTitle>
                It&#39;s actually the name of the win. Well, or you can add &#39;Bad luck&#39; if
                there are no prizes in this segment.
            </StepTitle>
            <ButtonGroup>
                <Button onClick={prevStep}>Back</Button>
                <Button width="20px" variant="invert" onClick={onClose}>
                    Close
                </Button>
                <Button variant="secondary" onClick={nextStep}>
                    Cool, what&#39;s next
                </Button>
            </ButtonGroup>
        </StepWrapper>
    )
}

export default memo(Step10)
