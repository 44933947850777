import React, { HTMLAttributes } from 'react'
import styled from 'styled-components'

interface IProps extends HTMLAttributes<any> {
    size?: number | string
    url?: string
    image?: string
}

export enum defaultSizes {
    extra_small = 'xs',
    small = 'sm',
    medium = 'md',
    large = 'lg',
    extra_large = 'xl',
    double = '2xl',
}

export const iconSizes = {
    extra_small: 32,
    small: 38,
    medium: 42,
    large: 48,
    extra_large: 52,
    double: 58,
}

const AvatarComponent = styled.div<IProps>`
    height: ${(p) => p.size}px;
    width: ${(p) => p.size}px;
    background: lightblue url(${(p) => p.url || p.image}) no-repeat center;
    border-radius: 50%;
    background-size: contain;
`

const selectImageSize = (key: string | number): number => {
    switch (key) {
        case defaultSizes.extra_small:
            return iconSizes.extra_small
        case defaultSizes.small:
            return iconSizes.small
        case defaultSizes.medium:
            return iconSizes.medium
        case defaultSizes.large:
            return iconSizes.large
        case defaultSizes.extra_large:
            return iconSizes.extra_large
        case defaultSizes.double:
            return iconSizes.double
        default:
            return +key
    }
}

const Avatar = ({ image = 'olena', size = 'md', ...props }: IProps) => {
    const currentSize = selectImageSize(size)
    return (
        <AvatarComponent
            image={`https://lh3.googleusercontent.com/a-/AOh14GicpudOj6tt4QhnJjqkfCEsPsQ1C45xbHIJTxrD=s96-c`}
            size={currentSize}
            {...props}
        />
    )
}
export default Avatar
