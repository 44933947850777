import React, {useEffect} from 'react'
import {atom,useRecoilState} from 'recoil';
import { useAssets } from '../../../../../lib/hooks/useAssets';
import RewardItem from './Rewards/RewardItem';
import Pagination from './Pagination';
import UploadAssetButton from './UploadAssetButton';
import ContentSortable from './Content/ContentSortable';


enum AssetCategory {
    REWARD = 'reward',
    CONTENT = 'content',
}

type AssetState = {
    assetCategory: AssetCategory
    subCategory: string
    page: number
    limit: number
    assets: any[]
}

export const categoryState = atom<AssetState>({
    key: '_CategoryState',
    default: {
        assetCategory: AssetCategory.CONTENT,
        subCategory: 'all',
        page: 1,
        limit: 12,
        assets: []
    }
})


const AssetPicker = () => {

    const [category, setCategory] = useRecoilState(categoryState)

    const {data: assets, isLoading, isError} = useAssets(category.assetCategory, category.subCategory, category.page , category.limit)

    useEffect(()=>{
        if(assets){
            setCategory({...category, assets})
        }
    }, [assets])
    
    return (
        <div className="flex flex-col font-primary w-full">
            <div className="w-full mt-4 pl-2 flex items-center justify-between">
                <div className="flex items-center">
                    <UploadAssetButton id="add" />
                    <button onClick={()=>setCategory({...category, assetCategory: AssetCategory.CONTENT})} className={`text-white ${category.assetCategory === 'content' ? 'bg-primaryOrange' : 'bg-primaryNavyBlue'}  px-2 rounded-full py-1 mr-2 text-sm hover:bg-primaryOrange hover:bg-primaryOrange focus:outline-none`}>Content</button>
                    <button onClick={()=>setCategory({...category, assetCategory: AssetCategory.REWARD})} className={`text-white ${category.assetCategory === 'reward' ? 'bg-primaryOrange' : 'bg-primaryNavyBlue'}  px-2 rounded-full py-1 mr-2 text-sm hover:bg-primaryOrange hover:bg-primaryOrange focus:outline-none`}>Reward</button>
                </div>
                <Pagination />
              
            </div>

            {category.assetCategory === 'reward' && (
                 <div className="w-full h-full p-4 grid  grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-4 overflow-y-auto">
                    {category.assetCategory === "reward" && category.assets && category.assets.map((asset, index)=>{
                        return <React.Fragment key={`${asset._id}-${index}`}><RewardItem index={index} asset={asset} /></React.Fragment>
                    })}
                </div>
            )}  
           

            
            {category.assetCategory === 'content' && category.assets && <ContentSortable assets={category.assets} />}

        </div>
    )
}

export default AssetPicker
