import React, { useState } from 'react'
import Image from 'next/image'
import { useRecoilState, useRecoilCallback } from 'recoil'
import { DigitalType, IReward } from '../../../../../../models/Assets/Reward'
import CustomSelect from '../../../../../Inputs/Select'
import { MenuItem, Tooltip } from '@material-ui/core'
import FilePicker from '../FilePicker'
import InputField from '../../../../../Inputs/DefaultInput'
import validator from 'validator'
import { rewardDataState } from '../../../../../../stores/assets'
import { DropMenu } from '../../../../../../stories/Forms/DropMenu'
import { FileUploadZone } from '../../../../../../stories/File Upload/FileUploadZone'
import { InputBox } from '../../../../../../stories/Forms/InputBox'
import File from '../../../../../Inputs/File'

const Digital = () => {
    const [rewardData, setRewardData] = useRecoilState<IReward>(rewardDataState)
    const [error, setError] = useState<string | null>(null)

    const updateDigitalState = useRecoilCallback(
        ({ snapshot }) =>
            async (key: string, value: any) => {
                setError(null)
                const state = await snapshot.getPromise(rewardDataState)

                if (!value) {
                    setRewardData({
                        ...state,
                        digitalSettings: {
                            ...state.digitalSettings,
                            [key]: null,
                        },
                    })
                    return
                }

                setRewardData({
                    ...state,
                    digitalSettings: {
                        ...state.digitalSettings,
                        [key]: value,
                    },
                })
            }
    )

    const handleQuantityBlur = (value: string, attr: string): void => {
        setError(null)
        if (!validator.isURL(value)) {
            setError('Please set valid link.')
        }
    }

    return (
        <div>
            <h2 className="mt-4">Digital Settings</h2>
            <h3>Digital Type</h3>
            <DropMenu
                width="100px"
                value={rewardData.digitalSettings.type}
                items={[DigitalType.FILE, DigitalType.URL]}
                onSelect={(e) => updateDigitalState('type', e)}
            ></DropMenu>

            <div className="flex flex-row gap-2">
                {rewardData.digitalSettings.type === 'file' &&
                    rewardData.digitalSettings.fileName !== '' &&
                    rewardData.digitalSettings.fileUrl !== '' && (
                        <div className="mt-4 mb-4">
                            {!rewardData.digitalSettings.fileName.includes('mp4') ? (
                                <File
                                    name={rewardData.digitalSettings.fileName}
                                    imageSrc={rewardData.digitalSettings.fileUrl}
                                    onClick={() => updateDigitalState('file', null)}
                                    editable={true}
                                    width="400px"
                                    height="200px"
                                ></File>
                            ) : (
                                <File
                                    name={rewardData.digitalSettings.fileName}
                                    videoSrc={rewardData.digitalSettings.fileUrl + '#t=0.1'}
                                    onClick={() => updateDigitalState('file', null)}
                                    editable={true}
                                    width="400px"
                                    height="200px"
                                ></File>
                            )}
                        </div>
                    )}

                {rewardData.digitalSettings.type === DigitalType.FILE &&
                    rewardData.digitalSettings.file === null &&
                    rewardData.digitalSettings.fileName === '' &&
                    rewardData.digitalSettings.fileUrl === '' && (
                        <div className="mt-4 mb-4">
                            <FileUploadZone
                                width="400px"
                                height="200px"
                                imagesOnly={false}
                                uploadAnyFiles={true}
                                setFile={(file: any) => updateDigitalState('file', file)}
                            />
                        </div>
                    )}

                {rewardData.digitalSettings.type === 'file' && rewardData.digitalSettings.file && (
                    <div className="my-2 text-sm flex flex-col">
                        <p
                            className="hover:line-through hover:cursor-not-allowed"
                            onClick={() => updateDigitalState('file', null)}
                        >
                            <b>Selected file: </b>
                            {rewardData.digitalSettings.file.name}
                        </p>
                    </div>
                )}

                {rewardData.digitalSettings.type === 'url' && (
                    <div className="flex flex-col mt-2">
                        <h3>Asset Link</h3>
                        <InputBox
                            name="asset quantity"
                            onChange={(e) => updateDigitalState('url', e.target.value)}
                            placeholder="https://deploy.link"
                            type="text"
                            value={
                                rewardData.digitalSettings.url ? rewardData.digitalSettings.url : ''
                            }
                            onBlur={() => handleQuantityBlur(rewardData.digitalSettings.url, 'url')}
                        />
                    </div>
                )}
            </div>

            {error && <h4>{error}</h4>}
        </div>
    )
}

export default Digital
