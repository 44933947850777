import React, { memo } from 'react'
import { Button } from '../../../stories/Button/Button'
import { ButtonGroup, StepTitle, StepWrapper } from '../style'

interface Props {
    prevStep: () => void
    lastStep: () => void
}

const Step14 = ({ prevStep, lastStep }: Props) => {
    return (
        <StepWrapper>
            <video muted loop autoPlay style={{ borderRadius: '15px' }}>
                <source src="/video/tooltipbutton.mp4" type="video/mp4" />
            </video>
            <StepTitle>
                Feel free to experiment and create cool designs. And if you need help, you can
                contact me again by clicking on the tips button or write to our deployable team. We
                will gladly help you. Enjoy and good luck!
            </StepTitle>
            <ButtonGroup>
                <Button onClick={prevStep}>Back</Button>
                <Button variant="secondary" onClick={lastStep}>
                    OK
                </Button>
            </ButtonGroup>
        </StepWrapper>
    )
}

export default memo(Step14)
