import { nanoid } from "nanoid"
import { AssetCategory } from "../../types/Assets/Asset"
import { Asset } from "./Asset"

export enum DigitalType {
    FILE = 'file',
    URL = 'url'
}

export interface IContent extends Asset {
    fileName: string
    fileUrl: string
    type: string
    file?: File | null
}

export enum IContentType {
    IMAGE = 'image',
    VIDEO = 'video',
    AUDIO = 'audio',
    MODEL = 'model'
}


class Content implements IContent {
    id = nanoid()
    createdAt = new Date().toISOString()
    category = AssetCategory.CONTENT
    file = null
    fileName = ""
    fileUrl = ""
    type = ""
}

export default Content