import React from 'react'
import { useRecoilState } from 'recoil'
import validator from 'validator'
import { VoucherGenerator } from '../../../../../../types/Assets/Rewards/Voucher'
import { IReward } from '../../../../../../models/Assets/Reward'
import { Toast } from '../../../../../../helpers/Toast'
import { rewardDataState } from '../../../../../../stores/assets'
import { DropMenu } from '../../../../../../stories/Forms/DropMenu'
import { InputBox } from '../../../../../../stories/Forms/InputBox'

const updateState = (value, attr, assetData, setAssetData) => {
    setAssetData({
        ...assetData,
        voucherSettings: {
            ...assetData.voucherSettings,
            [attr]: value,
        },
    })
}

const Voucher = () => {
    const [rewardData, setRewardData] = useRecoilState<IReward>(rewardDataState)

    const handleVoucherSettings = (value, attr: string): void => {
        updateState(value, attr, rewardData, setRewardData)
    }

    const handleNumberOnBlur = (value: number, attr: string): void => {
        if (value > 99999999) {
            updateState(99999999, attr, rewardData, setRewardData)
        } else if (value <= 0) {
            updateState(1, attr, rewardData, setRewardData)
        }
    }

    const handlePrefixOnBlur = (value: string, attr: string): void => {
        if (value === '') {
            return
        }

        if (!validator.isAlphanumeric(value)) {
            Toast('Prefix must only contain letters and numbers', 'error')
            updateState('prefix', attr, rewardData, setRewardData)
        }

        if (value.length > 10) {
            const slicedValue = value.slice(0, 10)
            updateState(slicedValue, attr, rewardData, setRewardData)
        }
    }

    return (
        <div>
            <h2 className="mt-4">Voucher Settings</h2>

            <DropMenu
                width="400px"
                value={rewardData.voucherSettings.voucherGenerator}
                items={[
                    VoucherGenerator.SEQUENTIAL,
                    VoucherGenerator.RANDOM,
                    VoucherGenerator.FIXED,
                ]}
                onSelect={(e) => handleVoucherSettings(e, 'voucherGenerator')}
            ></DropMenu>
            {rewardData.voucherSettings.voucherGenerator === VoucherGenerator.SEQUENTIAL && (
                <div className="mt-4 flex flex-row gap-2">
                    <div>
                        <h3>Starting Number</h3>
                        <InputBox
                            width="100px"
                            placeholder="starting number"
                            onChange={(e) =>
                                handleVoucherSettings(e.target.value, 'startingNumber')
                            }
                            type="number"
                            min={1}
                            max={99999999}
                            value={rewardData.voucherSettings.startingNumber}
                            onBlur={() =>
                                handleNumberOnBlur(
                                    rewardData.voucherSettings.startingNumber,
                                    'startingNumber'
                                )
                            }
                        ></InputBox>
                    </div>
                    <div>
                        <h3>Voucher Prefix</h3>
                        <InputBox
                            placeholder="prefix (letters & numbers)"
                            onChange={(e) => handleVoucherSettings(e.target.value, 'prefix')}
                            width="284px"
                            type="text"
                            value={rewardData.voucherSettings.prefix}
                            onBlur={() =>
                                handlePrefixOnBlur(rewardData.voucherSettings.prefix, 'prefix')
                            }
                        ></InputBox>
                    </div>
                </div>
            )}
            {rewardData.voucherSettings.voucherGenerator === VoucherGenerator.RANDOM && (
                <div>
                    <div className="mt-4 flex flex-row gap-2">
                        <div>
                            <h3>Length (1-10)</h3>
                            <InputBox
                                width="100px"
                                name="random length"
                                placeholder=""
                                onChange={(e) => handleVoucherSettings(e, 'length')}
                                type="number"
                                min={1}
                                max={10}
                                value={rewardData.voucherSettings.length}
                                onBlur={() =>
                                    handleNumberOnBlur(rewardData.voucherSettings.length, 'length')
                                }
                            />
                        </div>

                        <div>
                            <h3>Voucher Prefix</h3>
                            <InputBox
                                placeholder="prefix (letters & numbers)"
                                onChange={(e) => handleVoucherSettings(e.target.value, 'prefix')}
                                width="284px"
                                type="text"
                                value={rewardData.voucherSettings.prefix}
                                onBlur={() =>
                                    handlePrefixOnBlur(rewardData.voucherSettings.prefix, 'prefix')
                                }
                            ></InputBox>
                        </div>
                    </div>
                </div>
            )}
            {rewardData.voucherSettings.voucherGenerator === VoucherGenerator.FIXED && (
                <div className="flex items-center mt-3">
                    <div>
                        <h3>Default Voucher Code</h3>
                        <InputBox
                            width="400px"
                            name="default voucher code"
                            placeholder=""
                            onChange={(e) =>
                                handleVoucherSettings(e.target.value, 'defaultVoucherCode')
                            }
                            type="type"
                            value={
                                rewardData.voucherSettings.defaultVoucherCode
                                    ? rewardData.voucherSettings.defaultVoucherCode
                                    : ''
                            }
                            onBlur={() => {}}
                        />
                    </div>
                </div>
            )}
        </div>
    )
}

export default Voucher
