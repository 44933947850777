import React from 'react'

export const SceletonProject = () => {
    return (
        <svg
            width="186"
            height="309"
            viewBox="0 0 186 309"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M184.577 86.5387V86.8899L184.907 87.009C185.248 87.132 185.5 87.468 185.5 87.8707V126.948C185.5 127.351 185.248 127.687 184.907 127.81L184.577 127.929V128.28V366.591C184.577 381.406 172.806 393.398 158.308 393.398H27.6923C13.1936 393.398 1.42308 381.406 1.42308 366.591V134.401V134.049L1.09266 133.93C0.751722 133.807 0.5 133.471 0.5 133.069V110.47C0.5 110.067 0.751725 109.731 1.09267 109.608L1.42308 109.489V109.138V100.502V100.151L1.09267 100.032C0.751725 99.909 0.5 99.5729 0.5 99.1702V76.5713C0.5 76.1685 0.751727 75.8325 1.09266 75.7095L1.42308 75.5904V75.2392V63.779V63.4277L1.09267 63.3086C0.751726 63.1857 0.5 62.8496 0.5 62.4469V50.6766C0.5 50.2739 0.751725 49.9379 1.09267 49.8149L1.42308 49.6958V49.3446V27.6069C1.42308 12.7923 13.1936 0.799805 27.6923 0.799805H158.308C172.806 0.799805 184.577 12.7923 184.577 27.6069V86.5387ZM144.423 5.44954H144.336L144.254 5.47894C142.466 6.12067 141.192 7.85411 141.192 9.8842C141.192 15.3751 136.831 19.8093 131.473 19.8093H54.0659C48.7072 19.8093 44.3462 15.3751 44.3462 9.8842C44.3462 7.85411 43.0723 6.12067 41.2846 5.47894L41.2027 5.44954H41.1156H27.6923C15.6814 5.44954 5.96154 15.3792 5.96154 27.6069V366.591C5.96154 378.819 15.6814 388.748 27.6923 388.748H158.308C170.319 388.748 180.038 378.819 180.038 366.591V27.6069C180.038 15.3792 170.319 5.44954 158.308 5.44954H144.423Z"
                fill="white"
                stroke="#C7CBE1"
            />
            <mask
                id="mask0_2421_11328"
                maskUnits="userSpaceOnUse"
                x="6"
                y="5"
                width="174"
                height="384"
            >
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M27.7047 5.94922C15.9793 5.94922 6.47394 15.6456 6.47394 27.6065V366.591C6.47394 378.552 15.9793 388.248 27.7047 388.248H158.32C170.046 388.248 179.551 378.552 179.551 366.591V27.6065C179.551 15.6456 170.046 5.94922 158.32 5.94922H144.435C142.845 6.52015 141.705 8.06626 141.705 9.88387C141.705 15.6415 137.129 20.309 131.485 20.309H54.0783C48.4341 20.309 43.8586 15.6415 43.8586 9.88387C43.8586 8.06626 42.7185 6.52015 41.128 5.94922H27.7047Z"
                    fill="#6478F9"
                />
            </mask>
            <g mask="url(#mask0_2421_11328)">
                <rect x="6" y="5" width="174" height="377" fill="#6478F9" />
                <rect
                    opacity="0.55"
                    x="25.4534"
                    y="127.241"
                    width="135.093"
                    height="31.3716"
                    rx="8"
                    fill="white"
                />
                <rect
                    opacity="0.55"
                    x="25.4534"
                    y="169.431"
                    width="135.093"
                    height="31.3716"
                    rx="8"
                    fill="white"
                />
                <rect
                    opacity="0.55"
                    x="25.4534"
                    y="211.62"
                    width="135.093"
                    height="31.3716"
                    rx="8"
                    fill="white"
                />
                <ellipse cx="93" cy="69.9069" rx="28.6398" ry="28.6671" fill="#3AE29B" />
            </g>
        </svg>
    )
}
