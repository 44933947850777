import React, { ChangeEvent, useEffect, useState } from 'react'
import { BorderPropsType } from '../../../components/Widgets/DesignSettings/Border'
import { Icon } from '../../index'
import { ContainerLabel } from '../Sizer/Sizer'
import { BorderRadiusCustom } from './BorderCustom'
import {
    BorderContainer,
    BorderIcon,
    BorderSettingWrapper,
    ContainerBody,
    MiddleMultiSelectContainer,
    MultiSelectContainer,
    RadiusBody,
    TopBottomMultiSelectContainer,
} from './BorderWidth'

export const BorderRadius = ({
    label,
    widget,
    isCustomBorder,
    setIsCustomBorder,
    handleChange,
    handleChangeBorderRadius,
}: BorderPropsType) => {
    const checkCustomBorder = () => {
        setIsCustomBorder((prev) => !prev)
    }

    const borderTopLeftRadius = widget?.appearanceSettings
        ? widget?.appearanceSettings?.borderTopLeftRadius
        : 0
    const borderTopRightRadius = widget?.appearanceSettings
        ? widget?.appearanceSettings?.borderTopRightRadius
        : 0
    const borderBottomLeftRadius = widget?.appearanceSettings
        ? widget?.appearanceSettings?.borderBottomLeftRadius
        : 0
    const borderBottomRightRadius = widget?.appearanceSettings
        ? widget?.appearanceSettings?.borderBottomRightRadius
        : 0
    const borderRadius = widget?.appearanceSettings ? widget?.appearanceSettings?.borderRadius : 0

    useEffect(() => {
        if (
            borderTopLeftRadius === borderTopRightRadius &&
            borderTopRightRadius === borderBottomLeftRadius &&
            borderBottomLeftRadius === borderBottomRightRadius &&
            borderBottomRightRadius === borderTopLeftRadius
        ) {
            setIsCustomBorder(false)
        } else {
            setIsCustomBorder(true)
        }
    }, [borderBottomLeftRadius, borderBottomRightRadius, borderTopLeftRadius, borderTopRightRadius])

    return (
        <BorderSettingWrapper>
            <BorderContainer>
                <ContainerLabel>{label}</ContainerLabel>
                <ContainerBody>
                    <RadiusBody>
                        <img width="8px" height="8px" src="/images/v2/border-icon.png" />
                        <input
                            type="number"
                            disabled={isCustomBorder}
                            value={
                                borderTopLeftRadius ||
                                borderTopRightRadius ||
                                borderBottomLeftRadius ||
                                borderBottomRightRadius ||
                                borderRadius
                            }
                            onChange={handleChangeBorderRadius}
                        />
                    </RadiusBody>
                    <BorderIcon isCustomBorder={isCustomBorder} onClick={checkCustomBorder}>
                        <div style={{ padding: 5 }}>
                            <Icon
                                name="border-radius"
                                size={'sm'}
                                color={isCustomBorder ? '#6478F9' : ''}
                            />
                        </div>
                    </BorderIcon>
                </ContainerBody>
            </BorderContainer>
            {isCustomBorder && (
                <MultiSelectContainer
                    initial={{ opacity: 0, y: 50, scale: 0.3 }}
                    animate={{ opacity: 1, y: 0, scale: 1 }}
                    exit={{ opacity: 0, scale: 0.5, transition: { duration: 0.2 } }}
                >
                    <TopBottomMultiSelectContainer>
                        <BorderRadiusCustom
                            direction="Top"
                            value={borderTopLeftRadius}
                            onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                handleChange(e, 'borderTopLeftRadius')
                            }
                        />
                    </TopBottomMultiSelectContainer>
                    <MiddleMultiSelectContainer>
                        <BorderRadiusCustom
                            direction="Left"
                            value={borderBottomLeftRadius}
                            onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                handleChange(e, 'borderBottomLeftRadius')
                            }
                        />
                        <BorderRadiusCustom
                            direction="Right"
                            value={borderTopRightRadius}
                            onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                handleChange(e, 'borderTopRightRadius')
                            }
                        />
                    </MiddleMultiSelectContainer>
                    <TopBottomMultiSelectContainer>
                        <BorderRadiusCustom
                            direction="Bottom"
                            value={borderBottomRightRadius}
                            onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                handleChange(e, 'borderBottomRightRadius')
                            }
                        />
                    </TopBottomMultiSelectContainer>
                </MultiSelectContainer>
            )}
        </BorderSettingWrapper>
    )
}

export default BorderRadius
