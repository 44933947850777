import React, { useState } from 'react'
import ContentToggle from './ContentToggle'
import { Tooltip } from '@material-ui/core'
import { useRecoilValue } from 'recoil'
import { useDeleteAssetMutation } from '../../../../../../lib/hooks/useAssets'
import { categoryState } from '../AssetPicker'
import { IWidget } from '../../../../../../models/Widget'

interface Props {
    children: React.ReactElement
    widget: IWidget
}

const ContentPreviewContainer = ({ children, widget }: Props) => {
    const [isToggle, setToggle] = useState<boolean>(false)
    const category = useRecoilValue(categoryState)

    const deleteMutate = useDeleteAssetMutation(
        widget,
        category.assetCategory,
        category.subCategory,
        category.page
    )

    const deleteContent = async () => {
        await deleteMutate.mutate()
        setToggle(false)
    }

    const download = (): void => {
        let a = document.createElement('a')
        a.href = widget.settings.src
        a.download = widget.name
        a.target = '_blank'
        document.body.appendChild(a)
        a.click()
        document.body.removeChild(a)
    }

    return (
        <div className={`edit flex flex-col justify-between content-between items-center `}>
            <style jsx>
                {`
                    .edit:hover .edit-hover {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        font-size: 20px;
                    }
                `}
            </style>

            <div className="h-full flex justify-center items-center w-full relative ">
                <>
                    <div
                        onClick={() => setToggle(!isToggle)}
                        className="edit-hover hidden absolute top-0 rounded bg-gray-900 text-white opacity-50 w-full h-full cursor-pointer right-0 text-center"
                    >
                        ...
                    </div>
                    {isToggle && (
                        <ContentToggle
                            name={widget.name}
                            deleteContent={deleteContent}
                            download={download}
                            setToggle={setToggle}
                        />
                    )}
                </>
                <Tooltip title={widget.name}>{children}</Tooltip>
            </div>
        </div>
    )
}

export default ContentPreviewContainer
