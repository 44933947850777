import React from 'react'
import { useRecoilState, useSetRecoilState } from 'recoil'
import UploadAssetModal from './UploadAssetModal'
import { assetModalState, contentDataState } from '../../../../../stores/assets'
import Content from '../../../../../models/Assets/Content'
import { Button } from '../../../../../stories/Button/Button'
import styled from 'styled-components'

interface Props {
    id: string
    variant?: 'primary' | 'secondary' | 'orange' | 'invert'
    size?: 'x-small' | 'small' | 'medium' | 'large'
    label?: string
}

const ButtonContents = styled.span`
    p {
        margin-left: 5px;
    }
    display: flex;
    align-items: center;
`

const UploadAssetButton = ({
    id,
    variant = 'primary',
    size = 'medium',
    label = 'Upload',
}: Props) => {
    const [isModalVisible, setModalVisible] = useRecoilState(assetModalState(id))
    const setContentData = useSetRecoilState(contentDataState)

    const toggleUploadModal = (): void => {
        if (!isModalVisible === true) {
            setContentData(new Content())
        }

        setModalVisible(!isModalVisible)
    }

    return (
        <div>
            <Button
                size={size}
                variant={variant}
                onClick={toggleUploadModal}
                style={{ padding: 10 }}
                width="100%"
            >
                <ButtonContents>
                    <svg
                        width="22"
                        height="22"
                        viewBox="0 0 22 22"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M20.1591 11.8146C20.0395 13.5492 18.6836 15.4434 16.0319 15.4434C14.8156 15.4434 14.1776 15.4434 14.1776 15.4434C13.739 15.4434 13.3801 15.1044 13.3801 14.6658C13.3801 14.2271 13.739 13.9081 14.1776 13.9081C14.1776 13.9081 14.8156 13.8682 16.0319 13.8682C17.6867 13.8682 18.5042 12.7517 18.584 11.695C18.6637 10.5984 17.9659 9.38213 16.2711 9.14287C16.0518 9.10299 15.8524 8.98336 15.7328 8.80392C15.6132 8.62448 15.5733 8.38522 15.6132 8.16589C15.8325 7.2288 15.5932 6.49108 14.9751 6.15213C14.4567 5.85305 13.6193 5.87299 12.9414 6.53096C12.762 6.7104 12.5028 6.79015 12.2635 6.75028C12.0243 6.7104 11.8049 6.55089 11.6853 6.33157C10.5289 4.1583 8.75438 4.05861 7.57803 4.61688C6.44154 5.15521 5.54432 6.49108 6.24216 8.18583C6.32191 8.38522 6.32191 8.62448 6.22222 8.82386C6.12253 9.02324 5.94309 9.18275 5.7437 9.24256C3.31123 9.98028 3.37105 11.5753 3.43086 12.0539C3.57043 12.9311 4.26827 13.8284 5.34494 13.8284L7.83722 13.8483C8.27587 13.8483 8.63476 14.2271 8.63476 14.6658C8.63476 15.1044 8.27587 15.5032 7.83722 15.5032L5.36488 15.4434C3.61031 15.4434 2.15481 14.0876 1.87568 12.2732C1.65636 10.8775 2.2545 9.0033 4.48759 8.00639C3.9692 5.81318 5.20537 3.95892 6.88019 3.18133C8.59488 2.36386 11.0074 2.60311 12.6025 4.81626C13.5994 4.25799 14.7757 4.21812 15.7527 4.77639C16.5901 5.25491 17.3478 6.25182 17.268 7.74719C19.3815 8.38522 20.2588 10.2195 20.1591 11.8146ZM13.7988 12.4726C13.9583 12.6321 14.1577 12.7118 14.357 12.7118C14.5564 12.7118 14.7558 12.6321 14.9153 12.4726C15.2343 12.1536 15.2343 11.6551 14.9153 11.3361L11.5457 7.94657C11.4062 7.80701 11.2068 7.72725 10.9875 7.72725C10.7682 7.72725 10.5688 7.80701 10.4292 7.96651L7.05963 11.356C6.74062 11.675 6.74062 12.1735 7.05963 12.4925C7.37864 12.8115 7.8771 12.8115 8.19611 12.4925L10.1899 10.4389L10.2099 17.8958C10.2099 18.3344 10.5688 18.6933 11.0074 18.6933C11.4461 18.6933 11.8049 18.3344 11.8049 17.8958L11.785 10.4588L13.7988 12.4726Z"
                            fill={variant === 'invert' ? 'black' : 'white'}
                        />
                    </svg>

                    <p style={{ color: '#fff' }}>{label}</p>
                </ButtonContents>
            </Button>
            <UploadAssetModal id="add" visible={isModalVisible} toggleVisible={toggleUploadModal} />
        </div>
    )
}

export default UploadAssetButton
