import React, { ReactNode } from 'react'
import { CustomModal } from '../../components/Common/Modal'
import styled from 'styled-components'
import { RoundIconButton } from '../../components/Builder/RoundIconButton'
import { PlaceholderQR } from '../assets/PlaceholderQR'

interface Props {
    id: string
    visible: boolean
    QR: string
    link: string
    toggleVisible: any
}

const Body = styled.section`
    width: 500px;
    height: 500px;
    background-color: white;
    border: 1px solid #e3e7ff;
    box-sizing: border-box;
    border-radius: 16px;
    outline: none;
    padding: 28px;

    font-family: Matter;
    font-style: normal;
    font-weight: normal;

    h1 {
        text-align: center;
        font-size: 29px;
        font-weight: 600;
        line-height: 35px;
        color: #3ae29b;
    }

    h2 {
        text-align: center;
        font-size: 18px;
        font-weight: 600;
        line-height: 35px;
    }

    p {
        font-size: 14px;
        color: #3c4b61;
    }
`

const LinkBox = styled.div`
    margin-top: 30px;
    width: 450px;
    height: 56px;
    background: #fafaff;
    border: 1px solid #dfe2f2;
    box-sizing: border-box;
    border-radius: 200px;

    display: flex;
    flex-direction: row;

    p {
        position: relative;
        top: 15px;
        left: 20px;
        font-weight: 400;
        font-size: 16px;
        line-height: 26px;
        color: #717f95;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        max-width: 350px;
        margin-right: 50px;
    }

    .button {
        position: relative;
        top: 9px;
        right: 0%;

        background: #6478f9;
        width: 36px;
        height: 36px;
    }
`

const TopBar = styled.div`
    width: 100%;
`
const Wrapper = styled.div`
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`

const PreviewModal = ({ id, link, QR, visible, toggleVisible }: Props) => {
    function redirect() {
        window.open(link)
    }

    return (
        <CustomModal visible={visible} toggleVisible={toggleVisible} title="Upload Asset">
            <Body>
                <TopBar>
                    <svg
                        onClick={() => toggleVisible()}
                        className="cursor-pointer ml-auto"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M18 5.91016L6 18.0902"
                            stroke="#3C4B61"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                        <path
                            d="M6 5.91016L18 18.0902"
                            stroke="#3C4B61"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                    </svg>
                </TopBar>
                <Wrapper>
                    <h1>Preview</h1>
                    <h2>{id}</h2>
                    {/* eslint-disable-next-line @next/next/no-img-element */}
                    {QR !== '' && <img src={QR} alt="QR" height={200} width={200} />}
                    {QR === '' && (
                        <>
                            <PlaceholderQR />
                            <p style={{ marginTop: '16px', fontSize: '14px', color: '#717F95' }}>
                                This page has not been published yet
                            </p>
                        </>
                    )}
                    <LinkBox>
                        <p>{link}</p>
                        <RoundIconButton className="button" onClick={() => redirect()}>
                            <svg
                                width="17"
                                height="17"
                                viewBox="0 0 17 17"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M12.2779 12.9074H4.72238C4.37609 12.9074 4.09276 12.6241 4.09276 12.2778V4.72222C4.09276 4.37593 4.37609 4.09259 4.72238 4.09259H7.87053C8.21683 4.09259 8.50016 3.80926 8.50016 3.46297C8.50016 3.11667 8.21683 2.83334 7.87053 2.83334H4.09276C3.39387 2.83334 2.8335 3.4 2.8335 4.09259V12.9074C2.8335 13.6 3.40016 14.1667 4.09276 14.1667H12.9076C13.6002 14.1667 14.1668 13.6 14.1668 12.9074V9.12963C14.1668 8.78334 13.8835 8.5 13.5372 8.5C13.1909 8.5 12.9076 8.78334 12.9076 9.12963V12.2778C12.9076 12.6241 12.6242 12.9074 12.2779 12.9074ZM9.75942 3.46297C9.75942 3.80926 10.0428 4.09259 10.3891 4.09259H12.0198L6.27127 9.84111C6.02572 10.0867 6.02572 10.4833 6.27127 10.7289C6.51683 10.9744 6.9135 10.9744 7.15905 10.7289L12.9076 4.98037V6.61111C12.9076 6.95741 13.1909 7.24074 13.5372 7.24074C13.8835 7.24074 14.1668 6.95741 14.1668 6.61111V3.46297C14.1668 3.11667 13.8835 2.83334 13.5372 2.83334H10.3891C10.0428 2.83334 9.75942 3.11667 9.75942 3.46297Z"
                                    fill="#FAFAFF"
                                />
                            </svg>
                        </RoundIconButton>
                    </LinkBox>
                </Wrapper>
            </Body>
        </CustomModal>
    )
}

export default PreviewModal
