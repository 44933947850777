import React, { HTMLAttributes, useState, useEffect, ChangeEvent } from 'react'
import styled from 'styled-components'
import { ContainerBody, InputValue, Unit, Wrapper } from '../Sizer/Sizer'

export interface Props extends HTMLAttributes<HTMLDivElement> {
    direction: 'Top' | 'Bottom' | 'Left' | 'Right'
    value: number
    onChange?: (e: ChangeEvent<HTMLInputElement>) => void
}

export const BorderRadiusCustom = ({ direction, value, onChange }: Props) => {
    if (direction === 'Top' || direction === 'Bottom') {
        return (
            <BorderCustomContainer direction={direction}>
                {direction === 'Bottom' && <ContainerLabel>Bottom</ContainerLabel>}
                <ContainerBody>
                    <Wrapper>
                        <InputValue
                            type="number"
                            value={value}
                            onChange={onChange}
                            min={0}
                            max={30}
                        />
                        <Unit>px</Unit>
                    </Wrapper>
                </ContainerBody>
                {direction === 'Top' && <ContainerLabel>Top</ContainerLabel>}
            </BorderCustomContainer>
        )
    }

    if (direction === 'Right' || direction === 'Left') {
        return (
            <BorderCustomContainer direction={direction}>
                {direction === 'Right' && <ContainerLabel>Right</ContainerLabel>}
                <ContainerBody>
                    <Wrapper>
                        <InputValue
                            type="number"
                            value={value}
                            onChange={onChange}
                            min={0}
                            max={30}
                        />
                        <Unit>px</Unit>
                    </Wrapper>
                </ContainerBody>
                {direction === 'Left' && <ContainerLabel>Left</ContainerLabel>}
            </BorderCustomContainer>
        )
    }
}

const BorderCustomContainer = styled.div.attrs((props) => ({}))<{ direction: string }>`
    display: flex;
    flex-direction: ${(props) =>
        props.direction === 'Left' || props.direction === 'Right' ? 'row' : 'column'};
    justify-content: flex-start;
    align-items: center;
    color: #717f95;
    & > div > div {
        margin-right: 0;
    }
`

const ContainerLabel = styled.label`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin: 2px;
    font-size: 12px;
`
