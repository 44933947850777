import React, { useMemo, useState, useCallback, Fragment, useEffect } from 'react'
import { useRecoilValue } from 'recoil'
import styled from 'styled-components'

import { Description } from '../../components/BrandSettings/styles'
import { useBranding } from '../../lib/hooks/useBranding'
import { useLayers } from '../../lib/hooks/useLayers'
import { useUpdateWidgetSettings } from '../../lib/widgets/useUpdateWidgetSettings'
import { ILayer } from '../../models/Layer'
import { activeWidgetSelector } from '../../stores/builder/activeWidget'
import { ColorsContainer } from '../Elements/ColorsContainer'

interface Props {
    label: string
    colors: string[]
    name: string
    layer?: ILayer
    isBoxShadow?: boolean
}

const EditColor = ({ label, colors, name, layer, isBoxShadow }: Props) => {
    const { data: branding } = useBranding()
    const widget = useRecoilValue(activeWidgetSelector)
    const updateSettings = useUpdateWidgetSettings()
    const useLayerHook = useLayers()
    const [isOpenColorPicker, setIsOpenColorPicker] = useState<boolean>(false)

    const toggleColorPicker = () => {
        setIsOpenColorPicker((prev) => !prev)
    }

    const handleColorChange = useCallback(
        (color: any, index: number, isImmutable?: boolean) => {
            let recentColors = localStorage.getItem(name + '_recent_colors')
            let _colors = []
            if (recentColors) {
                // there is a browser instance of the recent colors
                _colors = JSON.parse(recentColors)
                if (Array.isArray(_colors)) {
                    if (isImmutable && color) {
                        _colors[index] = color
                            ? `rgba(${color.r},${color.g},${color.b},${color.a})`
                            : color
                    } else {
                        if (color) {
                            _colors[2] = _colors[1]
                            _colors[1] = _colors[0]
                            _colors[0] = color
                                ? `rgba(${color.r},${color.g},${color.b},${color.a})`
                                : color
                        }
                    }
                }
            } else {
                // first time storing recent colors
                _colors = [
                    color ? color : 'rgba(255,255,255,1)',
                    branding?.backgroundColour ? branding.backgroundColour : 'rgba(255,255,255,1)',
                    branding?.buttonColour ? branding.buttonColour : 'rgba(255,255,255,1)',
                ]
            }
            // update the colors in local storage
            localStorage.setItem(name + '_recent_colors', JSON.stringify(_colors))

            let newColors = []

            if (layer) {
                if (typeof layer.colourSettings[name] === 'string' || !layer.colourSettings[name]) {
                    newColors = [color, 'rgba(255,255,255,1)', 'rgba(255,255,255,1)']
                } else {
                    if (index === 0) {
                        newColors = [
                            `rgba(${color.r},${color.g},${color.b},${color.a})`,
                            layer.colourSettings[name][0],
                            layer.colourSettings[name][1],
                        ]
                    } else {
                        newColors = [
                            color,
                            layer.colourSettings[name][0],
                            layer.colourSettings[name][1],
                        ]
                    }
                }
                const settings = {
                    colourSettings: {
                        ...layer.colourSettings,
                        [name]: newColors,
                    },
                }
                useLayerHook.update(settings)
            } else {
                if (isBoxShadow) {
                    if (index === 0) {
                        newColors = [
                            `rgba(${color.r},${color.g},${color.b},${color.a})`,
                            widget?.appearanceSettings?.boxShadow[name][0],
                            widget?.appearanceSettings?.boxShadow[name][1],
                        ]
                    } else {
                        newColors = [
                            color,
                            widget?.appearanceSettings?.boxShadow[name][0],
                            widget?.appearanceSettings?.boxShadow[name][1],
                        ]
                    }
                    const settings = {
                        appearanceSettings: {
                            ...widget.appearanceSettings,
                            boxShadow: {
                                ...widget?.appearanceSettings?.boxShadow,
                                [name]: newColors,
                            },
                        },
                    }
                    updateSettings.update(settings)
                } else {
                    if (
                        typeof widget.colourSettings[name] === 'string' ||
                        !widget.colourSettings[name]
                    ) {
                        newColors = [color, 'rgba(255,255,255,1)', 'rgba(255,255,255,1)']
                    } else {
                        if (index === 0) {
                            const currentColor = widget.colourSettings[name].filter(
                                (_color: any, colorIndex: number) => colorIndex === index
                            )
                            newColors = [
                                `rgba(${color.r},${color.g},${color.b},${color.a} )`,
                                currentColor[0],
                                widget.colourSettings[name][1],
                            ]
                        } else {
                            newColors = [
                                color,
                                widget.colourSettings[name][0],
                                widget.colourSettings[name][1],
                            ]
                        }
                    }
                    const settings = {
                        colourSettings: {
                            ...widget.colourSettings,
                            [name]: newColors,
                        },
                    }
                    updateSettings.update(settings)
                }
            }
        },
        [
            isBoxShadow,
            layer,
            name,
            updateSettings,
            useLayerHook,
            widget?.appearanceSettings,
            widget?.colourSettings,
        ]
    )

    let _colors = [] // list of colors to initially set
    if (localStorage.getItem(name + '_recent_colors') == null) {
        // if there is no local browser storage for this widget
        _colors = [
            branding?.buttonColour ? branding.buttonColour : 'rgba(255,255,255,1)',
            branding?.backgroundColour ? branding.backgroundColour : 'rgba(255,255,255,1)',
            branding?.buttonTextColour ? branding.buttonTextColour : 'rgba(255,255,255,1)',
        ]
    } else {
        // if there is local browser storage for this widget
        const colorsFromLS = JSON.parse(localStorage.getItem(name + '_recent_colors'))

        _colors = [colors[0], colorsFromLS[1], colorsFromLS[2]]
        const _colorOptions = [
            'rgba(255,255,255,1)',
            branding?.backgroundColour ? branding.backgroundColour : 'rgba(255,255,255,1)',
            branding?.buttonColour ? branding.buttonColour : 'rgba(255,255,255,1)',
        ]
        for (let i = 0; i < _colors.length; i++) {
            if (_colors[i] == null) {
                _colors[i] = _colorOptions[i]
            }
        }
    }
    localStorage.setItem(name + '_recent_colors', JSON.stringify(_colors))

    const renderColors = useMemo(
        () =>
            Array.isArray(_colors) &&
            _colors?.map((color, index) => {
                switch (index) {
                    case 0:
                        return (
                            <ColorsContainer
                                key={index}
                                handleColourChange={handleColorChange}
                                colourPicker={isOpenColorPicker}
                                toggleColourPicker={toggleColorPicker}
                                color={color}
                                colorIndex={index}
                            />
                        )
                    case 1:
                        return (
                            <DefaultColorsContainer
                                key={index}
                                onClick={() => {
                                    handleColorChange(color, index, true)
                                }}
                                color={color}
                            />
                        )
                    case 2:
                        return (
                            <DefaultColorsContainer
                                key={index}
                                onClick={() => {
                                    handleColorChange(color, index, true)
                                }}
                                color={color}
                            />
                        )
                    default:
                        break
                }
            }),
        [colors, handleColorChange, isOpenColorPicker]
    )

    return (
        <Fragment>
            <Description>{label}</Description>
            <ColorsWrapper>
                {/* eslint-disable-next-line @next/next/no-img-element */}
                <img
                    width="32px"
                    height="32px"
                    src="/images/colors-image.png"
                    alt="select colors"
                    onClick={toggleColorPicker}
                />
                {renderColors}
            </ColorsWrapper>
        </Fragment>
    )
}

export default EditColor

const DefaultColorsContainer = styled.div.attrs((props) => ({}))<{ color: string }>`
    cursor: pointer;
    width: 32px;
    height: 32px;
    background-color: ${(props) => props.color};
    border-radius: 6px;
    margin-left: 6px;
    border: 1px solid #dfe2f2;
`

const ColorsWrapper = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 18px;
    & > img {
        margin-right: 3px;
    }
`
