import React, { useEffect, useRef, useState } from 'react'
import { useSetRecoilState } from 'recoil'
import styled from 'styled-components'

import { mobilePreviewState } from '../../stores/builder/mobilePreview'
import { mobilePreviewRefState } from '../../stores/builder/view/mobilePreviewRef'
import ICampaign from '../../types/Templates/Custom'
import ScreenshotHover from './ScreenshotHover'

interface Props {
    campaign: ICampaign
    scansVisibility: boolean
}

const ScreenCampaign = ({ campaign, scansVisibility }: Props) => {
    const backgroundColor = '#fff'
    const backgroundImage = ''
    const setMobilePreviewSize = useSetRecoilState(mobilePreviewState)
    const [imageWidth, setImageWidth] = useState<number | undefined>(200)
    const [imageHeight, setImageHeight] = useState<number | undefined>(200)
    const [borderRadius, setBorderRadius] = useState<string>('50px')
    const setMobilePreviewRef = useSetRecoilState(mobilePreviewRefState)

    const mobilePreviewRef = useRef(null)

    useEffect(() => {
        if (mobilePreviewRef.current) {
            setMobilePreviewRef(mobilePreviewRef)
        }
    }, [mobilePreviewRef])

    const imageRef = useRef(null)
    const backgroundRef = useRef(null)

    function adjustContainer(): void {
        let width = imageRef.current.width
        let height = imageRef.current.height
        if (width * 2 > height) {
            width = height / 2
        } else {
            height = width * 2.05
        }

        setMobilePreviewSize({ width, height })

        setImageWidth(width * 0.94)
        setImageHeight(height * 0.95)
        setBorderRadius(`${width / 7}px`)
    }

    useEffect((): any => {
        adjustContainer()

        window.addEventListener('resize', adjustContainer)

        return (_: any) => {
            window.removeEventListener('resize', adjustContainer)
        }
    }, [backgroundRef, imageRef])

    return (
        <Container
            ref={mobilePreviewRef}
            width={imageWidth}
            height={imageHeight}
            borderRadius={borderRadius}
        >
            {/* eslint-disable-next-line @next/next/no-img-element */}
            <img
                ref={imageRef}
                onLoad={adjustContainer}
                className="mobile-outline"
                src="/images/builder/Mobile.svg"
                alt="mobile"
            />
            <Screen
                ref={backgroundRef}
                backgroundColor={backgroundColor}
                backgroundImage={backgroundImage}
                imageWidth={imageWidth}
                imageHeight={imageHeight}
                borderRadius={borderRadius}
            >
                {/* eslint-disable-next-line @next/next/no-img-element */}
                <img className="widgets" src={campaign?.screenshot} alt="preview" />
            </Screen>
            {scansVisibility && <ScreenshotHover scans={campaign?.scans} />}
        </Container>
    )
}

export default ScreenCampaign

const Screen = styled.div<{
    backgroundImage: string
    backgroundColor: string
    imageWidth: number
    imageHeight: number
    borderRadius: string
}>`
    position: absolute;
    top: 3px;
    overflow: hidden;
    background-image: ${(props) =>
        props.backgroundImage ? `url(${props.backgroundImage})` : 'none'};
    background-color: ${(props) => (props.backgroundColor ? props.backgroundColor : 'none')};
    background-size: cover;
    width: ${(props) => props.imageWidth}px;
    height: ${(props) => props.imageHeight}px;
    max-width: ${(props) => props.imageWidth}px;
    max-height: ${(props) => props.imageHeight}px;
    border-radius: ${(props) => props.borderRadius};
    background-position: center;
    background-repeat: no-repeat;

    img {
        position: absolute;
        z-index: 2;
    }
`
const Container = styled.div<{
    width: number
    height: number
    borderRadius: string
}>`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    margin: 0 auto;
    position: relative;

    .mobile-outline {
        z-index: 10;
        pointer-events: none;
    }

    & > img {
        position: absolute;
        z-index: 12;
        overflow: hidden;
        width: 175px;
        height: 347px;
    }

    .scans {
        position: absolute;
        width: ${(props) => props.width}px;
        height: ${(props) => props.height}px;
        max-width: ${(props) => props.width}px;
        max-height: ${(props) => props.height}px;
        border-radius: ${(props) => props.borderRadius};
        z-index: 20;
        color: white;
        font-size: 20px;
        background: rgba(0, 0, 0, 1);
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        opacity: 0.6;

        p {
            margin-top: 5px;
        }

        svg {
            height: 500px;
            width: 500px;
            position: absolute;
            top: 80px;
            left: 65px;
        }
    }
`
