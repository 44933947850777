import React from 'react'
import { IContent } from '../../../../../../models/Assets/Content'
import { IContentModalState } from '../../../../../../stores/assets'
import File from '../../../../../Inputs/File'
import styled from 'styled-components'
import { Tooltip } from '@material-ui/core'

interface Props {
    content: IContent[]
    modalState: IContentModalState
    handleSelectedContent: (content: IContent) => void
    currentSelection: string
    setSelection: (id: string) => void
}

const Wrapper = styled.div`
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    grid-gap: 1rem;
    gap: 1rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
    overflow-y: scroll;
    max-width: auto;
    padding-right: 20px;

    max-height: 350px;
    min-height: 350px;
    height: 350px;

    .selected {
        background: #6478f9;
    }
`

const Selection = styled.div`
    width: 140px;
    height: 140px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
`

const ContentModalItems = ({
    content,
    modalState,
    handleSelectedContent,
    currentSelection,
    setSelection,
}: Props) => {
    return (
        <Wrapper>
            {content.length > 0 &&
                content &&
                content.map((item) => {
                    if (item.type === 'image') {
                        return (
                            <Selection
                                className={
                                    currentSelection ===
                                    item.id + item.createdAt + item.fileUrl + item.fileName
                                        ? 'selected'
                                        : ''
                                }
                            >
                                <File
                                    key={item.id}
                                    name={item.fileName}
                                    imageSrc={item.fileUrl}
                                    onClick={() => {
                                        handleSelectedContent(item)
                                        setSelection(
                                            item.id + item.createdAt + item.fileUrl + item.fileName
                                        )
                                    }}
                                    editable={true}
                                ></File>
                            </Selection>
                        )
                    } else if (item.type === 'video') {
                        return (
                            <Selection
                                className={
                                    currentSelection ===
                                    item._id + item.createdAt + item.fileUrl + item.fileName
                                        ? 'selected'
                                        : ''
                                }
                            >
                                <File
                                    key={item._id}
                                    name={item.fileName}
                                    videoSrc={item.fileUrl}
                                    onClick={() => {
                                        handleSelectedContent(item)
                                        setSelection(item._id)
                                    }}
                                    editable={true}
                                ></File>
                            </Selection>
                        )
                    }
                })}
            {content.length === 0 && (
                <div className="flex items-center justify-center">
                    <h1>No Results</h1>
                </div>
            )}
        </Wrapper>
    )
}

export default ContentModalItems
