import styled, { css, keyframes } from 'styled-components'

const spin = keyframes`
      0% {
        transform: rotateY(0);
      }
      100% {
        transform: rotateY(180deg);
      }
`

export const RoundIconButton = styled.button<{
    width?: string
    loadingProcces?: boolean
}>`
    border-radius: 50%;
    height: 36px;
    width: ${(props) => (props.width ? props.width : '36px')};
    background-color: white;
    box-shadow: 0 2px 11px 0 #eaecf8;
    display: flex;
    flex-direction: column;
    justify-content: center;

    outline: none !important;
    align-items: center;
    margin: 0 0.25rem;

    img {
        animation: ${(props) => (props.loadingProcces ? css`0.5s ${spin} infinite` : 'none')};
    }
`
