import {Tooltip, withStyles} from '@material-ui/core'
import React, {memo, ReactElement, useMemo} from 'react'
import {useRecoilState, useRecoilValue} from 'recoil'

import {activeWidgetSelector} from '../../stores/builder/activeWidget'
import {tooltipState} from '../../stores/tooltip'
import {
    Step1,
    Step10,
    Step11,
    Step12,
    Step13,
    Step14,
    Step2,
    Step3,
    Step4,
    Step5,
    Step6,
    Step7,
    Step8,
    Step9,
} from './steps'

interface Props {
    children: ReactElement<any, any>
    maxWidth?: number
    step: number
    placement:
        | 'bottom-end'
        | 'bottom-start'
        | 'bottom'
        | 'left-end'
        | 'left-start'
        | 'left'
        | 'right-end'
        | 'right-start'
        | 'right'
        | 'top-end'
        | 'top-start'
        | 'top'
}

const TooltipContent = ({ children, maxWidth, step, placement }: Props) => {
    const [tootlip, setTooltip] = useRecoilState(tooltipState)
    const widget = useRecoilValue(activeWidgetSelector)
    const onCloseTooltip = () => {
        setTooltip(null)
    }

    const nextStep = () => {
        if (widget?.type === 'puzzle' && step === 4) {
            setTooltip((prev) => prev + 2)
        } else {
            setTooltip((prev) => prev + 1)
        }
    }

    const prevStep = () => {
        if (widget?.type === 'puzzle' && step === 6) {
            setTooltip((prev) => prev - 2)
        } else {
            setTooltip((prev) => prev - 1)
        }
    }

    const clickLastStep = () => {
        localStorage.setItem('tooltip', `${widget.id}`)
        setTooltip(null)
    }

    const CustomTooltip = useMemo(() => {
        return withStyles((theme) => ({
            tooltip: {
                borderRadius: '15px',
                position: 'relative',
                maxWidth: maxWidth,
                background: '#fff',
                boxShadow: '0px 0px 5px 0px #000000',
                color: '#68758a',
                '& .MuiTooltip-arrow': {
                    '&:before': {
                        border: '1px solid #797979',
                    },
                    color: theme.palette.common.white,
                },
            },
        }))(Tooltip)
    }, [maxWidth])

    const tooltipTitle = useMemo(() => {
        switch (step) {
            case 1:
                return <Step1 onClose={onCloseTooltip} nextStep={nextStep} />
            case 2:
                return <Step2 prevStep={prevStep} nextStep={nextStep} onClose={onCloseTooltip} />
            case 3:
                return <Step3 prevStep={prevStep} nextStep={nextStep} onClose={onCloseTooltip} />
            case 4:
                return (
                    <Step4
                        widget={widget}
                        onClose={onCloseTooltip}
                        prevStep={prevStep}
                        nextStep={nextStep}
                    />
                )
            case 5:
                return (
                    <Step5
                        widget={widget}
                        onClose={onCloseTooltip}
                        prevStep={prevStep}
                        nextStep={nextStep}
                    />
                )
            case 6:
                return <Step6 onClose={onCloseTooltip} prevStep={prevStep} nextStep={nextStep} />
            case 7:
                return (
                    <Step7
                        widget={widget}
                        onClose={onCloseTooltip}
                        prevStep={prevStep}
                        nextStep={nextStep}
                    />
                )
            case 8:
                return (
                    <Step8
                        widget={widget}
                        onClose={onCloseTooltip}
                        prevStep={prevStep}
                        nextStep={nextStep}
                    />
                )
            case 9:
                return <Step9 onClose={onCloseTooltip} prevStep={prevStep} nextStep={nextStep} />
            case 10:
                return <Step10 onClose={onCloseTooltip} prevStep={prevStep} nextStep={nextStep} />
            case 11:
                return <Step11 onClose={onCloseTooltip} prevStep={prevStep} nextStep={nextStep} />
            case 12:
                return <Step12 onClose={onCloseTooltip} prevStep={prevStep} nextStep={nextStep} />
            case 13:
                return <Step13 onClose={onCloseTooltip} prevStep={prevStep} nextStep={nextStep} />
            case 14:
                return <Step14 prevStep={prevStep} lastStep={clickLastStep} />
            default:
                return null
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [step])

    return (
        <CustomTooltip
            open={tootlip === step}
            placement={placement}
            interactive
            arrow
            title={tooltipTitle}
        >
            {children}
        </CustomTooltip>
    )
}

export default memo(TooltipContent)
