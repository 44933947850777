import React from 'react'

export const PlaceholderQR = () => {
    return (
        <svg
            width="180"
            height="180"
            viewBox="0 0 239 239"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M149.375 85.8906H141.906V104.562H149.375V85.8906Z" fill="#DFE2F2" />
            <path d="M164.312 85.8906H156.844V104.562H164.312V85.8906Z" fill="#DFE2F2" />
            <path d="M149.375 112.031H141.906V119.5H149.375V112.031Z" fill="#DFE2F2" />
            <path d="M164.312 112.031H156.844V119.5H164.312V112.031Z" fill="#DFE2F2" />
            <path d="M123.234 126.969H115.766V134.438H123.234V126.969Z" fill="#DFE2F2" />
            <path d="M104.562 201.656H97.0938V209.125H104.562V201.656Z" fill="#DFE2F2" />
            <path d="M164.312 126.969H156.844V134.438H164.312V126.969Z" fill="#DFE2F2" />
            <path d="M149.375 141.906H141.906V149.375H149.375V141.906Z" fill="#DFE2F2" />
            <path d="M164.312 141.906H156.844V149.375H164.312V141.906Z" fill="#DFE2F2" />
            <path
                d="M7.46875 7.46875H18.6719V0H3.73438C1.673 0 0 1.673 0 3.73438V18.6719H7.46875V7.46875Z"
                fill="#DFE2F2"
            />
            <path
                d="M235.266 0H220.328V7.46875H231.531V18.6719H239V3.73438C239 1.673 237.327 0 235.266 0Z"
                fill="#DFE2F2"
            />
            <path
                d="M7.46875 220.328H0V235.266C0 237.327 1.673 239 3.73438 239H18.6719V231.531H7.46875V220.328Z"
                fill="#DFE2F2"
            />
            <path
                d="M231.531 231.531H220.328V239H235.266C237.327 239 239 237.327 239 235.266V220.328H231.531V231.531Z"
                fill="#DFE2F2"
            />
            <path
                d="M14.9375 18.6719V63.4844C14.9375 65.5457 16.6105 67.2188 18.6719 67.2188H63.4844C65.5457 67.2188 67.2188 65.5457 67.2188 63.4844V18.6719C67.2188 16.6105 65.5457 14.9375 63.4844 14.9375H18.6719C16.6105 14.9375 14.9375 16.6105 14.9375 18.6719ZM22.4062 22.4062H59.75V59.75H22.4062V22.4062Z"
                fill="#DFE2F2"
            />
            <path
                d="M48.5469 29.875H33.6094C31.548 29.875 29.875 31.548 29.875 33.6094V48.5469C29.875 50.6082 31.548 52.2812 33.6094 52.2812H48.5469C50.6082 52.2812 52.2812 50.6082 52.2812 48.5469V33.6094C52.2812 31.548 50.6082 29.875 48.5469 29.875ZM44.8125 44.8125H37.3438V37.3438H44.8125V44.8125Z"
                fill="#DFE2F2"
            />
            <path
                d="M175.516 67.2188H220.328C222.389 67.2188 224.062 65.5457 224.062 63.4844V18.6719C224.062 16.6105 222.389 14.9375 220.328 14.9375H175.516C173.454 14.9375 171.781 16.6105 171.781 18.6719V63.4844C171.781 65.5457 173.454 67.2188 175.516 67.2188ZM179.25 22.4062H216.594V59.75H179.25V22.4062Z"
                fill="#DFE2F2"
            />
            <path
                d="M205.391 29.875H190.453C188.392 29.875 186.719 31.548 186.719 33.6094V48.5469C186.719 50.6082 188.392 52.2812 190.453 52.2812H205.391C207.452 52.2812 209.125 50.6082 209.125 48.5469V33.6094C209.125 31.548 207.452 29.875 205.391 29.875ZM201.656 44.8125H194.188V37.3438H201.656V44.8125Z"
                fill="#DFE2F2"
            />
            <path
                d="M63.4844 171.781H18.6719C16.6105 171.781 14.9375 173.454 14.9375 175.516V220.328C14.9375 222.389 16.6105 224.062 18.6719 224.062H63.4844C65.5457 224.062 67.2188 222.389 67.2188 220.328V175.516C67.2188 173.454 65.5457 171.781 63.4844 171.781ZM59.75 216.594H22.4062V179.25H59.75V216.594Z"
                fill="#DFE2F2"
            />
            <path
                d="M33.6094 209.125H48.5469C50.6082 209.125 52.2812 207.452 52.2812 205.391V190.453C52.2812 188.392 50.6082 186.719 48.5469 186.719H33.6094C31.548 186.719 29.875 188.392 29.875 190.453V205.391C29.875 207.452 31.548 209.125 33.6094 209.125ZM37.3438 194.188H44.8125V201.656H37.3438V194.188Z"
                fill="#DFE2F2"
            />
            <path
                d="M164.312 70.9531H138.172V26.1406H130.703V74.6875C130.703 76.7489 132.376 78.4219 134.438 78.4219H164.312V70.9531Z"
                fill="#DFE2F2"
            />
            <path d="M108.297 44.8125H82.1562V52.2812H108.297V44.8125Z" fill="#DFE2F2" />
            <path d="M123.234 29.875H97.0938V37.3438H123.234V29.875Z" fill="#DFE2F2" />
            <path d="M22.4062 70.9531H14.9375V104.562H22.4062V70.9531Z" fill="#DFE2F2" />
            <path
                d="M67.2188 93.3594H29.875V100.828H63.4844V115.766H70.9531V97.0938C70.9531 95.0324 69.2801 93.3594 67.2188 93.3594Z"
                fill="#DFE2F2"
            />
            <path d="M41.0781 74.6875H33.6094V82.1562H41.0781V74.6875Z" fill="#DFE2F2" />
            <path d="M85.8906 67.2188H78.4219V97.0938H85.8906V67.2188Z" fill="#DFE2F2" />
            <path d="M112.031 104.562H78.4219V112.031H112.031V104.562Z" fill="#DFE2F2" />
            <path
                d="M227.797 108.297H220.328V145.641H209.125V153.109H224.062C226.124 153.109 227.797 151.436 227.797 149.375V108.297Z"
                fill="#DFE2F2"
            />
            <path d="M104.562 67.2188H97.0938V74.6875H104.562V67.2188Z" fill="#DFE2F2" />
            <path d="M22.4062 123.234H14.9375V153.109H22.4062V123.234Z" fill="#DFE2F2" />
            <path d="M48.5469 156.844H14.9375V164.312H48.5469V156.844Z" fill="#DFE2F2" />
            <path
                d="M186.719 104.562H194.188V89.625C194.188 87.5636 192.514 85.8906 190.453 85.8906H171.781V93.3594H186.719V104.562Z"
                fill="#DFE2F2"
            />
            <path d="M41.0781 123.234H33.6094V130.703H41.0781V123.234Z" fill="#DFE2F2" />
            <path d="M82.1562 119.5H74.6875V149.375H82.1562V119.5Z" fill="#DFE2F2" />
            <path d="M108.297 156.844H74.6875V164.312H108.297V156.844Z" fill="#DFE2F2" />
            <path d="M63.4844 141.906H29.875V149.375H63.4844V141.906Z" fill="#DFE2F2" />
            <path d="M85.8906 194.188H78.4219V209.125H85.8906V194.188Z" fill="#DFE2F2" />
            <path d="M108.297 179.25H74.6875V186.719H108.297V179.25Z" fill="#DFE2F2" />
            <path
                d="M134.438 164.312V145.641C134.438 143.579 132.764 141.906 130.703 141.906H93.3594V149.375H126.969V164.312H134.438Z"
                fill="#DFE2F2"
            />
            <path d="M104.562 119.5H97.0938V126.969H104.562V119.5Z" fill="#DFE2F2" />
            <path d="M63.4844 126.969H56.0156V134.438H63.4844V126.969Z" fill="#DFE2F2" />
            <path d="M227.797 197.922H220.328V224.062H227.797V197.922Z" fill="#DFE2F2" />
            <path d="M212.859 216.594H201.656V224.062H212.859V216.594Z" fill="#DFE2F2" />
            <path d="M209.125 194.188H201.656V209.125H209.125V194.188Z" fill="#DFE2F2" />
            <path
                d="M227.797 164.312C227.797 162.251 226.124 160.578 224.062 160.578H182.984V168.047H220.328V190.453H227.797V164.312Z"
                fill="#DFE2F2"
            />
            <path
                d="M179.25 209.125V190.453C179.25 188.392 177.577 186.719 175.516 186.719H134.438V194.188H171.781V209.125H179.25Z"
                fill="#DFE2F2"
            />
            <path d="M212.859 175.516H186.719V182.984H212.859V175.516Z" fill="#DFE2F2" />
            <path d="M100.828 216.594H74.6875V224.062H100.828V216.594Z" fill="#DFE2F2" />
            <path d="M119.5 190.453H112.031V224.062H119.5V190.453Z" fill="#DFE2F2" />
            <path d="M134.438 171.781H119.5V179.25H134.438V171.781Z" fill="#DFE2F2" />
            <path
                d="M175.516 134.438H194.188V126.969H179.25V104.562H171.781V130.703C171.781 132.764 173.454 134.438 175.516 134.438Z"
                fill="#DFE2F2"
            />
            <path d="M205.391 112.031H186.719V119.5H205.391V112.031Z" fill="#DFE2F2" />
            <path d="M212.859 74.6875H201.656V82.1562H212.859V74.6875Z" fill="#DFE2F2" />
            <path d="M227.797 74.6875H220.328V93.3594H227.797V74.6875Z" fill="#DFE2F2" />
            <path d="M209.125 93.3594H201.656V100.828H209.125V93.3594Z" fill="#DFE2F2" />
            <path
                d="M145.641 63.4844H160.578C162.639 63.4844 164.312 61.8114 164.312 59.75V11.2031H156.844V56.0156H145.641V63.4844Z"
                fill="#DFE2F2"
            />
            <path
                d="M89.625 18.6719H145.641V11.2031H85.8906C83.8293 11.2031 82.1562 12.8761 82.1562 14.9375V33.6094H89.625V18.6719Z"
                fill="#DFE2F2"
            />
            <path
                d="M93.3594 89.625H119.5C121.561 89.625 123.234 87.952 123.234 85.8906V56.0156H115.766V82.1562H93.3594V89.625Z"
                fill="#DFE2F2"
            />
            <path d="M134.438 85.8906H126.969V100.828H134.438V85.8906Z" fill="#DFE2F2" />
            <path d="M160.578 201.656H134.438V209.125H160.578V201.656Z" fill="#DFE2F2" />
            <path d="M168.047 216.594H134.438V224.062H168.047V216.594Z" fill="#DFE2F2" />
            <path
                d="M186.719 216.594H179.25V224.062H190.453C192.514 224.062 194.188 222.389 194.188 220.328V190.453H186.719V216.594Z"
                fill="#DFE2F2"
            />
            <path d="M67.2188 156.844H59.75V164.312H67.2188V156.844Z" fill="#DFE2F2" />
            <path d="M160.578 156.844H145.641V164.312H160.578V156.844Z" fill="#DFE2F2" />
            <path d="M179.25 141.906H171.781V153.109H179.25V141.906Z" fill="#DFE2F2" />
            <path d="M175.516 160.578H168.047V168.047H175.516V160.578Z" fill="#DFE2F2" />
            <path d="M156.844 171.781H145.641V179.25H156.844V171.781Z" fill="#DFE2F2" />
            <path d="M194.188 141.906H186.719V149.375H194.188V141.906Z" fill="#DFE2F2" />
            <path d="M212.859 126.969H201.656V134.438H212.859V126.969Z" fill="#DFE2F2" />
            <path d="M149.375 126.969H130.703V134.438H149.375V126.969Z" fill="#DFE2F2" />
            <path d="M134.438 108.297H126.969V119.5H134.438V108.297Z" fill="#DFE2F2" />
        </svg>
    )
}
