import React, { useEffect, useState } from 'react'
import { useRecoilState } from 'recoil'
import Loader from 'react-loader-spinner'
import CloseIcon from '@material-ui/icons/Close'
import { useAssets } from '../../../../../../lib/hooks/useAssets'
import { IContent, IContentType } from '../../../../../../models/Assets/Content'
import { contentDataState, contentModalState } from '../../../../../../stores/assets'
import { CustomModal } from '../../../../../Common/Modal'
import Pagination from './Pagination'
import ContentModalItems from './ContentModalItems'
import UploadAssetModal from '../UploadAssetModal'
import styled from 'styled-components'
import { DropMenu } from '../../../../../../stories/Forms/DropMenu'
import { capitalizeFirstLetter } from '../../../../../../utils/capitalizeFirstLetter'
import { SearchBar } from '../../../../../../stories/Search/SearchBar'
import ContentSortable from './ContentSortable'
import RewardItem from '../Rewards/RewardItem'
import { Button } from '../../../../../../stories/Button/Button'
import UploadAssetButton from '../UploadAssetButton'
import { safeName } from '../../../../../../helpers/safeName'

interface Props {
    id: string
    visible: boolean
    toggleVisible: (value: boolean) => void
    update: (src: string, srcName: string, srcType: string) => void
}

const Body = styled.div`
    width: auto;
    height: auto;
    max-width: 680px;
    max-height: 600px;
    min-width: 680px;
    min-height: 600px;
    background-color: white;
    border: 1px solid #e3e7ff;
    box-sizing: border-box;
    border-radius: 16px;

    padding: 28px;

    font-family: Matter;
    font-style: normal;
    font-weight: normal;

    h1 {
        font-size: 18px;
        color: #3c4b61;
    }

    p {
        font-size: 14px;
        color: #3c4b61;
    }

    h2 {
        font-size: 13px;
        color: #3c4b61;

        margin-bottom: 10px;
    }

    h3 {
        font-size: 12px;
        font-style: italic;
        color: #3c4b61;
    }

    h4 {
        font-size: 12px;
    }
`

const ContentModal = ({ id, visible, toggleVisible, update }: Props) => {
    const [modalState, setModalState] = useRecoilState(contentModalState(id))
    const [searchFilter, setSearchFilter] = useState<string>('')
    const [selection, setSelection] = useState<string>('')
    const [uploadContentModalVisible, setUploadContentModalVisible] = useState<boolean>(false)
    const {
        data: content,
        isLoading,
        isError,
    } = useAssets('content', modalState.category, modalState.page, modalState.limit)

    const handleModalState = (attr: string, value: string | number): void => {
        setModalState({ ...modalState, [attr]: value })
    }

    const handleSelectedContent = (content: IContent): void => {
        setModalState({
            ...modalState,
            src: content.fileUrl,
            srcName: content.fileName,
            srcType: content.type,
        })
    }

    const closeContentModal = (): void => {
        setUploadContentModalVisible(false)
        toggleVisible(false)
    }

    const searchText = (event) => {
        setSearchFilter(event.target.value)
    }

    let filteredAssets = content

    // Filtering algorithm for the search terms
    if (content && content.length > 0) {
        if (content[0].category === 'content') {
            filteredAssets = []

            for (let i = 0; i < content.length; i++) {
                let item = content[i]
                if (typeof item.fileName !== 'undefined') {
                    if (item.fileName.toLowerCase().includes(searchFilter.toLocaleLowerCase())) {
                        filteredAssets.push(item)
                    }
                }
            }
        } else if (content[0].category === 'reward') {
            filteredAssets = []

            for (let i = 0; i < content.length; i++) {
                let item = content[i]
                if (typeof item.name !== 'undefined') {
                    if (item.name.toLowerCase().includes(searchFilter.toLocaleLowerCase())) {
                        filteredAssets.push(item)
                    }
                }
            }
        }
    } else {
        filteredAssets = content
    }

    return (
        <>
            {!uploadContentModalVisible && (
                <CustomModal
                    visible={visible}
                    toggleVisible={toggleVisible}
                    title="content library"
                >
                    <>
                        <Body>
                            <div className="flex items-center justify-between mb-3">
                                <h1>Upload from Assets</h1>
                                <svg
                                    onClick={() => toggleVisible(!visible)}
                                    className="cursor-pointer"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M18 5.91016L6 18.0902"
                                        stroke="#3C4B61"
                                        strokeWidth="1.5"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                    <path
                                        d="M6 5.91016L18 18.0902"
                                        stroke="#3C4B61"
                                        strokeWidth="1.5"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                </svg>
                            </div>

                            <div className="flex items-center justify-between mt-4">
                                <AssetsHeaderContainer>
                                    <DropMenu
                                        width="140px"
                                        items={[IContentType.IMAGE, IContentType.VIDEO]}
                                        value={capitalizeFirstLetter(modalState.category)}
                                        onSelect={(value) => handleModalState('category', value)}
                                    />
                                    <SearchBar
                                        size="small"
                                        placeholder="Search assets"
                                        width="284px"
                                        height="38px"
                                        onChange={searchText.bind(this)}
                                    />
                                    <UploadAssetButton id="add" />
                                </AssetsHeaderContainer>
                            </div>
                            {isLoading ? (
                                <div className="w-full h-full flex flex-col items-center justify-center p-10">
                                    <Loader
                                        type="ThreeDots"
                                        color="#808080"
                                        height={50}
                                        width={50}
                                        timeout={3000} //3 secs
                                    />
                                </div>
                            ) : (
                                <ContentModalItems
                                    content={filteredAssets}
                                    modalState={modalState}
                                    currentSelection={selection}
                                    setSelection={setSelection}
                                    handleSelectedContent={handleSelectedContent}
                                />
                            )}
                            <Button
                                size="medium"
                                variant="primary"
                                width="100px"
                                onClick={() =>
                                    update(
                                        safeName(modalState.src),
                                        modalState.srcName,
                                        modalState.srcType
                                    )
                                }
                                loadingText={'Saving'}
                                type="submit"
                            >
                                Save
                            </Button>
                        </Body>
                    </>
                </CustomModal>
            )}
        </>
    )
}

export default ContentModal

const AssetsHeaderContainer = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;

    & > div {
        margin-bottom: 0;
    }
`
