import { useQuery, useMutation,  useQueryClient } from 'react-query'
import { PromiseFetch } from '../fetch/promiseFetch'
import {Asset} from "../../../types/Assets/Asset"
import { Toast } from '../../../helpers/Toast';
import { IReward } from '../../../models/Assets/Reward';
import { IContent } from '../../../models/Assets/Content';
import { IImage } from '../../../models/Image';
import { IVideo } from '../../../models/General/Video';
import { IWidget } from '../../../models/Widget';


function useAssets(category: string, subCategory:string = 'all', page: number, limit: number) {
    return useQuery<any, Error>(['assets', category, subCategory, page], () => PromiseFetch('GET', `/api/content/asset?category=${category}&subcategory=${subCategory}&page=${page}&limit=${limit}`))
}

const createAsset = (payload: any) => PromiseFetch('POST', '/api/content/asset', payload)

const useCreateAssetMutation = (category: string, subCategory:string = "all", page: number) => {

    const queryClient = useQueryClient()

    return useMutation(createAsset, {
        onMutate: async (data: Asset) => {
            await queryClient.cancelQueries(["assets", category, page])
            const previousValue = queryClient.getQueryData<Asset[]>(["assets", category, subCategory, page])
    
            if(previousValue){
                queryClient.setQueryData<Asset[]>(["assets", category, subCategory, page], prevData => {
                    if(prevData !== undefined){
                        return  [...prevData, data]
                    } 
                })
            }

            return {previousValue}
        },
        onError: (error, variables, context) => {
            if(context?.previousValue){
                queryClient.setQueryData<Asset[]>(["assets", category, subCategory, page], context.previousValue)
            }
            Toast("Error saving asset.", 'error')
        },
        onSuccess: (data, variables, context) => {
            
            if(context?.previousValue){
                queryClient.setQueryData<Asset[]>(["assets", category, subCategory, page], prevData => {
                    if(prevData !== undefined){
                        return  [...prevData, data]
                    } 
                })
            } else {
                queryClient.invalidateQueries(['assets', category, subCategory, page])
            }
            
            Toast("Asset successfully created!", 'success')
        },
    })

}

const updateAsset = (payload) => PromiseFetch('PUT', '/api/content/asset', payload)

const useUpdateAssetMutation = (category: string, subCategory: string = "all", page: number) => {

    const queryClient = useQueryClient()

    return useMutation(updateAsset, {
        onMutate: async (data: Asset) => {
            await queryClient.cancelQueries(["assets", category, page])
            const previousValue = queryClient.getQueryData<Asset[]>(["assets", category, subCategory, page])
    
            // if(previousValue){
            //     queryClient.setQueryData<Asset[]>(["assets", category, page], prevData => {
            //         if(prevData !== undefined){
            //             const index = prevData.findIndex(d=>d._id === data._id)
            //             if(index === -1) return prevData
            //             const updatedData = {...prevData[index], ...data}
            //             return [...prevData.slice(0,index), updatedData, ...prevData.slice(index+1)]
            //         } 
            //     })
            // }

            return {previousValue}
        },
        onError: (error, variables, context) => {
            if(context?.previousValue){
                queryClient.setQueryData<Asset[]>(["assets", category, subCategory, page], context.previousValue)
            }
            Toast("Error saving asset.", 'error')
        },
        onSuccess: (data, variables, context) => {

            queryClient.invalidateQueries(['assets', category, subCategory, page])
            
            Toast("Asset successfully updated!", 'success')
        },
    })

}


const useDeleteAssetMutation = (payload: IReward  | IWidget, category: string, subCategory:string,  page: number, )=> {
 
    const queryClient = useQueryClient()

    return useMutation(()=>PromiseFetch('DELETE', `/api/content/asset?category=${category}`, payload), {
        onMutate: async () => {
            await queryClient.cancelQueries(["assets", category, page])
            const previousValue = queryClient.getQueryData<Asset[]>(["assets", category,  subCategory, page])
    
            if(previousValue){
                queryClient.setQueryData<Asset[]>(["assets", category,  subCategory, page], prevData => {
                    if(prevData !== undefined){
                        const newData = prevData.filter(data=> data._id !== payload._id)
                        return newData
                    } 
                })
            }

            return {previousValue}
        },
        onError: (error, variables, context) => {
            if(context?.previousValue){
                queryClient.setQueryData<Asset[]>(["assets", category, subCategory, page], context.previousValue)
            }
            Toast("Error deleting asset.", 'error')
        },
        onSuccess: (data, variables, context) => {

            queryClient.invalidateQueries(['assets', category, subCategory, page])
            
            Toast("Asset successfully deleted!", 'success')
        },
    })

}

export {useAssets, useDeleteAssetMutation, useCreateAssetMutation, useUpdateAssetMutation}