import React, { memo } from 'react'
import { Button } from '../../../stories/Button/Button'
import { ButtonGroup, StepTitle, StepWrapper } from '../style'

interface Props {
    onClose: () => void
    nextStep: () => void
}

const Step1 = ({ onClose, nextStep }: Props) => {
    return (
        <StepWrapper>
            <video muted loop autoPlay style={{ borderRadius: '15px' }}>
                <source src="/video/dragwidgets.mp4" type="video/mp4" />
            </video>
            <StepTitle>
                <strong style={{ fontSize: '18px' }}>
                    Let’s build the story for your audience.
                </strong>
                <br />
                <strong>Using a template?</strong>
                <br /> Add in your logo, brand colours and update the pre populated areas.
                <br />
                <strong>Starting with a blank page?</strong>
                <br /> Build out your story how you wish.
                <br /> Don’t forget you need to add:
                <ul>
                    <li>the rules of the game</li>
                    <li>steps involved in playing the game </li>
                    <li>notifying players they need to register</li>
                    <li>potential prize list</li>
                </ul>
            </StepTitle>
            <ButtonGroup>
                <Button width="20px" variant="invert" onClick={onClose}>
                    Close
                </Button>
                <Button variant="secondary" onClick={nextStep}>
                    Okay what&apos;s next
                </Button>
            </ButtonGroup>
        </StepWrapper>
    )
}

export default memo(Step1)
