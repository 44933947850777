import styled, { keyframes } from 'styled-components'

export const ButtonGroup = styled.div`
    display: flex;
    width: 80%;
    align-items: center;
    justify-content: flex-start;
    button {
        min-width: max-content;
        height: 40px;
        margin-right: 10px;
        font-size: 12px;
    }
`

export const StepWrapper = styled.div`
    margin: 10px 15px 15px 15px;
`

export const StepTitle = styled.h1`
    font-size: 14px;
    margin: 15px 0;
    line-height: 1.5;
    text-align: justify;
    & > strong {
        color: #4d4d4d;
    }
    & > ul > li {
        list-style-type: circle;
        margin-left: 40px;
    }
`

export const ModalContainer = styled.div`
    width: 400px;
    height: max-content;
    min-height: 250px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 15px;
    padding: 25px;
`

export const ModalContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin: 25px auto;
    h1 {
        font-size: 25px;
        font-weight: 800;
        margin-bottom: 18px;
        color: #3ae29b;
    }
    svg {
        margin-bottom: 18px;
    }
    p {
        font-size: 15px;
        font-weight: 400;
        text-align: center;
        width: 80%;
        line-height: 1.2;
    }
`

export const ModalButtonGroup = styled.div`
    display: flex;
    width: 80%;
    align-items: center;
    justify-content: space-between;
    button {
        width: 50%;
        height: 45px;
    }
    button:first-child {
        margin-right: 10px;
    }
`

const bounceAnimation = keyframes`
  from {
    transform: scale(.9);
    transform: translate3d(0, -6px, 0);
  }
  50% {
    transform: scale(1);
    transform: translate3d(0, 0, 0);
  }
  to {
    transform: scale(.9);
    transform: translate3d(0, -6px, 0);
  }
`

export const TooltipButtonWrapper = styled.div`
    border-radius: 50%;
    animation: ${bounceAnimation} 2s infinite;
`
