import { useState, useEffect } from "react";

export const useOutsideClick = (ref) => {
  const [isOutsideClick, setOutsideClick] = useState(false);

  function handleClickOutside(event) {
    if (ref.current && !ref.current.contains(event.target)) {
        setOutsideClick(true);
    }
}

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
  }, [ref]);

  return { isOutsideClick };
};