import React, { HTMLAttributes } from 'react'
import styled, { css } from 'styled-components'

export interface IProps extends HTMLAttributes<HTMLDivElement> {
    direction?: 'row' | 'column'
}
const RowComponent = styled.div<{ direction: 'row' | 'column' }>`
    height: auto;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: left;
    :after {
        content: '';
        display: table;
        clear: both;
    }
`

const Row = ({ direction = 'row', children, ...rest }: IProps) => {
    return (
        <RowComponent direction={direction} {...rest}>
            {children}
        </RowComponent>
    )
}

export default Row
