import React, { useState } from 'react'
import { useRecoilState, useSetRecoilState, useRecoilValue } from 'recoil'
import Voucher from './Voucher'
import { RewardType } from '../../../../../../types/Assets/Rewards/RewardType'
import { IReward } from '../../../../../../models/Assets/Reward'
import Digital from './Digital'
import { Toast } from '../../../../../../helpers/Toast'
import { categoryState as navCategoryState } from '../AssetPicker'
import { clientUpload } from '../../../../../../lib/upload/clientUpload'
import {
    useDeleteAssetMutation,
    useCreateAssetMutation,
    useUpdateAssetMutation,
} from '../../../../../../lib/hooks/useAssets'
import { assetModalState, rewardDataState, rewardsState } from '../../../../../../stores/assets'
import CampaignList from './CampaignList'
import { InputBox } from '../../../../../../stories/Forms/InputBox'
import { DropMenu } from '../../../../../../stories/Forms/DropMenu'
import { Button } from '../../../../../../stories/Button/Button'
import styled from 'styled-components'
import Multiple from './Multiple'
interface Props {
    id: string
}

const RewardAsset = ({ id }: Props) => {
    const [rewardData, setRewardData] = useRecoilState(rewardDataState)
    const [rewards, setRewards] = useRecoilState(rewardsState)
    const category = useRecoilValue(navCategoryState)
    const setModalVisible = useSetRecoilState(assetModalState(id))
    const [loading, setLoading] = useState<boolean>(false)

    const createAssetMutation = useCreateAssetMutation(
        category.assetCategory,
        category.subCategory,
        category.page
    )
    const updateAssetMutation = useUpdateAssetMutation(
        category.assetCategory,
        category.subCategory,
        category.page
    )
    const deleteAsset = useDeleteAssetMutation(
        rewardData,
        rewardData.category,
        category.subCategory,
        category.page
    )

    const updateAssetData = (value: string | number, attr: string): void => {
        setRewardData({
            ...rewardData,
            [attr]: value,
        })
    }

    const handleQuantityBlur = (value: number, attr: string): void => {
        if (value > 9999999999) {
            setRewardData({ ...rewardData, [attr]: 999999999 })
        } else if (value <= 0 || value === undefined) {
            setRewardData({ ...rewardData, [attr]: 1 })
        }
    }

    const onSubmit = async (e: React.FormEvent): Promise<void> => {
        e.preventDefault()

        setLoading(true)

        try {
            let data: IReward = rewardData
            console.log(data)

            if (
                data.rewardType === RewardType.DIGITAL &&
                data.digitalSettings.file instanceof File
            ) {
                // upload file
                const file = data.digitalSettings.file
                const fileName = file.name
                const fileUrl = await clientUpload(data.digitalSettings.file, data.category)

                if (!fileUrl) throw new Error('Error uploading file')

                // save data
                data = {
                    ...data,
                    digitalSettings: {
                        ...data.digitalSettings,
                        file: null,
                        fileName,
                        fileUrl,
                    },
                }
            }

            if (data.rewardType === RewardType.MULTIPLE) {
                // upload file
                const file = data.multipleSettings.file

                const fileName = file?.name

                // save data
                data = {
                    ...data,
                    multipleSettings: {
                        ...data.multipleSettings,
                        fileName,
                    },
                }
            }

            if (id === 'add') {
                await createAssetMutation.mutate(data)
                setRewards([...rewards, data])
            } else {
                await updateAssetMutation.mutate(data)
            }
        } catch (error) {
            Toast(error.message, 'error')
            setLoading(false)
        }

        setLoading(false)
        setModalVisible(false)
    }

    return (
        <form className="flex flex-col mt-2" onSubmit={onSubmit}>
            <h2 className="mt-3">Reward Settings</h2>
            <div className="flex flex-row gap-2">
                <FieldsContainer>
                    <h3>Reward Name</h3>
                    <InputBox
                        size="small"
                        placeholder="reward name"
                        width="284px"
                        onChange={(e) => updateAssetData(e.target.value, 'name')}
                        value={rewardData.name}
                    />
                    <span>This will allow you to track each giveaway item</span>
                </FieldsContainer>
                <FieldsContainer>
                    <h3>Quantity</h3>
                    <InputBox
                        size="small"
                        placeholder="quantity"
                        type="number"
                        width="100px"
                        min={0}
                        onChange={(e) => updateAssetData(Number(e.target.value), 'quantity')}
                        value={rewardData.quantity}
                    />
                    <span>
                        How many of these <br /> items?
                    </span>
                </FieldsContainer>
            </div>

            <FieldsContainer>
                <h3>Reward Type</h3>
                <DropMenu
                    width="400px"
                    value={rewardData.rewardType}
                    items={[
                        RewardType.VOUCHER,
                        RewardType.DIGITAL,
                        RewardType.PHYSICAL,
                        RewardType.MULTIPLE,
                    ]}
                    onSelect={(e) => updateAssetData(e, 'rewardType')}
                />
                <span style={{ marginTop: '8px' }}>
                    Options include physical prizes, vouchers, multiple or digital prizes
                </span>
            </FieldsContainer>

            {rewardData.rewardType === RewardType.VOUCHER && <Voucher />}

            {rewardData.rewardType === RewardType.DIGITAL && <Digital />}

            {rewardData.rewardType === RewardType.MULTIPLE && <Multiple />}

            <CampaignList campaigns={rewardData.campaigns} />

            <div className="self-end">
                {id !== 'add' && (
                    <Button
                        size="medium"
                        variant="orange"
                        width="100px"
                        loading={loading}
                        loadingText={'...'}
                        type="submit"
                        className="right-0"
                        onClick={() => {
                            deleteAsset.mutate()
                        }}
                    >
                        Delete
                    </Button>
                )}

                <Button
                    disabled={loading}
                    size="medium"
                    variant="primary"
                    width="100px"
                    loading={loading}
                    loadingText={'Saving'}
                    type="submit"
                    className="right-0"
                >
                    Save
                </Button>
            </div>
        </form>
    )
}

export default RewardAsset

const FieldsContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    & > div {
        margin-bottom: 0;
    }
    & > span {
        font-weight: 400;
        font-size: 11px;
        color: #717f95;
    }
`
