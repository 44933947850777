export const ValidateSingleInput = (sFileName, _validFileExtensions) => {
    if (sFileName.length > 0) {
        var blnValid = false
        for (var j = 0; j < _validFileExtensions.length; j++) {
            var sCurExtension = _validFileExtensions[j]
            if (
                sFileName
                    .substr(sFileName.length - sCurExtension.length, sCurExtension.length)
                    .toLowerCase() == sCurExtension.toLowerCase()
            ) {
                blnValid = true
                break
            }
        }

        if (!blnValid) {
            return false
        }
    }

    return true
}

export const checkFileType = (fileName: string, fileType: string) => {
    let validFileExtensions = []

    switch (fileType) {
        case 'image':
            validFileExtensions = ['jpeg', 'png', 'gif', 'webp', 'svg+xml', 'svg', 'jpg', 'svg']
            break
        case 'audio':
            validFileExtensions = ['mpeg', 'ogg', 'mp3', 'wav']
            break
        case 'video':
            validFileExtensions = ['mp4', 'webm', 'ogg', 'wmv', 'mov', 'avi', 'mpeg', 'mpg']
            break
        case 'document':
            validFileExtensions = [
                'doc',
                'csv',
                'pdf',
                'docx',
                'txt',
                'ppt',
                'xlsx',
                'xlsm',
                'pptx',
            ]
            break
        default:
            break
    }

    return ValidateSingleInput(fileName, validFileExtensions)
}

export const getFileType = (file: File): string => {
    if (file.type.match('image.*')) return 'image'

    if (file.type.match('video.*')) return 'video'

    if (file.type.match('audio.*')) return 'audio'

    return 'other'
}
