import React, { Dispatch, useCallback } from 'react'
import styled from 'styled-components'
import { useDropzone } from 'react-dropzone'

type Props = {
    width?: string
    height?: string
    imagesOnly?: boolean
    setFile: Dispatch<any> | any
    uploadAnyFiles?: boolean
}

const Container = styled.div.attrs((props) => ({}))<{
    height: string
    width: string
}>`
    display: flex;
    position: relative;
    cursor: pointer;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: ${(props) => props.width};
    height: ${(props) => props.height};
    background: #fff;
    border: 1px dashed #dfe2f2;
    box-sizing: border-box;
    border-radius: 16px;
    transition: 0.15s;
`

const Group = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;

    h2 {
        font-weight: 600;
        font-size: 15px;
        padding-top: 16px;
    }

    p {
        font-size: 12px;
        font-weight: 400;
        color: #717f95;
    }
`

export const FileUploadZone = ({
    width,
    height,
    setFile,
    imagesOnly,
    uploadAnyFiles = false,
    ...props
}: Props) => {
    const onDrop = useCallback((acceptedFiles) => {
        if (acceptedFiles.length !== 0) {
            setFile(acceptedFiles[0])
        }
    }, [])

    const { getRootProps, getInputProps, isDragAccept, isDragReject } = useDropzone({
        onDrop,
        multiple: false,
        accept: uploadAnyFiles
            ? ''
            : `image/jpeg,image/png${!imagesOnly ? ',video/mp4,image/svg+xml' : ''}`,
    })

    return (
        <Container height={height} width={width}>
            <div {...getRootProps()}>
                <input {...getInputProps()} />
                <Group>
                    <img src="/icons/file-upload.svg" alt="Image Icon" />
                    {!isDragReject ? (
                        <Group>
                            {uploadAnyFiles ? (
                                <>
                                    <h2>Drop your file here or click</h2>
                                    <p>.jpg, .png, .svg, .xls, .csv file, max upload 2mb</p>
                                </>
                            ) : (
                                <>
                                    <h2>{`Drop your image ${
                                        !imagesOnly ? ' or video ' : ''
                                    } here or click`}</h2>
                                    <p>{`.jpg or .png or .svg or ${
                                        !imagesOnly ? ' or .mp4 ' : ''
                                    } file, max upload 2mb`}</p>
                                </>
                            )}
                        </Group>
                    ) : (
                        <h2>Sorry, unsupported file type</h2>
                    )}
                </Group>
            </div>
        </Container>
    )
}
