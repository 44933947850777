import React, { HTMLAttributes, ReactNode, useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import QRCode from 'qrcode'
import { motion } from 'framer-motion'

import { Icon, Popover } from '../index'
import { Red, Green, Orange, White, Blue } from '../../styles/v2/styles'
import ICampaign from '../../types/Templates/Custom'
import PreviewModal from '../Modals/PreviewModal'
import { DeploySignal } from '../Signal/DeploySignal'
import { ScansHover } from './ScansHover'
import { SceletonProject } from './SceletonProject'
import ScreenCampaign from './ScreenCampaign'
import { useRouter } from 'next/router'

interface IProjectCardProps extends HTMLAttributes<HTMLDivElement> {
    newCard?: boolean
    data?: ICampaign
    children?: ReactNode
    projectUID?: string
    businessID?: string
}

export enum defaultColor {
    success = 'success',
    weaning = 'weaning',
    error = 'error',
}

export const statusColor = {
    success: Green,
    weaning: Orange,
    error: Red,
}

const selectStatusColor = (key: string): string => {
    switch (key) {
        case defaultColor.success:
            return statusColor.success
        case defaultColor.weaning:
            return statusColor.weaning
        case defaultColor.error:
            return statusColor.error
        default:
            return statusColor.success
    }
}

const ProjectCard = ({
    newCard = false,
    projectUID,
    data,
    businessID,
    ...props
}: IProjectCardProps) => {
    const [modalVisibility, setModalVisibility] = useState<boolean>(false)
    const [qrUrl, setQrUrl] = useState<string | null>(null)
    const [scansVisibility, setScanVisibility] = useState<boolean>(false)
    const router = useRouter()

    let optionList = [
        { name: 'Make primary link', link: '#' },
        { name: 'Delete', link: '#' },
        { name: 'Edit', link: '#' },
        { name: 'View Forms', link: '#' },
    ]

    if (data) {
        optionList = [
            { name: 'Make primary link', link: '#' },
            { name: 'Delete', link: '#' },
            {
                name: 'Edit',
                link: projectUID
                    ? `/admin/campaign/edit/business/${data.campaignId}?projectID=${projectUID}`
                    : `/admin/campaign/edit/${data.campaignId}`,
            },
            { name: 'View Forms', link: `/forms?campaignId=${data.campaignId}` },
        ]
    }

    const handleClickCard = () => {
        if (projectUID) {
            router.push(`/admin/campaign/edit/business/${data.campaignId}?projectID=${projectUID}`)
        } else {
            router.push(`/admin/campaign/edit/${data.campaignId}`)
        }
    }

    useEffect(() => {
        async function generateQR() {
            if (data) {
                if (data.deployQRUrl === undefined) {
                    const url = await generateQRImage(
                        `${origin}/template/preview/${data.campaignId}`
                    )
                    setQrUrl(url)
                } else {
                    setQrUrl(data.deployQRUrl)
                }
            }
        }

        generateQR()
    }, [data])

    function toggleModaVisibility() {
        setModalVisibility(!modalVisibility)
    }

    function generateQRImage(url): Promise<string> {
        return new Promise((resolve, reject) => {
            var opts = {
                errorCorrectionLevel: 'Q',
                type: 'image/png',
                quality: 1,
                width: '2000px',
                margin: 4,
                color: {
                    dark: '#000000ff',
                    light: '#0000',
                },
                background: 'transparent',
            }

            QRCode.toDataURL(url, opts, function (err, url) {
                if (err) {
                    reject(err)
                }
                resolve(url)
            })
        })
    }

    const transition = { duration: 1.1, ease: 'easeInOut' }

    const cardPreviewVariants = {
        initial: { x: '100%', z: 'auto', opacity: 0 },
        enter: { x: 0, z: 'auto', opacity: 1, transition },
        exit: { x: '-100%', z: 'auto', opacity: 0, transition },
    }
    const newProjectVariants = {
        initial: { opacity: 0 },
        enter: { opacity: 1, transition: { duration: 1.5, ease: 'easeInOut' } },
    }

    return (
        <>
            {newCard ? (
                <motion.div variants={newProjectVariants}>
                    <ProjectCardComponent newCard {...props}>
                        <Icon size="lg" name="plus" color="#6478F9" />
                        <ProjectCardText>New Campaign</ProjectCardText>
                    </ProjectCardComponent>
                </motion.div>
            ) : (
                <motion.div variants={cardPreviewVariants}>
                    <ProjectCardComponent
                        onMouseEnter={() => {
                            setScanVisibility(true)
                        }}
                        onMouseLeave={() => {
                            setScanVisibility(false)
                        }}
                        {...props}
                    >
                        <MobileContainer onClick={handleClickCard}>
                            {data?.screenshot ? (
                                <ScreenCampaign campaign={data} scansVisibility={scansVisibility} />
                            ) : (
                                <>
                                    {scansVisibility && <ScansHover data={data} />}
                                    <SceletonProject />
                                </>
                            )}
                        </MobileContainer>
                        <Footer>
                            <FooterDetail>
                                <DeploySignal isDeployed={data?.status === 'published'} />
                                <div style={{ marginLeft: 10 }}>
                                    <ProjectName>
                                        <p>{data?.campaignName}</p>
                                    </ProjectName>
                                    <ProjectLink
                                        onClick={() => setModalVisibility(true)}
                                        target="_blank"
                                    >
                                        {'View Link / QR'}
                                    </ProjectLink>
                                </div>
                            </FooterDetail>
                            <FooterMoreDetail>
                                <Popover
                                    businessID={businessID}
                                    projectID={projectUID}
                                    data={data}
                                    options={optionList}
                                />
                            </FooterMoreDetail>
                        </Footer>
                    </ProjectCardComponent>
                </motion.div>
            )}

            {modalVisibility && (
                <PreviewModal
                    id={data.campaignName}
                    QR={qrUrl}
                    link={data.deployUrl}
                    visible={modalVisibility}
                    toggleVisible={setModalVisibility}
                />
            )}
        </>
    )
}
export default ProjectCard

const ProjectCardComponent = styled.div.attrs((props) => ({}))<{
    newCard?: boolean
}>`
    max-height: 392px;
    min-width: 278px;
    height: 392px;
    width: 100%;
    display: flex;
    flex-shrink: 0;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
    border: ${(p) => (p.newCard ? css`1px solid ${Blue}` : css`1px solid #E3E7FF`)};
    background: ${(p) => (p.newCard ? White : '#FAFAFF')};
    cursor: pointer;
    position: relative;
    padding: 33px 52px 0px 52px;

    :hover {
        box-shadow: 0px 0px 3px ${Blue};
    }
`
const ProjectCardText = styled.p`
    font-size: 18px;
    font-style: normal;
    font-weight: normal;
    line-height: 22px;
    text-align: center;
    color: ${Blue};
    margin-top: 15px;
    font-family: 'Matter';
`
const MobileContainer = styled.div`
    height: 346px;
    top: 35px;
    position: absolute;
    width: 174px;
    border-radius: 20px;

    svg {
        position: absolute;
        padding-right: 10px;
    }
`

const ProjectContent = styled.div`
    width: 100%;
    height: 100%;
    border-radius: 30px;
    padding: 1px;
    display: flex;
    overflow: hidden;
`
export const Footer = styled.div`
    height: 55px;
    width: 100%;
    bottom: 0px;
    position: absolute;
    background: white;
    padding: 13px 16px;
    border-top: 1px solid #dfe2f5;
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;
    font-family: 'Matter';
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    z-index: 55;
`
export const FooterDetail = styled.div`
    height: 35px;
    width: 195px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
`

const FooterMoreDetail = styled.div`
    height: 35px;
    width: 50px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
`

const ProjectName = styled.div`
    font-size: 14px !important;
    line-height: 14px;
    color: #3c4b61;
    text-transform: capitalize;
    margin-bottom: 3.5px;
    max-width: 200px;

    p {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        max-width: 170px;
        padding: 3px 0;
    }
`
const ProjectLink = styled.a`
    font-family: 'Matter';
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    line-height: 10px;
    text-decoration-line: underline;
    color: #717f95;
    overflow: none;
`

const StatusButton = styled.div<{ color: string }>`
    height: 10px;
    width: 10px;
    border-radius: 50%;
    background: ${(p) => p.color};
    box-shadow: 0px 0px 5px ${(p) => p.color};
`
