import React from 'react'
import dynamic from 'next/dynamic'
import { IContent } from '../../../../../../models/Assets/Content'
import { Image } from '../../../../../../models/Image'
import { Video } from '../../../../../../models/General/Video'
import ContentPreviewContainer from './ContentPreviewContainer'
import { IWidget } from '../../../../../../models/Widget'
import File from '../../../../../Inputs/File'

interface Props {
    widget: IWidget
}

const RenderContentWidget = ({ widget }: Props) => {
    if (!widget) return <></>

    return (
        <ContentPreviewContainer widget={widget}>
            <>
                {widget.type === 'image' && (
                    <File name={widget.name} imageSrc={widget.settings.src} onClick={() => {}} />
                )}
                {widget.type === 'video' && (
                    <File name={widget.name} videoSrc={widget.settings.src + '#t=0.1'} />
                )}
            </>
        </ContentPreviewContainer>
    )
}

export default RenderContentWidget
