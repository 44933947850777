import React, { HTMLAttributes, useEffect, useRef } from 'react'
import Link from 'next/link'
import styled from 'styled-components'
import { Blue, SoftBlue, White } from '../../styles/v2/styles'
import { useOutsideClick } from '../../hooks'
import { useMutation, useQueryClient } from 'react-query'
import ICampaign from '../../types/Templates/Custom'
import { useState } from 'react'
import { Toast } from '../../helpers/Toast'
import { useRouter } from 'next/router'
import { UrlObject } from 'url'
import IFormData from '../../types/Forms/FormData'
import { useRecoilValue } from 'recoil'
import { userProfileState } from '../../stores/auth/userProfileState'

export interface Props extends HTMLAttributes<any> {
    options: { link: string; name: string }[]
    toggleOptions: Function
    data: ICampaign
    projectID?: string
    businessID?: string
}

const PopOverItemContainer = styled.div`
    height: auto;
    width: auto;
    position: absolute;
    bottom: inherit;
    right: -10px;
    top: -150px;
    padding: 13px 12px 13px 14px;
    background: ${White};
    border: 1px solid #e3e7ff;
    box-sizing: border-box;
    border-radius: 9px;
    z-index: 60 !important;
`
const PopOverItem = styled.p`
    font-family: 'Matter';
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 29px;
    cursor: pointer;
    text-transform: capitalize;
    white-space: pre;
    color: ${SoftBlue};
    :hover {
        color: ${Blue};
    }
`
const PopOverItems = ({ data, options, toggleOptions, projectID, businessID, ...props }: Props) => {
    let wrapperRef = useRef(null)
    const { isOutsideClick } = useOutsideClick(wrapperRef)

    const { campaignId, campaignName } = data

    const queryClient = useQueryClient()
    const router = useRouter()

    const [loading, setLoading] = useState<boolean>(false)
    const [formData, setFormData] = useState<any>({})

    const userProfile = useRecoilValue(userProfileState)
    const username = userProfile.username
    const filter = data.status

    useEffect(() => {
        if (isOutsideClick) {
            toggleOptions()
        }
    }, [isOutsideClick])

    const updatePrimaryPageMutation = useMutation(updatePrimaryPage, {
        onMutate: async (campaignData: ICampaign) => {
            console.log('HEYY')
            await queryClient.cancelQueries(['primaryPage'])

            const previousValue = queryClient.getQueryData<ICampaign>('primaryPage')

            if (previousValue) {
                queryClient.setQueryData<ICampaign>('primaryPage', campaignData)
            }
            return { previousValue }
        },
        onError: (error, variables, context) => {
            if (context?.previousValue) {
                // An error happened!
                queryClient.setQueryData<ICampaign>('primaryPage', context.previousValue)
            }
        },
        onSettled: (data, error, variables, context) => {
            if (context?.previousValue) {
                queryClient.setQueryData<ICampaign>('primaryPage', data)
            } else {
                queryClient.invalidateQueries('primaryData')
            }
        },
    })

    async function updatePrimaryPage(campaignData: ICampaign): Promise<ICampaign> {
        setLoading(true)

        if (username == '' || username === undefined || username === null) {
            setLoading(false)
            Toast('Please set your username first in the accounts section.', 'error')
            return
        }

        try {
            const usernameRes = await fetch('/api/account/updateUsername', {
                method: 'POST',
                body: JSON.stringify({
                    username,
                    campaignId,
                    isPrimary: true,
                }),
            })

            const usernameData = await usernameRes.json()

            if (!usernameRes.ok) {
                setLoading(false)
                Toast(usernameData.message, 'error')
                return
            }

            Toast(`Successfully set ${campaignName} to primary brand page.`, 'success')
            setLoading(false)

            return campaignData
        } catch (error) {
            console.log(error)
            Toast('Could not set to primary page. Please try again later.', 'error')
            setLoading(false)
        }
    }

    const deleteCampaignMutation = useMutation(deleteCampaign, {
        onMutate: async () => {
            await queryClient.cancelQueries(['campaigns', filter])

            const previousValue = queryClient.getQueryData<ICampaign[]>(['campaigns', filter])

            if (previousValue) {
                queryClient.setQueryData<ICampaign[]>(['campaigns', filter], (prevData) => {
                    if (prevData !== undefined) {
                        const newCampaignArray = prevData.filter(
                            (campaign) => campaign.campaignId !== campaignId
                        )
                        return newCampaignArray
                    }
                })
            }

            return { previousValue }
        },
        onError: (error, variables, context) => {
            if (context?.previousValue) {
                // An error happened!
                queryClient.setQueryData<ICampaign[]>(['campaigns', filter], context.previousValue)
            }
        },
        onSettled: (data, error, variables, context) => {
            if (context?.previousValue) {
                queryClient.setQueryData<ICampaign[]>(['campaigns', filter], (prevData) => {
                    if (prevData !== undefined) {
                        const newCampaignArray = prevData.filter(
                            (campaign) => campaign.campaignId !== campaignId
                        )
                        return newCampaignArray
                    }
                })
            } else {
                queryClient.invalidateQueries('campaigns')
            }
        },
    })

    async function deleteCampaign(): Promise<void> {
        try {
            const res = await fetch('/api/campaign/delete', {
                method: 'POST',
                body: JSON.stringify({
                    campaignId,
                    projectID: userProfile.projectId,
                    businessID: businessID,
                    uid: projectID,
                    campaignName,
                    username,
                }),
            })

            const result = await res.json()

            if (!res.ok) {
                throw new Error(result.error)
            }

            Toast('Campaign successfully deleted!', 'success')

            return result
        } catch (error) {
            Toast(error, 'error')
        }
    }

    async function fetchFormData(): Promise<void> {
        const res = await fetch('/api/campaign/form-data', {
            method: 'POST',
            body: JSON.stringify({ campaignId }),
        })

        const data = await res.json()

        if (res.status == 200) {
            setFormData(data)
        } else if (res.status === 500) {
            Toast(data.message, 'error')
        }
    }

    async function getFormData(): Promise<void> {
        await fetchFormData()
    }

    const onClickLink = (name: string, path: string | UrlObject) => () => {
        if (name === 'Make primary link') {
            updatePrimaryPageMutation.mutate(data)
        } else if (name === 'Delete') {
            deleteCampaignMutation.mutate()
        } else {
            router.push(path)
        }
    }

    return (
        <PopOverItemContainer ref={wrapperRef}>
            {options.map(({ link, name }, i) => (
                <PopOverItem key={i} onClick={onClickLink(name, link)}>
                    {name}
                </PopOverItem>
            ))}
        </PopOverItemContainer>
    )
}

export default PopOverItems
