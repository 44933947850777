import React, { useState } from 'react'
import { useRecoilState, useSetRecoilState, useRecoilValue } from 'recoil'
import { Toast } from '../../../../../../helpers/Toast'
import { useCreateAssetMutation } from '../../../../../../lib/hooks/useAssets'
import { getFileType } from '../../../../../../lib/upload/checkFileType'
import { clientUpload } from '../../../../../../lib/upload/clientUpload'
import { contentDataState, assetModalState } from '../../../../../../stores/assets'
import { Button } from '../../../../../../stories/Button/Button'
import { FileUploadZone } from '../../../../../../stories/File Upload/FileUploadZone'
import ButtonSpinner from '../../../../../ButtonSpinner'
import File from '../../../../../Inputs/File'
import { categoryState } from '../AssetPicker'
import FilePicker from '../FilePicker'

interface Props {
    id: string
}

const fileTypes = [
    'image/jpg',
    'image/jpeg',
    'image/png',
    'image/gif',
    'image/webp',
    'video/mp4',
    'video/webm',
    'video/ogg',
    'video/wmv',
    'video/mov',
    'video/avi',
    'video/mpeg',
    'video/mpg',
    'image/svg+xml',
]

const ContentSettings = ({ id }: Props) => {
    const [contentData, setContentData] = useRecoilState(contentDataState)
    const category = useRecoilValue(categoryState)
    const setModalVisible = useSetRecoilState(assetModalState(id))
    const createAssetMutation = useCreateAssetMutation(
        contentData.category,
        category.subCategory,
        category.page
    )
    const [loading, setLoading] = useState<boolean>(false)

    const onSubmit = async (e: React.FormEvent): Promise<void> => {
        e.preventDefault()
        setLoading(true)
        try {
            if (!contentData.file) {
                return
            }

            const fileUrl = await clientUpload(contentData.file, contentData.category)

            if (!fileUrl) throw new Error('Error uploading file')

            const data = {
                ...contentData,
                fileUrl,
                file: null,
            }

            await createAssetMutation.mutate(data)
            setLoading(false)
            setModalVisible(false)
        } catch (error) {
            Toast(error.message, 'error')
        }
    }

    const updateFile = async (attr: string, file: File): Promise<void> => {
        if (!file) {
            setContentData({ ...contentData, fileName: '', type: '', file: null })
            return
        }

        const fileName = file.name
        const fileUrl = URL.createObjectURL(file)
        const type = getFileType(file)

        if (type === 'other') {
            Toast('Unsupported file type', 'error')
            return
        }

        if (file.size / 1024 / 1024 > 50) {
            Toast('File size cannot be exceed 50mb', 'error')
            return
        }

        setContentData({ ...contentData, fileName, fileUrl, type, file })
    }

    return (
        <form onSubmit={onSubmit}>
            <div className="flex flex-col mt-5">
                {contentData.fileName === '' && !contentData.file && (
                    <FileUploadZone
                        width="584px"
                        height="219px"
                        imagesOnly={false}
                        setFile={(file) => updateFile('', file)}
                    />
                )}

                {contentData.fileName !== '' && contentData.fileUrl !== '' && (
                    <div className="flex flex-col self-center items-center w-full justify-center">
                        {contentData.file.type.includes('image') ? (
                            <File
                                name={contentData.fileName}
                                imageSrc={contentData.fileUrl}
                                onClick={() => updateFile('', null)}
                                editable={false}
                                width="584px"
                                height="219px"
                            />
                        ) : (
                            <File
                                name={contentData.fileName}
                                videoSrc={contentData.fileUrl + '#t=0.1'}
                                onClick={() => updateFile('', null)}
                                editable={false}
                                width="584px"
                                height="219px"
                            />
                        )}
                    </div>
                )}

                <div className="mt-1 flex flex-col items-end justify-items-end, justify-end">
                    <Button
                        size="medium"
                        variant="primary"
                        width="100px"
                        loading={loading}
                        loadingText={'Saving'}
                        type="submit"
                        className="right-0"
                    >
                        Save
                    </Button>
                </div>
            </div>
        </form>
    )
}

export default ContentSettings
