import { Button } from '@material-ui/core'
import React from 'react'
import styled from 'styled-components'
import { IWidget } from '../../../models/Widget'

interface ButtonGroupProps {
    duplicateWidget: () => void
    removeWidget: () => void
    widget?: IWidget
}

export const ButtonGroup = ({ duplicateWidget, removeWidget, widget }: ButtonGroupProps) => {
    return (
        <ButtonGroupContainer>
            <Button onClick={removeWidget}>
                <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M17.96 5.83008H5.69V19.1901C5.69 20.3201 6.61001 21.2401 7.74001 21.2401H15.9C17.03 21.2401 17.95 20.3201 17.95 19.1901V5.83008H17.96Z"
                        stroke="#717F95"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        d="M3.78998 5.83008H20.21"
                        stroke="#717F95"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        d="M15.08 2.74023H8.91998V5.82023H15.08V2.74023Z"
                        stroke="#717F95"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        d="M9.94 9.75V17.14"
                        stroke="#717F95"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        d="M14.05 9.75V17.14"
                        stroke="#717F95"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </svg>
                Delete
            </Button>
            <Button onClick={duplicateWidget} disabled={widget?.type === 'spinwheel'}>
                <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M14.9474 3H5.68421C4.75789 3 4 3.75789 4 4.68421V15.6316C4 16.0947 4.37895 16.4737 4.84211 16.4737C5.30526 16.4737 5.68421 16.0947 5.68421 15.6316V5.52632C5.68421 5.06316 6.06316 4.68421 6.52632 4.68421H14.9474C15.4105 4.68421 15.7895 4.30526 15.7895 3.84211C15.7895 3.37895 15.4105 3 14.9474 3ZM18.3158 6.36842H9.05263C8.12632 6.36842 7.36842 7.12632 7.36842 8.05263V19.8421C7.36842 20.7684 8.12632 21.5263 9.05263 21.5263H18.3158C19.2421 21.5263 20 20.7684 20 19.8421V8.05263C20 7.12632 19.2421 6.36842 18.3158 6.36842ZM17.4737 19.8421H9.89474C9.43158 19.8421 9.05263 19.4632 9.05263 19V8.89474C9.05263 8.43158 9.43158 8.05263 9.89474 8.05263H17.4737C17.9368 8.05263 18.3158 8.43158 18.3158 8.89474V19C18.3158 19.4632 17.9368 19.8421 17.4737 19.8421Z"
                        fill="#717F95"
                    />
                </svg>
                Duplicate
            </Button>
        </ButtonGroupContainer>
    )
}

const ButtonGroupContainer = styled.div`
    position: fixed;
    bottom: 0;
    display: flex;
    background: #fff;
    height: 66px;
    width: 320px;
    border-top: 1px solid #dfe2f2;
    align-items: center;
    justify-content: flex-start;
    padding-left: 17px;
    z-index: 999;
    svg {
        margin-right: 11px;
    }
    button {
        border: 1px solid #dfe2f2;
        border-radius: 8px;
        text-transform: none;
        font-size: 14px;
        color: #3c4b61;
        outline: none;
    }
    & > button:first-child {
        margin-right: 12px;
    }
`
