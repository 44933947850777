import React from 'react'
import styled from 'styled-components'
import CustomTemplate from '../../types/Templates/Custom'

interface ScansHoverProps {
    data: CustomTemplate
}

export const ScansHover = ({ data }: ScansHoverProps) => {
    return (
        <Container>
            <svg
                width="300"
                height="300"
                viewBox="0 0 300 300"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M4.24626 1.3335H1.25V4.32976H4.24626V1.3335Z"
                    stroke="white"
                    strokeWidth="1.5"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M4.24626 14.8169H1.25V17.8132H4.24626V14.8169Z"
                    stroke="white"
                    strokeWidth="1.5"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M20.7257 1.3335H17.7294V4.32976H20.7257V1.3335Z"
                    stroke="white"
                    strokeWidth="1.5"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M1.25 7.32617H5.7444V8.82427"
                    stroke="white"
                    strokeWidth="1.5"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M1.25 11.8203H7.24253"
                    stroke="white"
                    strokeWidth="1.5"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M17.7536 14.8167H20.7259V17.8129H14.7574V10.3223H20.7259V11.8204"
                    stroke="white"
                    strokeWidth="1.5"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M10.2629 11.8204V10.3223"
                    stroke="white"
                    strokeWidth="1.5"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M14.7574 2.83163V1.3335"
                    stroke="white"
                    strokeWidth="1.5"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M11.7371 14.8169H10.2629"
                    stroke="white"
                    strokeWidth="1.5"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M7.2428 14.8633V17.8131H11.7372"
                    stroke="white"
                    strokeWidth="1.5"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M7.2428 1.35742V4.32972H8.7409"
                    stroke="white"
                    strokeWidth="1.5"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M11.7368 1.35742V7.326H8.74048"
                    stroke="white"
                    strokeWidth="1.5"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M14.7574 7.32617H20.7499"
                    stroke="white"
                    strokeWidth="1.5"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
            <p>{data.scans ? data?.scans : '0'}</p>
            <p>Scans</p>
        </Container>
    )
}

const Container = styled.div`
    position: absolute;
    top: 9px;
    left: 1px;
    width: 174px;
    height: 300px;
    border-radius: 25px;
    color: white;
    font-family: 'Matter';
    font-size: 20px;
    background: rgba(0, 0, 0, 1);
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    p {
        margin-top: 5px;
    }

    svg {
        height: 500px;
        width: 500px;
        position: absolute;
        top: 80px;
        left: 70px;
    }
`
