import { nanoid } from 'nanoid'
import { RewardType } from '../../types/Assets/Rewards/RewardType'
import { VoucherGenerator } from '../../types/Assets/Rewards/Voucher'
import { Asset } from './Asset'

export enum DigitalType {
    FILE = 'file',
    URL = 'url',
}

export interface IReward extends Asset {
    name: string
    rewardType: RewardType
    quantity: number
    voucherSettings: VoucherSettings
    digitalSettings: DigitalSettings
    multipleSettings: MultipleSettings
    campaigns: { [key: string]: { campaignName: string; redeemedQuantity: number } }
}

export interface VoucherSettings {
    voucherGenerator: VoucherGenerator
    length: number
    prefix: string
    startingNumber: number
    defaultVoucherCode: string
}

export interface DigitalSettings {
    type: DigitalType
    file?: File | null
    fileUrl?: string | null
    fileName?: string
    url?: string
}

export interface MultipleSettings {
    file?: File | null
    fileUrl?: string | null
    fileName?: string
    url?: string
    data: any
}

class Reward implements IReward {
    id = nanoid()
    createdAt = new Date().toISOString()
    category = 'reward'
    name = ''
    rewardType = RewardType.VOUCHER
    quantity = 1
    voucherSettings = {
        voucherGenerator: VoucherGenerator.SEQUENTIAL,
        length: 6,
        prefix: '',
        startingNumber: 1,
        defaultVoucherCode: '',
    }
    digitalSettings = {
        type: DigitalType.FILE,
        file: null,
        fileUrl: '',
        fileName: '',
        url: '',
    }
    multipleSettings = {
        file: null,
        fileUrl: '',
        fileName: '',
        url: '',
        data: null,
    }
    campaigns: {}
}

export default Reward
