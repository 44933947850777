import React, { HTMLAttributes, useState, ChangeEvent, useEffect } from 'react'
import styled from 'styled-components'
import { IWidget } from '../../../models/Widget'
import { SimpleSelect } from '../../Forms/SelectBox'
import { Icon } from '../../index'
import { BorderIcon, MiddleMultiSelectContainer, MultiSelectContainer } from '../Border/BorderWidth'
import { ContainerBody, WidthBlock } from '../Width/Width'
import BorderCustom from './MarginCustom'

export interface Props extends HTMLAttributes<HTMLDivElement> {
    label: string
    widget: IWidget
    handleChange: (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, attr: string) => void
    handleChangeMargin?: (e: ChangeEvent<HTMLInputElement>) => void
    handleChangePadding?: (e: ChangeEvent<HTMLInputElement>) => void
}

export const Margin = ({ label, widget, handleChange, handleChangeMargin }: Props) => {
    const [selectedType, setSelectedType] = useState('px')
    const [isCustomBorder, setCustomState] = useState(false)

    const settings = widget?.appearanceSettings || widget?.settings
    const marginTop = settings?.marginTop
    const marginBottom = settings?.marginBottom
    const marginLeft = settings?.marginLeft
    const marginRight = settings?.marginRight

    useEffect(() => {
        if (
            marginTop === marginBottom &&
            marginBottom === marginLeft &&
            marginLeft === marginRight &&
            marginRight === marginTop
        ) {
            setCustomState(false)
        } else {
            setCustomState(true)
        }
    }, [marginBottom, marginLeft, marginRight, marginTop])

    const handleChangeType = (opt: React.SetStateAction<string>): void => {
        setSelectedType(opt)
    }

    const checkCustomState = () => {
        setCustomState((prev) => !prev)
    }

    return (
        <MarginContainer>
            <ContainerLabel>{label}</ContainerLabel>
            <ContainerBody>
                <WidthBlock>
                    <input
                        type="number"
                        value={marginTop || marginBottom || marginLeft || marginRight}
                        onChange={handleChangeMargin}
                    />
                    <SimpleSelect
                        options={['px', '%', 'vw']}
                        onHandleChange={handleChangeType}
                        size="small"
                        defaultValue="px"
                    />
                </WidthBlock>
                <BorderIcon isCustomBorder={isCustomBorder} onClick={checkCustomState}>
                    <div style={{ padding: 5 }}>
                        {isCustomBorder ? (
                            <Icon
                                name="margin-link"
                                size={'sm'}
                                color={isCustomBorder ? '#6478F9' : ''}
                            />
                        ) : (
                            <Icon
                                name="margin-unlink"
                                size={'sm'}
                                color={isCustomBorder ? '#6478F9' : ''}
                            />
                        )}
                    </div>
                </BorderIcon>
            </ContainerBody>
            {isCustomBorder && (
                <MultiSelectContainer
                    initial={{ opacity: 0, y: 50, scale: 0.3 }}
                    animate={{ opacity: 1, y: 0, scale: 1 }}
                    exit={{ opacity: 0, scale: 0.5, transition: { duration: 0.2 } }}
                >
                    <TopBottomMultiSelectContainer>
                        <BorderCustom
                            direction="Top"
                            value={marginTop}
                            onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                handleChange(e, 'marginTop')
                            }
                        />
                    </TopBottomMultiSelectContainer>
                    <MiddleMultiSelectContainer>
                        <BorderCustom
                            direction="Left"
                            value={marginLeft}
                            onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                handleChange(e, 'marginLeft')
                            }
                        />
                        <BorderCustom
                            direction="Right"
                            value={marginRight}
                            onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                handleChange(e, 'marginRight')
                            }
                        />
                    </MiddleMultiSelectContainer>
                    <TopBottomMultiSelectContainer>
                        <BorderCustom
                            direction="Bottom"
                            value={marginBottom}
                            onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                handleChange(e, 'marginBottom')
                            }
                        />
                    </TopBottomMultiSelectContainer>
                </MultiSelectContainer>
            )}
        </MarginContainer>
    )
}

const MarginContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    color: #717f95;
    margin-bottom: 15px;
`

export const ContainerLabel = styled.label`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin-right: 10px;
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    color: #3c4b61;
    margin-bottom: 7px;
`

export const TopBottomMultiSelectContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
`
