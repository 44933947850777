import React, { HTMLAttributes, useState, useEffect, ChangeEvent } from 'react'
import styled from 'styled-components'

export interface Props extends HTMLAttributes<HTMLDivElement> {
    label: string
    disabled?: boolean
    widgetName?: string
    size?: number
    handleSizeChange?: (e: ChangeEvent<HTMLInputElement>) => void
    unit?: string
    min?: number
    max?: number
    name?: string
}

export const Sizer = ({
    label,
    handleSizeChange,
    size,
    widgetName,
    disabled,
    unit,
    ...props
}: Props) => {
    const checkLabel = () => {
        if (widgetName) {
            return `${widgetName[0]?.toUpperCase() + widgetName?.slice(1)} ${label}`
        } else {
            return `${label[0]?.toUpperCase() + label?.slice(1)}`
        }
    }

    return (
        <SizerContainer disabled={disabled}>
            <ContainerLabel>{checkLabel()}</ContainerLabel>
            <ContainerBody>
                <Wrapper>
                    <InputValue
                        {...props}
                        disabled={disabled}
                        type="number"
                        value={size}
                        onChange={handleSizeChange}
                    />
                    <Unit>{unit ? unit : 'px'}</Unit>
                </Wrapper>
            </ContainerBody>
        </SizerContainer>
    )
}

export const SizerContainer = styled.div.attrs((props) => ({}))<{
    disabled?: boolean
}>`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    color: #717f95;
    transition: opacity 0.2s;
    opacity: ${(props) => (props.disabled ? 0.4 : 1)};
`

export const Wrapper = styled.div`
    border: 1px solid #dfe2f2;
    border-radius: 8px;
    margin-right: 10px;
    height: 32px;
`

export const ContainerLabel = styled.label`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin-right: 10px;
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    color: #3c4b61;
    margin-bottom: 7px;
`

export const ContainerBody = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
`

export const InputValue = styled.input`
    width: 43px;
    height: 28px;
    border: none;
    font-weight: 600;
    border-radius: 8px;
    padding-left: 7px;
    color: #717f95;
    font-size: 12px;
`

export const Unit = styled.span`
    font-weight: 600;
    font-size: 14px;
    line-height: 12px;
    color: #3c4b61;
    margin: 10px 10px 10px 0;
`
