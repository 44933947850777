import React, { ChangeEvent } from 'react'
import { IWidget } from '../../../models/Widget'
import { Margin } from '../../../stories/Controllers/Margin/Margin'
import { Padding } from '../../../stories/Controllers/Margin/Padding'
import { DesignBlock } from './DesignTab'

export interface SpacingPropsType {
    widget?: any
    handleChange: (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, attr: string) => void
    handleChangeMargin?: (e: ChangeEvent<HTMLInputElement>) => void
    handleChangePadding?: (e: ChangeEvent<HTMLInputElement>) => void
}

export const Spacing = ({
    widget,
    handleChange,
    handleChangeMargin,
    handleChangePadding,
}: SpacingPropsType) => {
    return (
        <DesignBlock>
            <p>Spacing</p>
            {widget?.type !== 'popup' && (
                <Margin
                    label="Margin"
                    widget={widget}
                    handleChange={handleChange}
                    handleChangeMargin={handleChangeMargin}
                />
            )}
            {widget?.type !== 'rich text' && (
                <Padding
                    label="Padding"
                    widget={widget}
                    handleChange={handleChange}
                    handleChangePadding={handleChangePadding}
                />
            )}
        </DesignBlock>
    )
}
