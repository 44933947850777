import { useQuery, useMutation, useQueryClient } from 'react-query'
import { IBrandingSettings } from '../../../types/Branding'

async function serverFetchBranding(base_url, cookies) {
    const res = await fetch(`${base_url}/api/account/branding`, {
        method: 'POST',
        body: JSON.stringify({
            cookies: cookies,
        }),
    })
    if (!res.ok) {
        throw new Error(res.statusText)
    }

    return await res.json()
}

async function fetchBranding(uid?: string) {
    const res = await fetch('/api/account/branding', {
        method: 'POST',
        body: JSON.stringify(uid),
    })
    if (!res.ok) {
        throw new Error(res.statusText)
    }

    return await res.json()
}

function useBranding(uid?: string) {
    const initialData = {
        backgroundColour: '#f9f9f9',
        backgroundImageUrl: '',
        logoImageUrl: '',
        font: {
            family: 'Poppins',
        },
        textColour: 'white',
        buttonColour: 'black',
        buttonTextColour: 'white',
        iconColour: 'white',
    }

    return useQuery('branding', () => fetchBranding(uid), { initialData: initialData })
}

export { fetchBranding, serverFetchBranding, useBranding }
