import React, { useState } from 'react'
import { SketchPicker } from 'react-color'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'
import {
    BackgroundImageContainer,
    ColourPicker,
    ColourPickerWrapper,
    Container,
    CurrentColor,
} from '../../components/BrandSettings/styles'
import { ColorsContainerPropsType } from '../../types/Branding'

export const ColorsContainer = ({
    color,
    toggleColourPicker,
    colourPicker,
    handleColourChange,
    backgroundImage,
    onRemoveBackgroundImage,
    position,
    colorIndex,
}: ColorsContainerPropsType) => {
    const [_color, _setColor] = useState(color)

    return (
        <Container>
            {color && (
                <>
                    <CurrentColor background={color} onClick={toggleColourPicker} />
                    {/* eslint-disable-next-line @next/next/no-img-element */}
                    <img
                        style={{ position: 'absolute', zIndex: 5 }}
                        width="32px"
                        height="32px"
                        src="/icons/builder/colorback.svg"
                        alt="opacity icon"
                    />
                </>
            )}
            {backgroundImage && (
                <BackgroundImageContainer>
                    <span className="fab-action-button">
                        {/* eslint-disable-next-line @next/next/no-img-element */}
                        <img
                            src={
                                backgroundImage !== undefined
                                    ? backgroundImage
                                    : '/images/background-image.png'
                            }
                            alt="background"
                        />
                    </span>
                    <div className="fab-buttons">
                        <div className="fab-buttons__item" onClick={onRemoveBackgroundImage}>
                            <span className="fab-buttons__link" data-tooltip="Remove background">
                                <DeleteOutlineIcon className="icon-material" />
                            </span>
                        </div>
                    </div>
                </BackgroundImageContainer>
            )}
            {colourPicker && (
                <ColourPickerWrapper position={position}>
                    <ColourPicker
                        onClick={() => {
                            toggleColourPicker()
                            handleColourChange(_color, colorIndex)
                        }}
                    />
                    <SketchPicker color={_color} onChange={(color) => _setColor(color.rgb)} />
                </ColourPickerWrapper>
            )}
        </Container>
    )
}
