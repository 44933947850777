import React, { ChangeEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import styled, { keyframes } from 'styled-components'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import SearchIcon from '@material-ui/icons/Search'
import Loader from 'react-loader-spinner'
import useSWR from 'swr'
import { FontManager, Options, OPTIONS_DEFAULTS } from '@samuelmeuli/font-manager'

import { Toast } from '../../helpers/Toast'
import { InputBox } from '../Forms/InputBox'
import { motion } from 'framer-motion'
import { useQueryClient } from 'react-query'
import { IBrandingSettings } from '../../types/Branding'

const fetcher = (url: string) => fetch(url).then((res) => res.json())
const API_KEY = 'AIzaSyCVKoMaxlHC67C4sUWekndaZS81P13UrQI'
const FONTS_URL = `https://www.googleapis.com/webfonts/v1/webfonts?sort=popularity&key=${API_KEY}`

interface IFont {
    category: string
    family: string
    url?: string
    files: { [key: string]: string }
    kind: string
    lastModified: string
    subsets: string[]
    variants: string[]
}

interface Props {
    label: string
    defaultFont: string
    onHandleChangeFont: (family: any, url?: string) => void
}

const EditFonts = ({ label, defaultFont, onHandleChangeFont }: Props) => {
    const [visibility, setVisibility] = useState(false)
    const [selectedFont, setSelectedFont] = useState(defaultFont || 'Poppins')
    const queryClient = useQueryClient()
    const branding = queryClient.getQueryData<IBrandingSettings>('branding')
    const [searchFont, setSearchFont] = useState('')
    const { data, error } = useSWR(FONTS_URL, fetcher)
    const menuRef = useRef<HTMLDivElement>()

    const options: Options = {
        pickerId: OPTIONS_DEFAULTS.pickerId,
        families: OPTIONS_DEFAULTS.families,
        categories: OPTIONS_DEFAULTS.categories,
        scripts: OPTIONS_DEFAULTS.scripts,
        variants: OPTIONS_DEFAULTS.variants,
        filter: OPTIONS_DEFAULTS.filter,
        limit: OPTIONS_DEFAULTS.limit,
        sort: OPTIONS_DEFAULTS.sort,
    }

    // font loader init with custom font logic
    let fontManager = null
    if (selectedFont.includes('[CUSTOM]')) {
        fontManager = new FontManager(API_KEY, 'Poppins', options, onHandleChangeFont)
    } else {
        fontManager = new FontManager(API_KEY, selectedFont, options, onHandleChangeFont)
    }

    useEffect(() => {
        if (error) {
            return Toast('Something went wrong with loading fonts', 'error')
        }
        fontManager.init().catch((err: Error): void => {
            console.error('Error trying to fetch the list of available fonts')
            console.error(err)
        })
    }, [error])

    useEffect(() => {
        const handler = (event: Event) => {
            if (!menuRef.current.contains(event.target as Node)) {
                setVisibility(false)
            }
        }
        document.addEventListener('mousedown', handler)
        return () => {
            document.removeEventListener('mousedown', handler)
        }
    })

    const onHandleOpenFontList = () => {
        setVisibility(true)
    }

    const onHandleSelected = useCallback(
        (font) => () => {
            setVisibility(false)
            setSelectedFont(font.family)
            if (font.url) {
                onHandleChangeFont(font.family, font.url)
            } else {
                onHandleChangeFont(font.family)
            }
        },
        [onHandleChangeFont]
    )

    const handleSearch = (event: ChangeEvent<HTMLInputElement>) => {
        setSearchFont(event.target.value)
    }

    const renderFonts = useMemo(() => {
        if (data) {
            let dataFonts = data.items

            if (branding.font) {
                const customFonts = Object.values(branding.font)

                for (let i = 0; i < customFonts.length; i++) {
                    if (dataFonts.indexOf(customFonts[i]) === -1) {
                        dataFonts.push(customFonts[i])
                    }
                }
            }

            // const filteredFonts = dataFonts.filter((item) =>
            //     item.family !== undefined
            //         ? item.family.toLowerCase().includes(searchFont.toLowerCase())
            //         : item.toLowerCase().includes(searchFont.toLowerCase())
            // )

            let filteredFonts = []
            dataFonts.forEach((fontData) => {
                if (fontData.family) {
                    // if there is a font family property
                    if (fontData.family.toLowerCase().includes(searchFont.toLowerCase())) {
                        filteredFonts.push(fontData)
                    }
                } else {
                    // if there isn't a font family property
                    if (
                        typeof fontData === 'string' &&
                        fontData.toLowerCase().includes(searchFont.toLowerCase())
                    ) {
                        filteredFonts.push(fontData)
                    }
                }
            })

            if (filteredFonts.length !== 0) {
                return filteredFonts.map((font, index: number) => {
                    return (
                        <li
                            key={font.family + index}
                            className={selectedFont === font.family ? 'active-font' : ''}
                            onClick={onHandleSelected(font)}
                            style={{
                                display: 'flex',
                                alignItems: 'flex-end',
                                fontFamily: font.family,
                            }}
                        >
                            {font.family}
                        </li>
                    )
                })
            } else {
                return dataFonts?.map((font, index: number) => {
                    return (
                        <li
                            key={font.family + index}
                            className={selectedFont === font.family ? 'active-font' : ''}
                            onClick={onHandleSelected(font)}
                            style={{
                                display: 'flex',
                                alignItems: 'flex-end',
                                fontFamily: font.family,
                            }}
                        >
                            {font.family}
                        </li>
                    )
                })
            }
        } else {
            return <Loader type="ThreeDots" color="#808080" height={10} width={10} timeout={3000} />
        }
    }, [data, onHandleSelected, searchFont, selectedFont])

    return (
        <Container ref={menuRef}>
            <FormLabel>{label}</FormLabel>
            <Select onClick={onHandleOpenFontList}>
                <SelectedFont fontFamily={selectedFont}>
                    <span title={selectedFont === '' ? 'Select font' : selectedFont}>
                        {selectedFont === ''
                            ? 'Select font'
                            : selectedFont.length <= 20
                            ? selectedFont
                            : `${selectedFont?.slice(0, 20)}...`}
                    </span>
                    <ArrowDropDownIcon className={visibility ? 'rotate' : ''} />
                </SelectedFont>
            </Select>

            {visibility && (
                <Fonts
                    initial={{ opacity: 0, y: 50, scale: 0.3 }}
                    animate={{ opacity: 1, y: 0, scale: 1 }}
                    exit={{ opacity: 0, scale: 0.5, transition: { duration: 0.2 } }}
                >
                    <SearchBlock>
                        <InputBox
                            name="searchValue"
                            type="text"
                            width="100%"
                            placeholder="Search font"
                            onChange={handleSearch}
                            value={searchFont}
                        />
                        <SearchIcon />
                    </SearchBlock>
                    <ul>{renderFonts}</ul>
                </Fonts>
            )}
        </Container>
    )
}

export default EditFonts

const Container = styled.div`
    position: relative;
    width: 100%;
`

const Select = styled.div`
    width: 100%;
    height: 32px;
    display: inline-block;
    padding: 0 16px;
    border-radius: 10px;
    background: #fff;
    border: 1px solid #dfe2f2;
    position: relative;
    cursor: pointer;
`
const SelectedFont = styled.div<{
    fontFamily: string
}>`
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    span {
        font-weight: 400;
        font-size: 14px;
        color: #3c4b61;
        font-family: ${(props) => props.fontFamily};
    }
    svg {
        transition: transform 0.2s ease-in-out;
        &.rotate {
            transform: rotate(180deg);
        }
    }
`
const fadeIn = keyframes`
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
`
const Fonts = styled(motion.div)`
    animation: 0.3s ${fadeIn} ease-out;
    position: absolute;
    z-index: 999;
    background: #fff;
    left: 0px;
    top: 55px;
    width: 100%;
    max-height: 249px;
    overflow-y: auto;
    padding: 8px;
    border-radius: 10px;
    border: 1px solid #dfe2f2;

    &::-webkit-scrollbar {
        width: 5px;
        display: none;
    }

    &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        border-radius: 50%;
    }

    &::-webkit-scrollbar-thumb {
        background-color: darkgrey;
        outline: 1px solid slategrey;
    }

    &:hover {
        &::-webkit-scrollbar {
            display: block;
        }
    }

    ul {
        margin: 0;
        padding: 0;
        li {
            height: 32px;
            font-size: 14px;
            list-style: none;
            line-height: 29px;
            padding: 0 10px;
            cursor: pointer;
            border-radius: 6px;
            color: #3c4b61;
            transition: 0.3s linear;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            &:hover {
                color: #000;
                background: rgba(100, 120, 249, 0.15);
            }
            &.active-option {
                color: #000;
                background: rgba(10, 39, 206, 0.082);
            }
        }
    }
`
const FormLabel = styled.p`
    margin: 6px 0;
    font-size: 12px;
    line-height: 14px;
    color: #3c4b61;
    font-weight: bold;
`
const SearchBlock = styled.div`
    display: flex;
    width: inherit;
    position: sticky;

    & > div {
        margin-bottom: 0;
    }

    .MuiSvgIcon-root {
        position: absolute;
        right: 10px;
        top: 7px;
        opacity: 0.5;
    }
`
