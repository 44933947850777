import React from 'react'
import styled from 'styled-components'

interface Props {
    label?: string
    width?: string
    height?: string
    size?: 'small' | 'medium' | 'large'
    value?: string | number
    placeholder?: string
    name?: string
    onChange?: (any) => void
    error?: string
    onBlur?: (any) => void
    onKeyUp?: (e: React.KeyboardEvent) => void
    maxLength?: number
    margin?: string
    background?: string
}

const InputWrapper = styled.div<{
    margin?: string
}>`
    display: flex;
    flex-direction: row;
    margin: ${(props) => props.margin || '0 0 12px 0'};

    .error-text {
        margin: 0.2rem 0;
        color: red;
    }
`

const InputLabel = styled.label`
    font-size: 14px;
    font-weight: bold;
    color: ${(props) => props.theme.text.secondary};
`

const InputBoxStyle = styled.input.attrs((props) => ({}))<{
    componentSize: string
    width: string
}>`
    border-radius: 10px;
    border: 1px solid ${(props) => props.theme.lineColour};
    color: ${(props) => props.theme.text.light};
    padding: ${(props) =>
        props.componentSize === 'small'
            ? '10px 10px'
            : props.componentSize === 'medium'
            ? '12px 12px'
            : '14px 14px'};

    display: flex;
    align-items: center;
    width: ${(props) => (props.width ? props.width : 'auto')};
    ::placeholder {
        color: ${(props) => props.theme.text.light};
    }
    font-size: ${(props) =>
        props.componentSize === 'small'
            ? '10px'
            : props.componentSize === 'medium'
            ? '12px'
            : '15px'};
    padding-right: 30px;
`

export const SearchBar = ({ label, size = 'medium', width, height, margin, ...props }: Props) => {
    return (
        <InputWrapper margin={margin}>
            {label && <InputLabel>{label}</InputLabel>}
            <InputBoxStyle {...props} componentSize={size} width={width} max={props.maxLength} />
            {props.error && <p className="error-text">{props.error}</p>}
        </InputWrapper>
    )
}
