import React, { Fragment, memo, useMemo } from 'react'
import { IWidget } from '../../../models/Widget'
import { Button } from '../../../stories/Button/Button'
import { ButtonGroup, StepTitle, StepWrapper } from '../style'

interface Props {
    widget: IWidget
    onClose: () => void
    prevStep: () => void
    nextStep: () => void
}

const Step5 = ({ prevStep, nextStep, onClose, widget }: Props) => {
    const renderStep = useMemo(() => {
        if (widget && widget.type === 'spinwheel') {
            return (
                <StepWrapper>
                    <StepTitle>
                        <strong>Use custom image for ‘pin’</strong>
                        <br />
                        Upload your own design to appear at the top of the Spin Wheel. You can also
                        customise the colours in the design tab.
                    </StepTitle>
                    <ButtonGroup>
                        <Button onClick={prevStep}>Back</Button>
                        <Button width="20px" variant="invert" onClick={onClose}>
                            Close
                        </Button>
                        <Button variant="secondary" onClick={nextStep}>
                            OK
                        </Button>
                    </ButtonGroup>
                </StepWrapper>
            )
        }
        if (widget && widget.type === 'scratchcard') {
            return (
                <StepWrapper>
                    <StepTitle>
                        <strong>Choose the erase percentage</strong>
                        <br />
                        It indicates how much the foreground must be erased in order to receive a
                        prize.
                    </StepTitle>
                    <ButtonGroup>
                        <Button onClick={prevStep}>Back</Button>
                        <Button width="20px" variant="invert" onClick={onClose}>
                            Close
                        </Button>
                        <Button variant="secondary" onClick={nextStep}>
                            OK
                        </Button>
                    </ButtonGroup>
                </StepWrapper>
            )
        }
        if (widget && widget.type === 'quiz') {
            return (
                <StepWrapper>
                    <StepTitle>
                        <strong>Choose the percentage of correct answers</strong>
                        <br />
                        This will make it possible to receive a prize even if not all answers were
                        correct.
                    </StepTitle>
                    <ButtonGroup>
                        <Button onClick={prevStep}>Back</Button>
                        <Button width="20px" variant="invert" onClick={onClose}>
                            Close
                        </Button>
                        <Button variant="secondary" onClick={nextStep}>
                            OK
                        </Button>
                    </ButtonGroup>
                </StepWrapper>
            )
        }
        if (widget && widget.type === 'flipcard') {
            return (
                <StepWrapper>
                    <StepTitle>
                        <strong>Choose the numbers of cards</strong>
                        <br />
                        It indicates how many cards the user can open in one attempt
                    </StepTitle>
                    <ButtonGroup>
                        <Button onClick={prevStep}>Back</Button>
                        <Button width="20px" variant="invert" onClick={onClose}>
                            Close
                        </Button>
                        <Button variant="secondary" onClick={nextStep}>
                            OK
                        </Button>
                    </ButtonGroup>
                </StepWrapper>
            )
        }
    }, [nextStep, onClose, prevStep, widget])

    return <Fragment>{renderStep}</Fragment>
}

export default memo(Step5)
