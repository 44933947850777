import React, { HTMLAttributes, useMemo } from 'react'
import { useRecoilValue } from 'recoil'
import styled from 'styled-components'
import { TextAlignment } from '../../../models/Games/Spinwheel'
import { activeWidgetSelector } from '../../../stores/builder/activeWidget'
import { BorderIcon } from '../Border/BorderWidth'
import { ContainerBody } from '../Sizer/Sizer'

export interface Props extends HTMLAttributes<HTMLDivElement> {
    label: string
    defaultAlignment: string
    widgetName?: string
    onChange?: any
    vertical?: boolean
}

export const Alignment = ({
    widgetName,
    defaultAlignment,
    label,
    onChange,
    vertical = false,
}: Props) => {
    const widget = useRecoilValue(activeWidgetSelector)

    const alignmentData =
        widget?.type === 'spinwheel'
            ? [
                  { id: 1, alignment: TextAlignment.INNER, link: '/icons/builder/left.svg' },
                  { id: 2, alignment: TextAlignment.CENTER, link: '/icons/builder/centered.svg' },
                  { id: 3, alignment: TextAlignment.OUTER, link: '/icons/builder/right.svg' },
              ]
            : [
                  { id: 1, alignment: 'left', link: '/icons/builder/left.svg' },
                  { id: 2, alignment: 'center', link: '/icons/builder/centered.svg' },
                  { id: 3, alignment: 'right', link: '/icons/builder/right.svg' },
                  { id: 4, alignment: 'justify', link: '/icons/builder/block.svg' },
              ]

    const alignmentContainerData = [
        { id: 1, alignment: 'left', link: '/icons/builder/block_left.svg' },
        { id: 2, alignment: 'center', link: '/icons/builder/block_centered.svg' },
        { id: 3, alignment: 'end', link: '/icons/builder/block_right.svg' },
    ]

    const alignmenVerticaltData = [
        {
            id: 1,
            alignment: 'flex-start',
            link: (
                <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M18 12H6"
                        stroke="#717F95"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        d="M18 17H6"
                        stroke="#717F95"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        d="M30 7H6 10H2"
                        stroke="#717F95"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </svg>
            ),
        },
        {
            id: 2,
            alignment: 'center',
            link: (
                <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M18 17H6"
                        stroke="#717F95"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        d="M18 7H6"
                        stroke="#717F95"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        d="M30 12H6 10H2"
                        stroke="#717F95"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </svg>
            ),
        },
        {
            id: 3,
            alignment: 'flex-end',
            link: (
                <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M18 12H6"
                        stroke="#717F95"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        d="M30 17H6 10H2"
                        stroke="#717F95"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        d="M18 7H6"
                        stroke="#717F95"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </svg>
            ),
        },
    ]

    const checkLabel = () => {
        if (widgetName) {
            return `${widgetName[0].toUpperCase() + widgetName.slice(1)} ${label}`
        } else {
            return `${label[0].toUpperCase() + label.slice(1)}`
        }
    }

    const renderAlignment = useMemo(() => {
        if (
            widget?.type === 'link text' ||
            widget?.type === 'rich text' ||
            widget?.type === 'swiper' ||
            widget?.type === 'polls'
        ) {
            return alignmentData.map((item) => (
                <BorderIcon
                    key={item.id}
                    isCustomBorder={defaultAlignment === item.alignment}
                    onClick={onChange(item.alignment)}
                >
                    <div style={{ padding: 5 }}>
                        <FillIcon
                            link={item.link}
                            color={defaultAlignment === item.alignment ? '#6478F9' : '#717F95'}
                            width="20px"
                            height="20px"
                        />
                    </div>
                </BorderIcon>
            ))
        } else {
            return alignmentContainerData.map((item) => (
                <BorderIcon
                    key={item.id}
                    isCustomBorder={defaultAlignment === item.alignment}
                    onClick={onChange(item.alignment)}
                >
                    <div style={{ padding: 5 }}>
                        <FillIcon
                            link={item.link}
                            color={defaultAlignment === item.alignment ? '#6478F9' : '#717F95'}
                            width="15px"
                            height="15px"
                        />
                    </div>
                </BorderIcon>
            ))
        }
    }, [defaultAlignment, onChange, widget?.type])

    const renderVerticalAlignment = useMemo(
        () =>
            alignmenVerticaltData?.map((item) => (
                <BorderIcon
                    key={item.id}
                    isCustomBorder={defaultAlignment === item.alignment}
                    onClick={onChange(item.alignment)}
                >
                    <div style={{ padding: 5 }}>
                        <Icon
                            color={defaultAlignment === item.alignment ? '#6478F9' : '#717F95'}
                            width="20px"
                            height="20px"
                        >
                            {item.link}
                        </Icon>
                    </div>
                </BorderIcon>
            )),
        [defaultAlignment, onChange]
    )

    return (
        <BorderContainer>
            <ContainerLabel>{checkLabel()}</ContainerLabel>
            <ContainerBody>{vertical ? renderVerticalAlignment : renderAlignment}</ContainerBody>
        </BorderContainer>
    )
}

const BorderContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 15px;
    color: #717f95;
`

const ContainerLabel = styled.label`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin-right: 10px;
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    color: #3c4b61;
    margin-bottom: 7px;
`
const Icon = styled.div.attrs((props) => ({}))<{
    color: string
    width: string
    height: string
}>`
    width: ${(props) => props.width};
    height: ${(props) => props.height};
    svg {
        width: 100%;
        height: 100%;
        path {
            stroke: ${(props) => props.color};
        }
    }
`

const FillIcon = styled.div.attrs((props) => ({}))<{
    link: string
    color: string
    width: string
    height: string
}>`
    width: ${(props) => props.width};
    height: ${(props) => props.height};
    background-color: ${(props) => props.color};
    mask: url(${(props) => props.link}) no-repeat center / contain;
    -webkit-mask: url(${(props) => props.link}) no-repeat center / contain;
`
