import React, { ChangeEvent, useState } from 'react'
import { useRecoilState, useRecoilCallback } from 'recoil'
import { IReward } from '../../../../../../models/Assets/Reward'
import { rewardDataState } from '../../../../../../stores/assets'
import Papa from 'papaparse'
import { Button } from '@material-ui/core'
import styled from 'styled-components'

const Multiple = () => {
    const [rewardData, setRewardData] = useRecoilState<IReward>(rewardDataState)
    const [error, setError] = useState<string | null>(null)

    const updateMultipleState = useRecoilCallback(
        ({ snapshot }) =>
            async (key: string, value: any) => {
                setError(null)
                const state = await snapshot.getPromise(rewardDataState)

                if (!value) {
                    setRewardData({
                        ...state,
                        multipleSettings: {
                            ...state.multipleSettings,
                            [key]: null,
                        },
                    })
                    return
                }

                setRewardData({
                    ...state,
                    multipleSettings: {
                        ...state.multipleSettings,
                        [key]: value,
                    },
                })
            }
    )

    const hamdleUploadFile = (e: ChangeEvent<HTMLInputElement>) => {
        const files = e.target.files
        if (files) {
            updateMultipleState('file', files[0])
            Papa.parse(files[0], {
                complete: (results: { data: any[] }) => {
                    updateMultipleState(
                        'data',
                        results.data.flat().filter((item) => item !== '')
                    )
                },
            })
        }
    }

    return (
        <div>
            <UploadContainer>
                <input type="file" id="uploadFile" accept=".csv" onChange={hamdleUploadFile} />
                <Button htmlFor="uploadFile" component="label" variant="outlined" color="primary">
                    <svg
                        width="22"
                        height="22"
                        viewBox="0 0 22 22"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M20.1591 11.8146C20.0394 13.5492 18.6836 15.4434 16.0318 15.4434C14.8156 15.4434 14.1776 15.4434 14.1776 15.4434C13.7389 15.4434 13.3801 15.1044 13.3801 14.6658C13.3801 14.2271 13.7389 13.9081 14.1776 13.9081C14.1776 13.9081 14.8156 13.8682 16.0318 13.8682C17.6867 13.8682 18.5042 12.7517 18.5839 11.695C18.6637 10.5984 17.9659 9.38213 16.2711 9.14287C16.0518 9.10299 15.8524 8.98336 15.7328 8.80392C15.6131 8.62448 15.5733 8.38522 15.6131 8.16589C15.8325 7.2288 15.5932 6.49108 14.9751 6.15213C14.4567 5.85305 13.6193 5.87299 12.9414 6.53096C12.762 6.7104 12.5028 6.79015 12.2635 6.75028C12.0243 6.7104 11.8049 6.55089 11.6853 6.33157C10.5289 4.1583 8.75438 4.05861 7.57802 4.61688C6.44153 5.15521 5.54431 6.49108 6.24215 8.18583C6.32191 8.38522 6.32191 8.62448 6.22221 8.82386C6.12252 9.02324 5.94308 9.18275 5.74369 9.24256C3.31122 9.98028 3.37104 11.5753 3.43085 12.0539C3.57042 12.9311 4.26826 13.8284 5.34493 13.8284L7.83721 13.8483C8.27586 13.8483 8.63475 14.2271 8.63475 14.6658C8.63475 15.1044 8.27586 15.5032 7.83721 15.5032L5.36487 15.4434C3.6103 15.4434 2.1548 14.0876 1.87567 12.2732C1.65635 10.8775 2.2545 9.0033 4.48758 8.00639C3.96919 5.81318 5.20536 3.95892 6.88018 3.18133C8.59487 2.36386 11.0074 2.60311 12.6025 4.81626C13.5994 4.25799 14.7757 4.21812 15.7527 4.77639C16.5901 5.25491 17.3478 6.25182 17.268 7.74719C19.3815 8.38522 20.2588 10.2195 20.1591 11.8146ZM13.7988 12.4726C13.9583 12.6321 14.1576 12.7118 14.357 12.7118C14.5564 12.7118 14.7558 12.6321 14.9153 12.4726C15.2343 12.1536 15.2343 11.6551 14.9153 11.3361L11.5457 7.94657C11.4062 7.80701 11.2068 7.72725 10.9875 7.72725C10.7681 7.72725 10.5688 7.80701 10.4292 7.96651L7.05962 11.356C6.74061 11.675 6.74061 12.1735 7.05962 12.4925C7.37863 12.8115 7.87709 12.8115 8.1961 12.4925L10.1899 10.4389L10.2099 17.8958C10.2099 18.3344 10.5688 18.6933 11.0074 18.6933C11.446 18.6933 11.8049 18.3344 11.8049 17.8958L11.785 10.4588L13.7988 12.4726Z"
                            fill="#717F95"
                        />
                    </svg>
                    Upload your .csv file
                </Button>

                {rewardData.multipleSettings.file && (
                    <div className="my-2 text-sm flex flex-col">
                        <p
                            className="hover:line-through hover:cursor-not-allowed"
                            onClick={() => updateMultipleState('file', null)}
                        >
                            <b>Selected file: </b>
                            {rewardData.multipleSettings.file.name}
                        </p>
                    </div>
                )}

                <Note>
                    <span>
                        This type of reward makes it possible to distribute several prizes from one
                        file. For example, voucher numbers or access codes, etc. Prerequisite: all
                        numbers must be placed in one column in your file. For example:
                    </span>
                    <img width={180} src="/images/example_reward.png" alt="example" />
                </Note>
            </UploadContainer>

            {error && <h4>{error}</h4>}
        </div>
    )
}

export default Multiple

const Note = styled.div`
    display: flex;
    width: 370px;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
    margin-bottom: 18px;

    & > span {
        font-weight: 400;
        font-size: 11px;
        color: #717f95;
        margin-bottom: 8px;
    }
`

const UploadContainer = styled.div`
    display: flex;
    flex-direction: column;

    p {
        cursor: pointer;
    }

    input[id^='uploadFile'] {
        display: none;
    }
    .MuiButton-outlinedPrimary {
        outline: none;
        height: 32px;
        width: 100%;
        text-transform: none;
        border: 1px solid #dfe2f2;
        border-radius: 6px;
        font-size: 12px;
        color: #717f95;
        margin-bottom: 18px;
        &:first-child {
            margin-right: 7px;
        }
        &:hover {
            border: 1px solid #dfe2f2;
        }
        svg {
            margin-right: 7px;
        }
        @media (max-width: 450px) {
            .MuiButton-label {
                display: -webkit-inline-box;
            }
        }
    }
`
