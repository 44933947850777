import React, { ChangeEvent, useMemo } from 'react'
import styled from 'styled-components'
import { useUpdateWidgetSettings } from '../../../lib/widgets/useUpdateWidgetSettings'

import { IWidget } from '../../../models/Widget'
import EditFonts from '../../../stories/Components/EditFonts'
import { Sizer } from '../../../stories/Controllers/Sizer/Sizer'
import { SimpleSelect } from '../../../stories/Forms/SelectBox'
import { FontPickerContainer } from '../../BrandSettings/styles'
import { DesignBlock } from './DesignTab'

interface FontProps {
    font: { family: string }
    size?: number
    handleFontChange: (family: any, url?: string) => void
    handleFontSizeChange?: (e: ChangeEvent<HTMLInputElement>) => void
    widget?: IWidget
    weight?: string
    name?: string
    handleWeightChange?: (opt: string) => void
}

export const Font = ({
    font,
    size,
    handleFontChange,
    handleFontSizeChange,
    widget,
    weight,
    name,
    handleWeightChange,
}: FontProps) => {
    const updateSettings = useUpdateWidgetSettings()

    const handleFontSpacingChange = (e: ChangeEvent<HTMLInputElement>) => {
        const settings = {
            appearanceSettings: {
                ...widget.appearanceSettings,
                font: {
                    ...widget.appearanceSettings.font,
                    spacing: e.target.value,
                },
            },
        }
        updateSettings.update(settings)
    }

    const handleLineHeightChange = (opt: string): void => {
        const settings = {
            appearanceSettings: {
                ...widget.appearanceSettings,
                font: {
                    ...widget.appearanceSettings.font,
                    lineHeight: Number(opt),
                },
            },
        }
        updateSettings.update(settings)
    }

    const renderOptions = () => {
        if (
            widget?.type === 'stepper' ||
            widget?.type === 'spinwheel' ||
            widget?.type === 'polls'
        ) {
            return <></>
        } else {
            return (
                <FontSettingWrapp>
                    <Sizer
                        size={widget?.appearanceSettings?.font?.spacing || 8}
                        name={name}
                        handleSizeChange={handleFontSpacingChange}
                        label="letter spacing"
                        unit="px"
                    />
                    <SimpleSelect
                        label="Line height"
                        size="small"
                        width="100%"
                        options={['1', '1.2', '1.4', '1.5', '2', '2.5', '3']}
                        defaultValue={widget?.appearanceSettings?.font?.lineHeight || '1'}
                        onHandleChange={handleLineHeightChange}
                    />
                </FontSettingWrapp>
            )
        }
    }

    return (
        <DesignBlock>
            <p>Font</p>
            <FlexWrapper>
                <FontPickerContainer>
                    <EditFonts
                        label="Select font"
                        defaultFont={font?.family}
                        onHandleChangeFont={handleFontChange}
                    />
                </FontPickerContainer>
                {widget?.type !== 'swiper' && widget?.type !== 'events' && (
                    <>
                        <FontSettingWrapp>
                            <Sizer
                                size={size}
                                name={name}
                                handleSizeChange={handleFontSizeChange}
                                label="font size"
                            />
                            <SimpleSelect
                                label="Font weight"
                                size="small"
                                width="100%"
                                options={[
                                    '100',
                                    '200',
                                    '300',
                                    '400',
                                    '500',
                                    '600',
                                    '700',
                                    '800',
                                    '900',
                                ]}
                                defaultValue={weight}
                                onHandleChange={handleWeightChange}
                            />
                        </FontSettingWrapp>
                        {renderOptions()}
                    </>
                )}
            </FlexWrapper>
        </DesignBlock>
    )
}

const FlexWrapper = styled.div`
    display: flex;
    flex-direction: column;
`
const FontSettingWrapp = styled.div`
    display: flex;
    margin-top: 18px;
    align-items: flex-end;
    justify-content: space-between;
    & > div:last-child {
        span {
            font-weight: 500;
        }
    }
    & > div {
        width: 100%;
    }
    input {
        width: 80%;
        font-size: 14px;
    }
`
