import React, { HTMLAttributes } from 'react'
import styled, { css } from 'styled-components'

const paddingData = {
    xs: '0px',
    sm: '0px',
    md: '0px',
    lg: '0px',
}

interface IPadding {
    xs?: string
    sm?: string
    md?: string
    lg?: string
}
const marginData = {
    xs: '0px',
    sm: '0px',
    md: '0px',
    lg: '0px',
}

interface IMargin extends IPadding {}
interface IProps extends HTMLAttributes<HTMLDivElement> {
    padding?: IPadding
    margin?: IMargin
    cardType?: 'full-width' | 'center'
}

const CardContainer = styled.div<IProps>`
    width: 100%;
    height: 100%;
    min-height: 20px;
    min-width: 20px;

    @media only screen and (max-width: 600px) {
        padding: ${(props) => props.padding['xs']};
    }

    @media only screen and (min-width: 600px) {
        padding: ${(props) => props.padding['sm']};
    }

    @media only screen and (min-width: 768px) {
        padding: ${(props) => props.padding['md']};
    }

    @media only screen and (min-width: 992px) {
        padding: ${(props) => props.padding['lg']};
    }
`

const CenterCardContainer = styled.div<IProps>`
    width: 100%;
    height: 100%;
    min-height: 20px;
    min-width: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;

    @media only screen and (max-width: 600px) {
        padding: ${(props) => props.padding['xs']};
        margin: ${(props) => props.margin['xs']};
        justify-content: center;
    }

    @media only screen and (min-width: 600px) {
        padding: ${(props) => props.padding['sm']};
        margin: ${(props) => props.margin['sm']};
        justify-content: center;
    }

    @media only screen and (min-width: 768px) {
        padding: ${(props) => props.padding['md']};
        margin: ${(props) => props.margin['md']};
        justify-content: space-between;
    }

    @media only screen and (min-width: 992px) {
        padding: ${(props) => props.padding['lg']};
        margin: ${(props) => props.margin['lg']};
        justify-content: space-between;
    }
`

const Card = ({
    padding = paddingData,
    margin = marginData,
    children,
    cardType = 'full-width',
    ...props
}: IProps) => {
    if (cardType === 'full-width') {
        return (
            <CardContainer
                padding={{ ...paddingData, ...padding }}
                margin={{ ...marginData, ...margin }}
                {...props}
            >
                {children}
            </CardContainer>
        )
    }
    if (cardType === 'center') {
        return (
            <CenterCardContainer
                padding={{ ...paddingData, ...padding }}
                margin={{ ...marginData, ...margin }}
                {...props}
            >
                {children}
            </CenterCardContainer>
        )
    }
}

export default Card
