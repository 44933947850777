import React, { memo } from 'react'
import { Button } from '../../../stories/Button/Button'
import { ButtonGroup, StepTitle, StepWrapper } from '../style'

interface Props {
    onClose: () => void
    prevStep: () => void
    nextStep: () => void
}

const Step2 = ({ prevStep, nextStep, onClose }: Props) => {
    return (
        <StepWrapper>
            <StepTitle>
                <strong>Customise your game!</strong>
                <br /> Click on the game page to open in the builder.
                <br /> Then, click the game widget to open the editor.
            </StepTitle>
            <ButtonGroup>
                <Button onClick={prevStep}>Back</Button>
                <Button width="20px" variant="invert" onClick={onClose}>
                    Close
                </Button>
                <Button variant="secondary" onClick={nextStep}>
                    Next
                </Button>
            </ButtonGroup>
        </StepWrapper>
    )
}

export default memo(Step2)
