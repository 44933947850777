import React, { HTMLAttributes, useState, useEffect, ChangeEvent, FormEvent } from 'react'
import styled from 'styled-components'
import { ContainerBody, InputValue, Unit, Wrapper } from '../Sizer/Sizer'

export interface Props extends HTMLAttributes<HTMLDivElement> {
    direction: 'Top' | 'Bottom' | 'Left' | 'Right'
    value: any
    onChange: any
}

const MarginCustom = ({ direction, value, onChange }: Props) => {
    if (direction === 'Top' || direction === 'Bottom') {
        return (
            <MarginCustomContainer direction={direction}>
                {direction === 'Bottom' && <ContainerLabel>Bottom</ContainerLabel>}
                <ContainerBody>
                    <Wrapper>
                        <InputValue type="number" value={value} onChange={onChange} />
                        <Unit>px</Unit>
                    </Wrapper>
                </ContainerBody>
                {direction === 'Top' && <ContainerLabel>Top</ContainerLabel>}
            </MarginCustomContainer>
        )
    }

    if (direction === 'Right' || direction === 'Left') {
        return (
            <MarginCustomContainer direction={direction}>
                {direction === 'Right' && <ContainerLabel>Right</ContainerLabel>}
                <ContainerBody>
                    <Wrapper>
                        <InputValue type="number" value={value} onChange={onChange} />
                        <Unit>px</Unit>
                    </Wrapper>
                </ContainerBody>
                {direction === 'Left' && <ContainerLabel>Left</ContainerLabel>}
            </MarginCustomContainer>
        )
    }
}

export default MarginCustom

const MarginCustomContainer = styled.div.attrs((props) => ({}))<{ direction: string }>`
    display: flex;
    flex-direction: ${(props) =>
        props.direction === 'Left' || props.direction === 'Right' ? 'row' : 'column'};
    justify-content: flex-start;
    align-items: center;
    color: #717f95;
    & > div > div {
        margin-right: 0;
    }
`

const ContainerLabel = styled.label`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin: 2px;
    font-size: 12px;
`
