import React, { HTMLAttributes, useState } from 'react'
import styled from 'styled-components'
import { Icon } from '../index'
import PopOverItemContainer from './PopoverItem'
import ICampaign from '../../types/Templates/Custom'

export interface Props extends HTMLAttributes<any> {
    options: { link: string; name: string }[]
    data: ICampaign
    projectID?: string
    businessID?: string
}

const PopOverComponent = styled.div`
    height: auto;
    width: auto;
    position: absolute;
    z-index: 10 !important;
`

const PopOver = ({ data, children, options, projectID, businessID, ...props }: Props) => {
    const [isDisplayed, setDisplayOption] = useState(false)
    const toggleOptions = () => {
        setDisplayOption(!isDisplayed)
    }

    return (
        <PopOverComponent onClick={toggleOptions}>
            {children ? children : <Icon size={26} name="dots" onClick={toggleOptions} />}
            {isDisplayed && (
                <PopOverItemContainer
                    data={data}
                    toggleOptions={toggleOptions}
                    options={options}
                    projectID={projectID}
                    businessID={businessID}
                />
            )}
        </PopOverComponent>
    )
}

export default PopOver
