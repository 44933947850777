import React, { memo } from 'react'
import { Button } from '../../../stories/Button/Button'
import { ButtonGroup, StepTitle, StepWrapper } from '../style'

interface Props {
    onClose: () => void
    prevStep: () => void
    nextStep: () => void
}

const Step13 = ({ prevStep, nextStep, onClose }: Props) => {
    return (
        <StepWrapper>
            <StepTitle>
                Not all. But you&#39;ve now fully customized your game. It remains only to customize
                the design, which you already got acquainted with when working with other widgets.
            </StepTitle>
            <ButtonGroup>
                <Button onClick={prevStep}>Back</Button>
                <Button width="20px" variant="invert" onClick={onClose}>
                    Close
                </Button>
                <Button variant="secondary" onClick={nextStep}>
                    OK
                </Button>
            </ButtonGroup>
        </StepWrapper>
    )
}

export default memo(Step13)
