import React, { ChangeEvent, Dispatch, HTMLAttributes, SetStateAction, useState } from 'react'
import { ILayer } from '../../../models/Layer'
import { IWidget } from '../../../models/Widget'
import BorderRadius from '../../../stories/Controllers/Border/BorderRadius'
import { BorderWidth } from '../../../stories/Controllers/Border/BorderWidth'
import { DesignBlock, FlexWrapper } from './DesignTab'

export interface BorderPropsType extends HTMLAttributes<HTMLDivElement> {
    label?: string
    isCustomBorder?: boolean
    setIsCustomBorder?: Dispatch<SetStateAction<boolean>>
    widget?: any
    handleChange: (e: ChangeEvent<HTMLInputElement>, attr: string) => void
    handleChangeBorderWidth?: (e: ChangeEvent<HTMLInputElement>) => void
    handleChangeBorderRadius?: (e: ChangeEvent<HTMLInputElement>) => void
}

interface BorderSettingsPropsType {
    widget?: IWidget | ILayer
    handleChange: (e: ChangeEvent<HTMLInputElement>, attr: string) => void
    handleChangeBorderWidth?: (e: ChangeEvent<HTMLInputElement>) => void
    handleChangeBorderRadius?: (e: ChangeEvent<HTMLInputElement>) => void
}

export const Border = ({
    widget,
    handleChange,
    handleChangeBorderRadius,
    handleChangeBorderWidth,
}: BorderSettingsPropsType) => {
    const [isCustomBorderWidth, setIsCustomBorderWidth] = useState(false)
    const [isCustomBorderRadius, setIsCustomBorderRadius] = useState(false)

    return (
        <DesignBlock>
            <p>Borders</p>
            <FlexWrapper isCustomBorder={isCustomBorderWidth || isCustomBorderRadius}>
                <BorderWidth
                    label="Border line width"
                    isCustomBorder={isCustomBorderWidth}
                    widget={widget}
                    setIsCustomBorder={setIsCustomBorderWidth}
                    handleChange={handleChange}
                    handleChangeBorderWidth={handleChangeBorderWidth}
                />
                <BorderRadius
                    label="Border radius"
                    widget={widget}
                    setIsCustomBorder={setIsCustomBorderRadius}
                    isCustomBorder={isCustomBorderRadius}
                    handleChange={handleChange}
                    handleChangeBorderRadius={handleChangeBorderRadius}
                />
            </FlexWrapper>
        </DesignBlock>
    )
}
