import React, { ReactNode, useEffect } from 'react'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import styled from 'styled-components'

import { useUpdateViewWidgets } from '../../../lib/widgets/useUpdateViewWidgets'
import { ILayer } from '../../../models/Layer'
import { activeWidgetSelector, activeWidgetState } from '../../../stores/builder/activeWidget'
import { currentLayerIndexState } from '../../../stores/builder/currentLayerIndexState'
import { viewSectionState } from '../../../stores/builder/viewSectionState'
import { ButtonGroup } from './ButtonGroup'
import { useLayers } from '../../../lib/hooks/useLayers'
import { useConfirm } from '../../Common/CustomConfirm/useConfirm'

interface DesignTabProp {
    children: ReactNode
    index: number
    layer?: ILayer
}

export const DesignTab = ({ children, index, layer }: DesignTabProp) => {
    const currentLayer = useRecoilValue(currentLayerIndexState)
    const widget = useRecoilValue(activeWidgetSelector)
    const { parentIndex, currentViewIndex } = useRecoilValue(activeWidgetState)
    const updateWidget = useUpdateViewWidgets(currentViewIndex, currentLayer)
    const setSectionState = useSetRecoilState(viewSectionState)
    const useLayerHook = useLayers()
    const { isConfirmed } = useConfirm()

    const listener = (event: KeyboardEvent) => {
        if (event.code === 'Delete') {
            event.preventDefault()
            removeWidget()
        }
    }

    useEffect(() => {
        document.addEventListener('keydown', listener)
        return () => {
            document.removeEventListener('keydown', listener)
        }
    }, [])

    const duplicateWidget = () => {
        if (
            (widget?.category === 'form' && widget?.type !== 'form') ||
            widget?.type === 'submit' ||
            widget?.category === 'game' ||
            parentIndex !== undefined
        ) {
            updateWidget.duplicateContainerWidgets(parentIndex, index, widget)
        } else if (layer || layer?.type === 'popup') {
            useLayerHook.copy(index, layer?.id)
        } else {
            updateWidget.duplicateWidgets(index, widget)
        }
    }

    const removeWidget = async () => {
        if (
            (widget?.category === 'form' && widget?.type !== 'form') ||
            widget?.type === 'submit' ||
            widget?.category === 'game' ||
            (parentIndex !== undefined && parentIndex !== null)
        ) {
            const confirmed = await isConfirmed(
                `You are about to delete a ${widget.type} widget. Confirm deletion?`
            )

            if (confirmed) {
                updateWidget.deleteContainerWidget(parentIndex, index, false)
                setSectionState(null)
            }
        } else if (layer || layer?.type === 'popup') {
            const confirmed = await isConfirmed(
                `You are about to delete a ${layer.type} widget. Confirm deletion?`
            )
            if (confirmed) {
                useLayerHook.delete(index, layer?.id)
                setSectionState(null)
            }
        } else if (widget.type === 'form' || widget.type === 'container') {
            const confirmed = await isConfirmed(
                'You are about to delete the container with all its contents. Confirm deletion?'
            )
            if (confirmed) {
                updateWidget.delete(index)
                setSectionState(null)
            }
        } else {
            const confirmed = await isConfirmed(
                `You are about to delete a ${widget.type} widget. Confirm deletion?`
            )
            if (confirmed) {
                updateWidget.delete(index)
                setSectionState(null)
            }
        }
    }

    return (
        <DesignTabContainer
            onFocus={(e) => {
                if (e.currentTarget !== e.target) {
                    document.removeEventListener('keydown', listener)
                }
            }}
            onBlur={(e) => {
                if (e.currentTarget !== e.target) {
                    document.addEventListener('keydown', listener)
                }
            }}
        >
            {children}
            <ButtonGroup
                removeWidget={removeWidget}
                duplicateWidget={duplicateWidget}
                widget={widget}
            />
        </DesignTabContainer>
    )
}

export const DesignTabContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 320px;
    margin-bottom: 90px;
    & > div:nth-last-child(2) {
        border-bottom: none;
    }
`

export const DesignBlock = styled.div`
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid #dfe2f2;
    padding: 18px 18px 22px 18px;
    position: relative;
    & > p {
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        color: #192536;
        margin-bottom: 7px;
    }
    & > div:last-child {
        margin-bottom: 0;
    }
`
export const FlexWrapper = styled.div.attrs((props) => ({}))<{
    isCustomBorder?: boolean
}>`
    display: flex;
    align-items: ${(props) => (props.isCustomBorder ? 'flex-start' : 'flex-end')};
    flex-direction: ${(props) => (props.isCustomBorder ? 'column' : 'row')};
    & > div:first-child {
        margin-right: 8px;
    }
    & > div:last-child > div > div {
        margin-right: 0;
    }
    div[id^='font-picker'] {
        height: 32px;
        background-color: transparent;
        margin-top: 6px;
    }
    div[id^='font-picker'] .dropdown-button p {
        margin-bottom: 9px;
    }
`
